<template>
  <v-layout column>
    <v-flex
      class="justify-space-between align-center"
      style="display: flex; margin-right: 10px"
    >
      <div style="padding-bottom: 10px">
        <v-checkbox
          v-model="inProgressFilter"
          label="Show only In Progress Vehicles"
          hide-details
          style="margin-top: 0; padding-top: 0; padding-left: 10px"
          @change="$emit('toggle-filter')"
        />
      </div>
    </v-flex>
    <v-flex style="height: 600px; overflow-y: auto">
      <template v-if="loading">
        <v-flex v-for="i in [0, 1, 3]" :key="i" class="vehicle-card">
          <SkeletonBox style="height: 200px" />
        </v-flex>
      </template>
      <template v-else>
        <v-flex
          v-for="(vehicle, vehicleIndex) in vehicles"
          :key="vehicle.vehicleId"
          class="vehicle-card"
          :class="{
            'unavailable-vehicle': vehicle.isUnavailable,
            'in-progress-vehicle': isVehicleInProgress(vehicle),
          }"
        >
          <v-layout row>
            <div style="padding-top: 15px; padding-left: 15px">
              <p
                style="font-size: 16px"
                :class="{
                  'unavailable-index': vehicle.isUnavailable,
                  'in-progress-index': isVehicleInProgress(vehicle),
                }"
              >
                <b>{{ vehicleIndex + 1 }}</b>
              </p>
            </div>
            <div style="padding: 15px 15px">
              <div>
                <p class="vehicle-title">
                  {{ vehicle.vehicleName }}
                </p>
              </div>
              <div
                v-if="isVehicleInProgress(vehicle)"
                style="padding-bottom: 10px; display: flex"
              >
                <CRTag
                  :label="
                    lastReservationIsTracking ? 'Tracking' : 'Not Tracking'
                  "
                  :color="lastReservationIsTracking ? 'green' : 'red'"
                />
                <div>
                  <CRTag
                    v-if="
                      vehicle.onTimeStatusId === 2 ||
                      vehicle.onTimeStatusId === 3
                    "
                    label="On Time"
                    color="green"
                  />
                  <CRTag
                    v-else-if="vehicle.onTimeStatusId === 1"
                    label="Late"
                    color="red"
                  />
                </div>
              </div>
              <div style="padding-bottom: 10px">
                <p>
                  {{ vehicle.vehicleType.label }}
                </p>
                <p>
                  Device:
                  <b>{{ getTrackerInfo(vehicle) }}</b>
                </p>
              </div>
              <div style="padding-bottom: 10px">
                <p>
                  Last Reservation ID:
                  <router-link
                    v-if="vehicle.lastReservation"
                    :id="`reservation-link-${vehicle.lastReservation}`"
                    target="_blank"
                    :to="{
                      name: 'reservation-detail',
                      params: { id: vehicle.lastParentManagedId },
                    }"
                    class="reservation-link"
                  >
                    #{{ vehicle.lastManagedId }}
                  </router-link>
                  <span v-else><b>N/A</b></span>
                </p>
                <p v-if="isVehicleInProgress(vehicle)">
                  Contract:
                  <b>{{ getContractInfo(vehicle) }}</b>
                </p>
              </div>
              <p>
                Last Report Time:
                <b>
                  {{
                    vehicle.lastSyncDate
                      ? isoToString(vehicle.lastSyncDate)
                      : 'N/A'
                  }}
                </b>
              </p>
              <p>
                Last Location:
                <b>
                  {{
                    vehicle.lat && vehicle.lng
                      ? `${vehicle.lat}, ${vehicle.lng}`
                      : 'N/A'
                  }}
                </b>
              </p>
            </div>
          </v-layout>
        </v-flex>
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import { isoToString } from '@/utils/time'
import { truncate } from '@/utils/string'
import SkeletonBox from '@/components/SkeletonBox.vue'

export default {
  components: {
    SkeletonBox,
  },
  props: {
    vehicles: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inProgressFilter: false,
    }
  },
  methods: {
    isoToString,
    isVehicleInProgress(vehicle) {
      return vehicle?.lastReservationStatus === 'started'
    },
    getTrackerInfo(vehicle) {
      return vehicle.deviceId && vehicle.eldType
        ? `#${vehicle.deviceId} (${vehicle.eldType})`
        : 'N/A'
    },
    getContractInfo(vehicle) {
      if (
        !vehicle?.lastReservationContractName ||
        !vehicle.lastReservationQuoteId
      ) {
        return 'N/A'
      }
      return (
        truncate(
          `${vehicle.lastReservationQuoteId} (${vehicle.lastReservationContractName}`,
          33
        ) + ')'
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.vehicle-card {
  border: 1px solid $input-border-gray;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 15px;

  p {
    color: $black;
    margin: 0;
  }
}
.in-progress-vehicle {
  background: $blue-light;
}
.unavailable-vehicle {
  background: $input-background-gray !important;
}
.vehicle-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px !important;
}
.reservation-link {
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 10px !important;
}
.tag {
  background: $green;
  color: $white;
  border-radius: 5px;
  margin-right: 5px;
  padding: 2px 6px;
  width: fit-content;
  &--green {
    background: $green;
  }
  &--red {
    background: $red;
  }
}
.in-progress-index {
  color: $blue !important;
}
.unavailable-index {
  color: $gray-mid-light !important;
}
</style>
