<template>
  <CRSidebarDialog
    v-model="active"
    persistent
    @input="closeHandlerWrapper(false)"
  >
    <template #title>Trip Details</template>
    <div class="trip-sidebar">
      <v-card flat class="mb-4" color="#ECF4FA">
        <v-card-title primary-title>
          <ul>
            <li>
              <MarketplaceRequiredVehicles v-if="rowData" :row="rowData" />
            </li>
            <li>
              {{ rowData.requiredDrivers }}
              {{ pluralize(rowData.requiredDrivers, 'Driver') }}
            </li>
            <li>
              {{ rowData.passengerCount }}
              {{ pluralize(rowData.passengerCount, 'Passenger') }}
            </li>
            <div v-if="bid">
              <li>{{ priceLabel }}: {{ calculatedPrice }}</li>
              <li>Actual awarded price would be: {{ awardedPrice }}</li>
            </div>
          </ul>
        </v-card-title>
      </v-card>

      <div class="mb-4" style="font-size: 12px">
        <b>Trip Dates:</b>
        {{ formatDisplayTime(rowData.stops[0].pickupDate) }} -
        {{ formatDisplayTime(rowData.stops[1].dropoffDate) }}
      </div>

      <div class="trip-timeline">
        <div
          v-for="(stop, index) in rowData.stops"
          :key="stop.stopId"
          class="trip-timeline-item"
        >
          <div class="trip-timeline-item__divider position-relative top-0">
            <div class="trip-timeline-item__dot" />
          </div>
          <div class="trip-timeline-item__body">
            <h4 class="trip-timeline-item__stop__title">
              {{ stop.address.title || addressPretty(stop) }}
            </h4>
            <div
              v-if="stop.address.title"
              :id="`trip-timeline-item-stop-${index}-address`"
              class="trip-timeline-item__stop__address"
            >
              {{ addressPretty(stop) }}
            </div>
            <div
              v-if="stop.dropoffDate"
              :id="`trip-timeline-item-stop-${index}-pickup-time`"
              class="trip-timeline-item__stop__time"
            >
              Arrival:
              {{ formatDisplayTime(stop.dropoffDate, stop.address.timeZone) }}
            </div>
            <div
              v-if="stop.pickupDate"
              :id="`trip-timeline-item-stop-${index}-pickup-time`"
              class="trip-timeline-item__stop__time"
            >
              Departure:
              {{ formatDisplayTime(stop.pickupDate, stop.address.timeZone) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </CRSidebarDialog>
</template>

<script>
import MarketplaceRequiredVehicles from '@/components/MarketplaceRequiredVehicles.vue'
import { addressPretty } from '@/utils/string'
import { currencyFilter } from '@/utils/currency'
import { DateTime } from 'luxon'
import { pluralize } from '@/utils/string'

export default {
  components: {
    MarketplaceRequiredVehicles,
  },
  model: {
    prop: 'active',
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    rowData: {
      type: Object,
      required: true,
    },
    closeHandler: {
      type: Function,
      default: () => {},
    },
    companyId: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      addressPretty,
      pluralize,
    }
  },
  computed: {
    priceLabel() {
      if (this.bid.getIsEditingPrevented) {
        return 'Automated Price'
      }
      return 'Calculated Price'
    },
    bid() {
      return (
        this.rowData.bids.filter(
          (bid) => bid.active && bid.companyId === this.companyId
        )[0] || null
      )
    },
    currentBidPrice() {
      return this.bid?.bidAmount
    },

    calculatedPrice() {
      if (this.currentBidPrice) {
        return currencyFilter(this.currentBidPrice)
      }
      return ''
    },

    awardedPrice() {
      if (this.currentBidPrice) {
        const brokerTakeRate = this.bid.getBid?.takeRate || 10
        const operatorTakePercent = (100 - brokerTakeRate) / 100
        return currencyFilter(this.currentBidPrice * operatorTakePercent)
      }
      return ''
    },
  },
  methods: {
    closeHandlerWrapper(refreshStateAfterAction) {
      this.closeHandler(refreshStateAfterAction)
    },
    formatDisplayTime(isoString) {
      return DateTime.fromISO(isoString).toFormat('f ZZZZ')
    },
  },
}
</script>

<style lang="scss" scoped>
.trip-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 30px;

  .trip-timeline {
    margin-left: 10px;
    padding-top: 0;

    .trip-timeline-item {
      display: flex;
      &:last-of-type {
        .trip-timeline-item__divider {
          border-left: none;
        }
      }
      &__divider {
        border-left: 2px solid #cacdd3;
        min-width: 1px;
        position: relative;
        display: flex;

        align-items: center;
        justify-content: center;
        top: 0;
      }
      &__body {
        max-width: 500px;
        margin-left: 12px;
        margin-top: -5px;
        padding-bottom: 16px;
      }
      &__dot {
        background: #fff;
        align-self: start;

        border-radius: 50%;
        height: 12px;
        width: 12px;
        margin-right: 4px;
        margin-left: -6px;

        box-shadow: 0 0;
        z-index: 10;
        margin-right: 20px;

        background-color: $gray-medium-light;
      }
      &__stop {
        &__title {
          margin-bottom: -2px;
          line-height: 1.5;
          font-size: 16px;
          &__eta {
            position: relative;
            top: -1px;
            display: inline-block;
            padding: 2px 8px;
            margin-left: 4px;
            font-size: 12px;
            background: $purple;
            color: $white !important;
          }
        }
        &__address {
          margin-bottom: 2px;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6) !important;
        }
        &__time {
          color: $gray-medium-light !important;
          line-height: 1.2em;
        }
        &__reached {
          margin-top: 6px;
          font-size: 12px;
          line-height: 1.2em;
          color: rgba(0, 0, 0, 0.6) !important;
        }
      }
    }
  }
}
</style>
