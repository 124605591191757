import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { ContractTrackingDataResult, DeviceInfoPayload, TrackingSource, TrackingSummaryResponse } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets the tracked reservations and other tracking data for a given contract.
   * @param hash - the hash for the contract.
   * @returns a ContractTrackingDataResult.
   */
  getTrackingByContractHash(hash: string): Promise<AxiosResponse<ContractTrackingDataResult>> {
    const url = `https://${baseUrl()}/tracking/contract/${hash}?minutesBefore=30&minutesAfter=30&isVanityUrl=false`
    return httpService.get(url)
  },
  /**
   * Gets device info for a list of devices.
   * @param payload - a DeviceInfoPayload object.
   * @returns the list of TrackingSources.
   */
  getDeviceInfo(payload: DeviceInfoPayload): Promise<AxiosResponse<TrackingSource[]>> {
    const url = `https://${baseUrl()}/v2/tracking/deviceInfo`
    return httpService.post(url, payload)
  },
  /**
   * Gets tracking summary for a list of referrals.
   * @param payload - a list of referral ids.
   * @returns a TrackingSummaryResponse.
   */
  getTrackingSummary(referralIds: number[]): Promise<AxiosResponse<TrackingSummaryResponse>> {
    const url = `https://${baseUrl()}/reservations/trackingSummary`
    return httpService.post(url, referralIds)
  }
}
