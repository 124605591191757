<template>
  <CRSidebarDialog
    v-model="active"
    persistent
    @input="closeHandlerWrapper(false)"
  >
    <template #title>ID: {{ rowData.managedId }}</template>
    <div class="bookings-sidebar">
      <v-row class="bookings-sidebar--booking-details">
        <div style="margin-bottom: 5px">
          <b>{{ requiredVehicles }}</b>
        </div>
        <div style="margin-bottom: 5px">
          <b>Passengers:</b>
          {{ trip.passengerCount }}
        </div>
        <div>
          <b>Referral Total:</b>
          ${{ rowData.amount }}
        </div>
      </v-row>
      <v-row class="bookings-sidebar--trip-details">
        <div>
          <b>Trip Dates</b>
          {{ formattedDate }}
        </div>
        <v-timeline align-top dense left class="trip-itinerary">
          <v-timeline-item
            v-for="(stop, index) in trip.stops"
            :key="stop.id"
            color="$gray-mid-light"
            small
            fill-dot
          >
            <div>
              <b>{{ timelineLabel(index) }}</b>
              {{ stopName(stop) }}
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-row>
    </div>
  </CRSidebarDialog>
</template>

<script>
import { DateTime } from 'luxon'
import { pluralize } from '@/utils/string'

export default {
  props: {
    mode: {
      type: String,
      default: 'view',
    },
    active: {
      type: Boolean,
      default: false,
    },
    rowData: {
      type: Object,
      default: () => ({}),
    },
    closeHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      trip: {
        requiredDrivers: 1,
        tripVehicles: [],
      },
    }
  },
  computed: {
    requiredVehicles() {
      let requiredVehicleString = ''
      let requiredDriverString = `${this.trip.requiredDrivers} ${pluralize(
        this.trip.requiredDrivers,
        'Driver'
      )}`
      for (let vehicle of this.trip.tripVehicles) {
        requiredVehicleString += `${vehicle.quantity} ${pluralize(
          vehicle.quantity,
          vehicle.vehicleType.label
        )}`
      }
      return `${requiredVehicleString} | ${requiredDriverString}`
    },
    formattedDate() {
      return `${DateTime.fromISO(this.rowData.pickupDate).toFormat(
        'MM/dd/yyyy H:mm a ZZZZ'
      )} - ${DateTime.fromISO(this.rowData.dropoffDate).toFormat(
        'MM/dd/yyyy H:mm a ZZZZ'
      )}`
    },
  },
  async beforeUpdate() {
    if (this.active) {
      const { data } = await this.$store.dispatch(
        'trips/getTrip',
        this.rowData.tripId
      )
      this.trip = data
    }
  },
  methods: {
    timelineLabel(index) {
      if (index === 0) {
        return 'Pickup:'
      } else if (index === this.trip.stops.length - 1) {
        return 'Dropoff:'
      }
      return `Stop #${index}:`
    },
    stopName(stop) {
      return this.addressPretty(stop) || stop.address.addressName
    },
    closeHandlerWrapper(refreshStateAfterAction) {
      if (this.internalMode !== 'add') {
        this.internalMode = 'view'
      }

      if (this.internalMode === 'add') {
        this.resetForm()
      }
      this.closeHandler(refreshStateAfterAction)
    },
    addressPretty(stop) {
      const street1 = `${stop?.address?.street1?.trim()} ` || ''
      const city = stop?.address?.city ? `${stop?.address?.city}, ` : ''
      const state = `${stop.address.state} ` || ''
      const postalCode = stop.address.postalCode || ''
      return `${street1}${city}${state}${postalCode}`
    },
  },
}
</script>

<style lang="scss" scoped>
.v-timeline {
  padding-top: 10px;
}

.v-timeline::before {
  top: 30px;
  height: calc(100% - 60px);
  border-left: 1px thin $gray-mid-light;
}

.trip-itinerary {
  margin-top: 10px;
}

::v-deep .v-timeline-item__body {
  margin-top: 6px;
  font-family: 'Airbnb Cereal App Medium', 'Lato', sans-serif;
  font-weight: 400;
  font-size: 12px;
  padding-left: 0;
  margin-left: -10px;

  h4 {
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
  }
}

::v-deep .v-timeline-item__dot {
  height: 10px;
  width: 10px;
  background-color: $gray-mid-light !important;
  margin-left: 7px;
  z-index: 10;
  box-shadow: none;

  .v-timeline-item__inner-dot {
    height: 8px;
    margin: 2px;
    width: 8px;
  }
}

.bookings-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0 !important;
  padding: 15px 40px;
  font-size: 12px;

  &--booking-details {
    padding: 15px;
    background-color: $blue-light;
    margin-bottom: 10px;
    border-radius: 3px;
  }

  &--trip-details {
    margin-top: 10px;
  }
}
</style>
