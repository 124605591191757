<template>
  <div style="position: relative; margin-left: 7px">
    <v-layout
      v-for="(integrationDetail, index) in integrationDetails"
      :id="`integration-detail-${integrationDetail}-${index}`"
      :key="`integration-detail-${integrationDetail}-${index}`"
      style="margin-left: 20px; margin-right: 10px; margin-bottom: 10px"
      class="integration-section"
      row
    >
      <v-flex style="padding: 10px 20px 20px 10px; width: 50%">
        <div>
          <v-layout row>
            <v-flex xs6>
              <div style="margin-top: 10px">
                <h3>
                  <b>
                    {{ getEldTypeLabel(integrationDetail.eldType) }}
                  </b>
                </h3>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs6>
              <div style="margin-top: 10px">
                <b>Connection:</b>
                {{
                  integrationDetail.credentialsStatus === 'ACCESS_FAILURE'
                    ? 'Failing'
                    : 'Connected'
                }}
              </div>
            </v-flex>
            <v-flex xs6>
              <div style="margin-top: 10px">
                <b>Last Successfull:</b>
                {{ formattedDate(integrationDetail.lastSync) }}
              </div>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import affiliates from '@/services/affiliates'

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      integrationDetails: {},
      eldTypes: [],
    }
  },
  async mounted() {
    const integrationDetails = await affiliates.getAffiliateEldCredentialDetails(
      {
        id: this.company.companyId,
      }
    )
    this.integrationDetails = integrationDetails.data.data
    this.getEldTypes()
  },
  methods: {
    async getEldTypes() {
      const eldTypes = await this.$store.dispatch('types/getEldTypes')
      this.eldTypes = eldTypes.data.resultList
    },
    getEldTypeLabel(eldType) {
      return eldType?.charAt(0) + eldType?.slice(1).toLowerCase()
    },
    formattedDate(date) {
      return DateTime.fromISO(date).toFormat('MM/dd/yy h:mma ZZZZ')
    },
  },
}
</script>

<style lang="scss" scoped>
.integration-section {
  border: 1px solid $gray-light;
  border-radius: 5px;
}

.center-divider {
  border-left: 1px solid $gray-light;
}
</style>
