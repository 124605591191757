var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sortedDevices,"item-key":"trackingDeviceId","rows-per-page-items":_vm.rowsPerPageItems,"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(heads){return _vm._l((heads.headers),function(headerItem,headerIndex){return _c('th',{key:headerIndex,class:`column text-xs-${
          headerItem.align ? headerItem.align : 'left'
        }`,attrs:{"role":"columnheader","scope":"col"},on:{"click":function($event){return _vm.sortByColumn(headerItem.value)}}},[_vm._v(" "+_vm._s(headerItem.text)+" "),_c('span',{key:`data-table-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[(
              _vm.pagination.sortBy &&
              _vm.pagination.sortBy === headerItem.value &&
              _vm.pagination.descending === true
            )?_c('CRIcon',{staticStyle:{"margin-bottom":"-4px"},attrs:{"width":16,"height":16,"view-box":"0 0 24 24"}},[_vm._v(" arrow_upward ")]):(
              _vm.pagination.sortBy &&
              _vm.pagination.sortBy === headerItem.value &&
              _vm.pagination.descending === false
            )?_c('CRIcon',{staticStyle:{"margin-bottom":"-4px"},attrs:{"width":16,"height":16,"view-box":"0 0 24 24"}},[_vm._v(" arrow_downward ")]):_c('v-icon',{staticStyle:{"width":"16px"},attrs:{"small":""}},[_vm._v("fa-fw")])],1)])})}},{key:"items",fn:function(props){return [_c('td',{key:`device-id-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_vm._v(" "+_vm._s(props.item.trackingDeviceId)+" ")]),_c('td',{key:`vehicle-name-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_vm._v(" "+_vm._s(props.item.vehicleName)+" ")]),_c('td',{key:`vin-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_vm._v(" "+_vm._s(props.item.trackingContextId)+" ")]),_c('td',{key:`eld-type-table-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_vm._v(" "+_vm._s(props.item.eldType)+" ")]),_c('td',{key:`received-on-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_vm._v(" "+_vm._s(props.item.reportedOn !== null ? _vm.datetimeToShortLocalizedDateTime(props.item.reportedOn) : 'No data available')+" ")]),_c('td',{key:`received-on-sort-key-${_vm.pagination.sortBy}-${_vm.pagination.descending}`},[_vm._v(" "+_vm._s(props.item.createdOn !== null ? _vm.datetimeToShortLocalizedDateTime(props.item.createdOn) : 'No data available')+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }