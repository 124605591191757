<template>
  <v-container>
    <v-layout row wrap>
      <v-flex>
        <v-layout row wrap>
          <template v-if="row.action === 'delete_driver_detail'">
            <BaseDeletion
              :close="close"
              :collection-name="'driver'"
              :row="row"
              :special-identifier="'userId'"
              :action="action"
            />
          </template>
          <template v-if="row.action === 'detail'">
            <DriverQuickView :row="row" />
          </template>
          <template v-if="row.action === 'activate_driver'">
            <ActivateDriver :row="row" :close="close" />
          </template>
          <template v-if="row.action === 'deactivate_driver'">
            <DeactivateDriver :row="row" :close="close" />
          </template>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import BaseDeletion from '@/components/BaseDeletion.vue'
import DriverQuickView from '@/components/DriverQuick.vue'
import ActivateDriver from '@/components/ActivateDriver.vue'
import DeactivateDriver from '@/components/DeactivateDriver.vue'
import drivers from '@/services/drivers'

export default {
  components: {
    BaseDeletion,
    DriverQuickView,
    ActivateDriver,
    DeactivateDriver,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      action: (params) => drivers.deleteDriver(params),
    }
  },
  methods: {
    close() {
      this.props.expanded = false
    },
  },
}
</script>
