<template>
  <span v-if="isSoldOut" class="text-error font-medium">Sold Out</span>
  <span v-else-if="formattedBidAmount">{{ formattedBidAmount }}</span>
  <v-btn v-else-if="!bid" small color="primary" disabled>No Bid</v-btn>
</template>

<script>
import { currencyFilter } from '@/utils/currency'

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
    rowProps: {
      type: Object,
      default: () => {},
    },
    column: {
      type: Object,
      required: true,
    },
  },

  computed: {
    bid() {
      return (
        this.row.bids.filter(
          (bid) => bid.active && bid.companyId === this.column.companyId
        )[0] || null
      )
    },
    isSoldOut() {
      return this.bid?.soldOut || false
    },

    bidAmount() {
      return this.row?.totalBidAmount || null
    },

    formattedBidAmount() {
      if (this.bidAmount !== null) {
        return currencyFilter(this.bidAmount)
      }
      return null
    },
  },
}
</script>
