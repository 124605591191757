<template>
  <div>
    <MarketplaceSidebar
      v-model="active"
      :row-data="rowData"
      :company-id="company.companyId"
      :close-handler="closeHandler"
    />
    <v-tabs v-model="selectedTab" class="mb-4" @change="changeTab">
      <template v-for="tab in tabs">
        <v-tab
          :id="`affiliate-marketplace-tab-${tab.text.toLowerCase()}`"
          :key="tab.text"
          class="mx-2"
        >
          {{ tab.text }}
        </v-tab>
      </template>
    </v-tabs>
    <DataTable
      ref="dataTable"
      :initial-filters="initialFilters"
      v-bind.sync="tableProps"
      style="margin-left: 15px"
    />
  </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import Vue from 'vue'
import { deepClone } from '@/utils/deepClone'
import MarketplacePickupDestination from '@/components/MarketplacePickupDestination.vue'
import MarketplaceRequiredVehicles from '@/components/MarketplaceRequiredVehicles.vue'
import MarketplaceListExpiration from '@/components/MarketplaceListExpiration.vue'
import MarketplaceListBidPrice from '@/components/MarketplaceListBidPrice.vue'
import MarketplaceActions from '@/components/MarketplaceActions.vue'
import MarketplaceSidebar from '@/components/MarketplaceSidebar.vue'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import { sort } from '@/utils/sort'

import { calculatedValues, datePredefined } from '@/utils/predefined'
import { DateTime } from 'luxon'
import { v4 } from 'uuid'

const tabs = [
  {
    text: 'New',
    tabFilters: [
      {
        column: {
          _t_id: 'e6b676ab-b001-4cb1-a825-e905058a0616',
          text: 'New',
          prop: 'createdOn',
          type: 'date',
          method: 'or',
          filterType: 'gte',
        },
        value: DateTime.local().toISODate() + 'T00:00:00.000+00:00',
        hideOnFilterBar: true,
      },
    ],
    tabSorts: [],
  },
  {
    text: 'Ending Soon',
    tabFilters: [
      {
        column: {
          _t_id: '65cd0baa-88cd-4aa9-8205-ca2decdc3dcc',
          text: 'Ending Soon',
          prop: 'biddingEndDate',
          type: 'date',
          method: 'or',
          filterType: 'lte',
        },
        value: DateTime.local().plus({ days: 1 }).toISO(),
        hideOnFilterBar: true,
      },
    ],
    tabSorts: [],
  },
  {
    text: 'Picking Up Soonest',
    tabFilters: [],
    tabSorts: [
      {
        prop: 'startDate',
        direction: 'asc',
      },
    ],
  },
  {
    text: 'All',
    tabFilters: [],
    tabSorts: [],
  },
]

export default {
  components: {
    DataTable,
    MarketplaceSidebar,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: 'Affiliate Marketplace',
    }
  },
  data() {
    return {
      latestRequestId: 0,
      active: false,
      tabs,
      selectedTab: 0,
      filters: [],
      sorts: sort(),
      currentPage: 1,
      itemsPerPage: 10,
      tableProps: {
        total: 0,
        tableId: 'affiliate_marketplace',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        calculatedValues,
        id: 'reservations-list',
        columns: [
          {
            _t_id: 'f1b17889-d043-4f3f-bb5e-d0cbe268437b',
            text: 'Actions',
            component: this.createComponent(
              MarketplaceActions,
              this.viewSidebar
            ),
            sort: false,
            filter: false,
            detail: false,
            type: 'actions',
          },
          {
            _t_id: 'c70e5684-0bab-4fd0-bcaa-9a61b459b552',
            text: 'Trip ID',
            value: 'tripId',
            prop: 'tripId',
            sortProp: 'tripId',
            direction: 'desc',
            defaultSort: true,
            defaultHidden: true,
          },
          {
            _t_id: '755e663f-0126-4f31-a642-94b90dfc81cc',
            text: 'Pickup/Destination',
            value: 'stops[0].address.city',
            type: 'slot',
            component: MarketplacePickupDestination,
          },
          {
            _t_id: 'a40e2a3e-25d7-4f1f-bff0-d0296d7a0d25',
            value: 'pickupDate',
            text: 'Pickup Date',
            filter: true,
            sort: true,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            predefined: deepClone(datePredefined),
            computedText: (item, trip) => {
              const firstStop = trip.stops?.[0]
              const datetime = DateTime.fromISO(firstStop?.pickupDate, {
                zone: firstStop?.address?.timeZone,
              })
              return datetime.toFormat('ff ZZZZ')
            },
          },
          {
            _t_id: '2c1660f9-ba0a-46b8-8ee5-32ec50728901',
            text: 'Vehicles',
            value: 'requiredVehicles',
            type: 'slot',
            component: MarketplaceRequiredVehicles,
          },
          {
            _t_id: 'd4e33239-c705-4e38-bc32-7a1edef431ca',
            text: 'Drivers',
            prop: 'requiredDrivers',
            sort: true,
            type: 'text',
            method: 'or',
            childMethod: 'and',
          },
          {
            _t_id: 'b9b4e35d-f241-4799-b5df-17a5b2574228',
            text: 'Expiration',
            value: 'biddingEndDate',
            sortable: true,
            sortProp: 'biddingEndDate',
            component: MarketplaceListExpiration,
            type: 'slot',
          },
          {
            _t_id: '67517e7f-61e8-4421-b58a-8db07b67123f',
            text: 'Bid Price',
            value: 'bids',
            type: 'slot',
            component: MarketplaceListBidPrice,
            companyId: this.company.companyId,
          },
        ],
      },
    }
  },
  computed: {
    ...authComputed,
    initialFilters() {
      let initialFilters = [
        {
          column: {
            _t_id: 'af96c292-4bd0-46f7-8583-de4158bced4a',
            prop: 'startDate',
            filterType: 'gte',
            text: '',
            filterPlaceholder: true,
          },
          value: DateTime.utc().toISO(),
          hideOnFilterBar: true,
        },
      ]

      return initialFilters.concat(tabs[this.selectedTab]?.tabFilters || [])
    },
  },
  mounted() {
    EventBus.$on('global-table-view-refresh', () => {
      this.refresh()
    })
    this.$refs.dataTable.setInitialFilters()
  },
  methods: {
    createComponent(component, handler) {
      // eslint-disable-next-line
      return Vue.component('WrappedActions', {
        props: {
          row: {
            type: Object,
            default: () => ({}),
          },
          handleAction: {
            type: Function,
            default: () => null,
          },
          rowProps: {
            type: Object,
            default: () => ({}),
          },
        },
        render(createElement) {
          return createElement(component, {
            props: {
              row: this.row,
              handleAction: this.handleAction,
              rowProps: this.rowProps,
              handler: () => {
                handler(this.row)
              },
            },
          })
        },
      })
    },
    changeTab() {
      this.sorts.set([])
      this.sorts.add(
        this.tableProps.columns.find((column) => column.defaultSort)
      )
      tabs[this.selectedTab].tabSorts.forEach(this.sorts.add) // reset datatable sorting
      this.$refs.dataTable.clearView() // reset data table filtering
      this.refresh()
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    viewSidebar(data) {
      this.active = true
      this.rowData = data
      this.mode = 'view'
    },
    closeHandler(refreshQuery) {
      if (refreshQuery) {
        this.tableInit.forceRefresh = 1 + Math.random()
      }
      this.active = false
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }

      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getTable() {
      const requestId = v4()
      this.latestRequestId = requestId
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const params = {
        sorts,
        filters,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        additionalQueries: `isAffiliateDetailsView=true&affiliateCompanyId=${this.company.companyId}`,
      }
      this.tableProps.loading = true

      const reservationData = await this.$store.dispatch(
        'trips/getTrips',
        params
      )

      if (this.latestRequestId !== requestId) {
        return
      }

      const reservations = reservationData.data

      const tripparams = {
        page: 1,
        pageSize: -1,
        sorts: undefined,
        filters: undefined,
      }

      for (let reservation of reservations.resultList) {
        const response = await this.$store.dispatch('trips/getTrips', {
          ...tripparams,
          quoteId: reservation.quoteId.toString(),
        })
        const allTrips = response.data.resultList

        let totalBidAmount = 0
        for (const trip of allTrips) {
          totalBidAmount = trip.bids.reduce((sum, bid) => {
            if (bid.active && bid.companyId === this.company.companyId) {
              sum += bid.bidAmount
            }
            return sum
          }, totalBidAmount)
        }
        reservation.totalBidAmount = totalBidAmount
        reservation.totalTrips = response.data.count
      }

      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = reservations.resultList
      this.tableProps.total = reservations.count
      this.tableProps.loading = false
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      if (
        this.currentPage != pagination.page ||
        this.itemsPerPage != pagination.rowsPerPage
      ) {
        this.currentPage = pagination.page
        this.itemsPerPage = pagination.rowsPerPage
        this.refresh()
      }
    },
  },
}
</script>
