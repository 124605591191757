<template>
  <v-menu
    v-model="menuOpen"
    :open-on-click="false"
    :close-on-click="false"
    nudge-bottom="50"
  >
    <template #activator="{ on }">
      <CRInput
        ref="autoCompleteTextField"
        placeholder="Affiliate"
        v-bind="$attrs"
        :autofocus="autoFocus"
        :name="uuidv4()"
        :value="search"
        :disabled="disabled"
        :error-messages="errorMessages"
        clear-icon="replay"
        :clearable="true"
        flat
        :append-icon="appendIcon ? appendIcon : ''"
        autocomplete="off"
        outlined
        :tabindex="tabIndex"
        style="margin-left: 10px; min-height: 40px; max-width: 240px"
        v-on="on"
        @blur="onBlur"
        @input="affiliateAutoComplete"
        @keyup.native="handleKeyEvent"
        @focus="onFocus"
        @click:append="appendIconHandler"
        @click:clear="autocompleteClear"
      />
      <div v-if="loading">
        <v-progress-linear :indeterminate="true" height="1" />
      </div>
    </template>
    <v-card v-if="autocompleteItems.length && !hideOptions && manualToggle">
      <v-list>
        <v-list-tile
          v-for="(item, index) in autocompleteItems"
          :id="`address-autocomplete-item-${index}`"
          :key="`address-autocomplete-item-${index}`"
          :class="arrowPosition === index ? 'hover-auto-complete-item' : ''"
          @mouseover="arrowPosition = index"
          @mousedown="selectAffiliate(item)"
          @click="selectAffiliate(item)"
        >
          <v-list-tile-content>
            <v-list-tile-title>
              {{ item.name }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { setTimeout } from 'timers'
import { v4 } from 'uuid'
import affiliates from '@/services/affiliates'
import { filter } from '@/utils/filter'

export default {
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    manualControl: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focusHandler: {
      type: Function,
      default: () => null,
    },
    hideOptions: {
      type: Boolean,
    },
    identifier: {
      type: String,
      default: () => undefined,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: () => undefined,
    },
    appendIconHandler: {
      type: Function,
      default: () => true,
    },
    initialSearch: {
      type: String,
      default: () => undefined,
    },
    tabIndex: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      search: undefined,
      debounce: undefined,
      autocompleteItems: [],
      arrowPosition: undefined,
      manualToggle: true,
      errorMessages: [],
      menuOpen: false,
    }
  },
  created() {
    if (this.initialSearch) {
      this.search = this.initialSearch
    }
  },
  methods: {
    handleKeyEvent(event) {
      if (this.manualControl) {
        this.manualToggle = true
        this.errorMessages = []
      }
      const maxPosition = this.autocompleteItems.length
      // UP ARROW
      if (event.keyCode === 38) {
        if (typeof this.arrowPosition === 'undefined') {
          this.arrowPosition = maxPosition
        } else if (this.arrowPosition >= 0) {
          this.arrowPosition = this.arrowPosition - 1
        }
        return
      }
      // DOWN ARROW
      if (event.keyCode === 40) {
        if (typeof this.arrowPosition === 'undefined') {
          this.arrowPosition = 0
        } else if (this.arrowPosition + 1 === maxPosition) {
          this.arrowPosition = 0
        } else {
          this.arrowPosition = this.arrowPosition + 1
        }
        return
      }

      // ENTER
      if (event.keyCode === 13) {
        if (typeof this.autocompleteItems[this.arrowPosition] !== 'undefined') {
          this.selectAffiliate(this.autocompleteItems[this.arrowPosition])
        }
        return
      }
    },
    async affiliateAutoComplete(text) {
      const filterObject = {
        column: {
          _t_id: 'affiliates-search-by-keyword',
          prop: 'name',
          filterType: 'contains',
          filterAsIs: true,
        },
      }
      filterObject.value = text
      if (typeof text === 'string') {
        if (text.length === 0) {
          this.autofilledAffiliates = []
          return
        }
      } else {
        return
      }
      const keywordSearch = filter()
      const parentFilter = keywordSearch.createParent('and')
      keywordSearch.add(parentFilter, filterObject)

      const params = {
        filters: keywordSearch.asQueryParams(),
        pageSize: -1,
      }

      if (this.debounce) {
        window.clearTimeout(this.debounce)
      }
      this.debounce = window.setTimeout(async () => {
        this.loading = true
        this.arrowPosition = undefined
        const matchedAffiliates = await affiliates
          .getAffiliatesMap(params)
          .catch((error) => {
            return false
          })

        this.autocompleteItems = matchedAffiliates.data.resultList
      }, 250)
    },
    selectAffiliate(affiliate) {
      this.search = affiliate.name
      this.$emit('change', affiliate)
    },
    autocompleteClear() {
      this.autocompleteItems = []
      this.search = null
      this.$emit('autocomplete-clear')
    },
    onFocus() {
      this.menuOpen = true
    },
    onBlur() {
      const { identifier } = this
      this.menuOpen = false
      setTimeout(() => {
        this.autocompleteItems = []
        this.$emit('exit', identifier)
      }, 500)
    },
    uuidv4() {
      return v4()
    },
  },
}
</script>

<style scoped lang="scss">
.v-list {
  .v-list-item {
    cursor: pointer;
  }
}

.location-avatar {
  width: 22px;
  height: 24px;
}

.hover-auto-complete-item {
  background-color: rgba(202, 205, 211, 0.25);
}

::v-deep button.v-icon.v-icon--link {
  color: rgba($black, 0.5);
  margin-top: 14px !important;
  margin-right: 16px !important;
}
</style>
