<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="sortedDevices"
      item-key="trackingDeviceId"
      :rows-per-page-items="rowsPerPageItems"
      :pagination.sync="pagination"
    >
      <template #headers="heads">
        <th
          v-for="(headerItem, headerIndex) in heads.headers"
          :key="headerIndex"
          role="columnheader"
          scope="col"
          :class="`column text-xs-${
            headerItem.align ? headerItem.align : 'left'
          }`"
          @click="sortByColumn(headerItem.value)"
        >
          {{ headerItem.text }}
          <span
            :key="`data-table-sort-key-${pagination.sortBy}-${pagination.descending}`"
          >
            <CRIcon
              v-if="
                pagination.sortBy &&
                pagination.sortBy === headerItem.value &&
                pagination.descending === true
              "
              :width="16"
              :height="16"
              view-box="0 0 24 24"
              style="margin-bottom: -4px"
            >
              arrow_upward
            </CRIcon>
            <CRIcon
              v-else-if="
                pagination.sortBy &&
                pagination.sortBy === headerItem.value &&
                pagination.descending === false
              "
              :width="16"
              :height="16"
              view-box="0 0 24 24"
              style="margin-bottom: -4px"
            >
              arrow_downward
            </CRIcon>
            <v-icon v-else small style="width: 16px">fa-fw</v-icon>
          </span>
        </th>
      </template>
      <template #items="props">
        <td
          :key="`device-id-sort-key-${pagination.sortBy}-${pagination.descending}`"
        >
          {{ props.item.trackingDeviceId }}
        </td>
        <td
          :key="`vehicle-name-sort-key-${pagination.sortBy}-${pagination.descending}`"
        >
          {{ props.item.vehicleName }}
        </td>
        <td :key="`vin-sort-key-${pagination.sortBy}-${pagination.descending}`">
          {{ props.item.trackingContextId }}
        </td>
        <td
          :key="`eld-type-table-sort-key-${pagination.sortBy}-${pagination.descending}`"
        >
          {{ props.item.eldType }}
        </td>
        <td
          :key="`received-on-sort-key-${pagination.sortBy}-${pagination.descending}`"
        >
          {{
            props.item.reportedOn !== null
              ? datetimeToShortLocalizedDateTime(props.item.reportedOn)
              : 'No data available'
          }}
        </td>
        <td
          :key="`received-on-sort-key-${pagination.sortBy}-${pagination.descending}`"
        >
          {{
            props.item.createdOn !== null
              ? datetimeToShortLocalizedDateTime(props.item.createdOn)
              : 'No data available'
          }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { datetimeToShortLocalizedDateTime } from '@/utils/time'

export default {
  props: {
    devices: {
      type: Array,
      default: () => [],
    },
    company: {
      type: Object,
      default: () => {},
    },
    isAffiliateView: {
      type: Boolean,
    },
  },
  data() {
    return {
      rowsPerPageItems: [...Array(10).keys()].map((x) => (x + 1) * 10),
      headers: [
        { text: 'Device ID', value: 'trackingDeviceId', sortable: true },
        { text: 'Vehicle Name', value: 'vehicleName', sortable: true },
        { text: 'VIN', value: 'trackingContextId', sortable: true },
        { text: 'ELD Type', value: 'eldType', sortable: true },
        { text: 'Last Report Time', value: 'reportedOn', sortable: true },
        { text: 'Created On', value: 'createdOn', sortable: true },
      ],
      deviceData: [],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'trackingDeviceId',
      },
    }
  },
  computed: {
    sortedDevices() {
      if (this.pagination.sortBy) {
        const sortedDevices = [...this.deviceData]

        sortedDevices.sort((a, b) => {
          if (
            typeof a[this.pagination.sortBy] === 'string' &&
            typeof b[this.pagination.sortBy] === 'string'
          ) {
            return this.pagination.descending
              ? b[this.pagination.sortBy].localeCompare(
                  a[this.pagination.sortBy]
                )
              : a[this.pagination.sortBy].localeCompare(
                  b[this.pagination.sortBy]
                )
          }

          return this.pagination.descending
            ? b[this.pagination.sortBy] - a[this.pagination.sortBy]
            : a[this.pagination.sortBy] - b[this.pagination.sortBy]
        })

        return sortedDevices
      }

      return this.deviceData
    },
  },
  async mounted() {
    this.deviceData = this.devices

    const params = {
      additionalQueries: this.isAffiliateView
        ? `referredCompanyId=${this.company.companyId}`
        : '',
      pageSize: -1,
    }
    const vehiclesData = await this.$store.dispatch(
      'vehicles/getVehicles',
      params
    )
    const vehicles = vehiclesData.data.resultList

    for (let i = 0; i < this.deviceData.length; i++) {
      this.deviceData[i].reportedOn = this.deviceData[i].gpsData[0]?.reportedOn
      if (this.deviceData[i].eldType) {
        this.deviceData[i].eldType =
          this.deviceData[i].eldType.charAt(0).toUpperCase() +
          this.deviceData[i].eldType.slice(1).toLowerCase()
      }

      const matchingVehicle = vehicles.find(
        (vehicle) =>
          vehicle.vinNumber?.toUpperCase() ===
          this.deviceData[i].trackingContextId?.toUpperCase()
      )

      if (matchingVehicle) {
        this.deviceData[i].vehicleName = matchingVehicle.vehicleName
      }
    }
    this.pagination.totalItems = this.deviceData.length
    this.sortByColumn(this.pagination.sortBy)
  },
  methods: {
    datetimeToShortLocalizedDateTime(datetime) {
      return datetimeToShortLocalizedDateTime(datetime)
    },
    sortByColumn(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false // Set the default order to ascending
      }
      this.$forceUpdate()
    },
  },
}
</script>
