import { render, staticRenderFns } from "./MarketplacePickupDestination.vue?vue&type=template&id=5955dcd3"
import script from "./MarketplacePickupDestination.vue?vue&type=script&lang=js"
export * from "./MarketplacePickupDestination.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VTooltip})
