<template>
  <v-container fluid style="padding-top: 0; padding-left: 0">
    <v-layout column sheet style="padding-top: 0; padding-left: 0">
      <DataTable v-bind="tableProps" />
      <p v-show="driverRate" style="font-size: 16px; margin: 0">
        Driver Rate: {{ currencyFilter(driverRate) }}
      </p>
    </v-layout>
  </v-container>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import driverRates from '@/services/driverRates'
import marketRates from '@/services/marketRates'
import { currencyFilter } from '@/utils/currency'
import { v4 } from 'uuid'

export default {
  components: {
    DataTable,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    isAffiliateView: {
      type: Boolean,
    },
  },
  data() {
    return {
      filters: () => [],
      sorts: () => [],
      currentPage: 1,
      itemsPerPage: 10,
      debounce: undefined,
      tableProps: {
        columns: [
          {
            _t_id: '13ec8ca8-33ce-4303-9528-521604b9dde1',
            text: 'Type',
            prop: 'vehicleType',
            filter: true,
            filterProp: 'vehicleType',
            filterType: 'contains',
            type: 'text',
          },
          {
            _t_id: '6742bacb-6a77-49aa-a8d4-1a533fd15b7e',
            text: 'Transfer',
            prop: 'transfer',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: 'fc6c55d9-b411-4c5b-adac-6f660c8774d4',
            text: 'Dead Mile',
            prop: 'deadMile',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: 'e40bbff9-bfda-4205-96f2-c9c83ac1cd8c',
            text: 'Live Mile',
            prop: 'liveMile',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: '5588da71-8f49-4195-92cc-0ee51c68b320',
            text: 'Hourly',
            prop: 'hourly',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: '53945430-26ad-40b9-bee6-b479fd4f789d',
            text: 'Hr Min',
            prop: 'hourlyMinimum',
          },
          {
            _t_id: 'f7ede755-748b-4111-a99a-2936e7be0fca',
            text: 'Daily',
            prop: 'daily',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: '2c86330f-03d8-4e6c-93ca-6c4168f0fc95',
            text: 'Marketplace',
            prop: 'marketplace',
            filter: true,
            filterType: 'eq',
            defaultHidden: true,
            computedText: (item) => (item === 1 ? 'Yes' : 'No'),
          },
          {
            _t_id: 'faee0a1f-7cdd-4387-b594-b2febcae38f2',
            text: 'Market ID',
            prop: 'marketId',
            filter: true,
            filterType: 'eq',
            defaultHidden: true,
          },
          {
            _t_id: 'be7c7640-0717-40f6-ba9c-bfd0555050d2',
            text: 'Company ID',
            prop: 'companyId',
            type: 'number',
            defaultHidden: true,
          },
        ],
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        initialFilters: [],
        pagination: {
          rowsPerPage: 10,
          page: 1,
        },
      },
      resultToTableMapper: {
        'Daily Rate': 'daily',
        'Hourly Rate': 'hourly',
        'Mileage Rate': 'liveMile',
        'Dead Mile Rate': 'deadMile',
        'Transfer Rate': 'transfer',
      },
      driverRate: null,
    }
  },
  mounted() {
    if (this.isAffiliateView) {
      const companyIdFilterObject = {
        column: {
          _t_id: 'be7c7640-0717-40f6-ba9c-bfd0555050d2',
          prop: 'companyId',
          filterType: 'eq',
        },
        value: this.company.companyId,
        hideOnFilterBar: true,
      }
      const marketplaceFilterObject = {
        column: {
          _t_id: '2c86330f-03d8-4e6c-93ca-6c4168f0fc95',
          text: 'Marketplace',
          prop: 'marketplace',
          filterType: 'eq',
        },
        value: 1,
        hideOnFilterBar: true,
      }
      this.tableProps.initialFilters.push(
        companyIdFilterObject,
        marketplaceFilterObject,
      )
    }
  },
  methods: {
    currencyFilter,
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    async getTable() {
      const requestId = v4()
      this.latestRequestId = requestId
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const results = await marketRates
        .tableView({
          page: this.currentPage,
          pageSize: -1,
          sort: sorts,
          filters: filters,
          additionalQueries: 'isAffiliateDetailsView=true',
        })
        .then((d) => d.data.resultList)
        .catch((e) => {})

      if (this.latestRequestId !== requestId) {
        return
      }

      this.tableProps.list = []
      this.marketRates = results

      results
        .filter((v) => !v.vehicleId && v.vehicleType)
        .forEach((_marketRate) => {
          const alreadyInputElementsWithSameVehicleType = this.tableProps.list.filter(
            (rate) => rate.vehicleType === _marketRate.vehicleType
          )
          let vehicleMarketRate
          if (alreadyInputElementsWithSameVehicleType?.[0]) {
            vehicleMarketRate = alreadyInputElementsWithSameVehicleType?.[0]
          } else {
            vehicleMarketRate = {
              vehicleType: _marketRate.vehicleType,
              originalData: [],
            }
            this.tableProps.list.push(vehicleMarketRate)
          }
          vehicleMarketRate[
            this.resultToTableMapper[_marketRate.marketRateType]
          ] = _marketRate.highRate
          if (_marketRate.marketRateType === 'Hourly Rate') {
            vehicleMarketRate.hourlyMinimum = _marketRate.hourlyMinimum
          }
          vehicleMarketRate.originalData.push(_marketRate)
        })

      const driverResults = await driverRates
        .getDriverRate({
          marketId: this.company?.address?.nearestMarketId || 1,
          isMarketplace: true,
          companyId: this.company.companyId,
        })
        .then((d) => d.data)
        .catch((e) => {})
      if (driverResults.driverRate) {
        this.driverRate = driverResults?.driverRate?.rate
      }
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
  },
}
</script>
