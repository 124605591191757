<template>
  <v-flex style="margin-right: 10px">
    <v-layout>
      <v-layout grow>
        <v-tabs v-model="selectedTab" class="mb-4">
          <template v-for="tab in tabs">
            <v-tab
              :id="`summary-trips-tab-${tab.text.toLowerCase()}`"
              :key="tab.text"
              class="mx-2"
            >
              {{ tab.text }}
            </v-tab>
          </template>
        </v-tabs>
      </v-layout>
      <v-flex shrink class="see-all-button" @click="$emit('see-all-bookings')">
        See All
      </v-flex>
    </v-layout>
    <div style="height: 200px; overflow-y: scroll">
      <v-flex
        v-for="trip in trips"
        :key="`summary-reservation-${trip.managedId}`"
        style="margin-bottom: 15px; margin-left: 20px"
      >
        <b>
          Reservation
          <router-link
            :to="{ path: `/reservations/${trip.parentReservationId}` }"
            style="text-decoration: none"
          >
            <span class="reservation-number">#{{ trip.managedId }}</span>
          </router-link>
        </b>
        <div style="font-size: 12px">
          {{ formattedDate(trip.pickupDate) }} -
          {{ formattedDate(trip.dropoffDate) }}
        </div>
        <div style="font-size: 12px">
          {{ trip.firstPickupAddressName }} - {{ trip.firstDropoffAddressName }}
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import { DateTime } from 'luxon'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'

export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      trips: [],
      filters: () => [],
      selectedTab: 0,
      pageSize: 5,
      tabs: [
        {
          text: 'Upcoming Trips',
          initialFilters: [
            {
              column: {
                _t_id: 'f6270abd-aa6a-4e17-ad5a-6f99dc47ec89',
                prop: 'pickupDate',
                filterType: 'gte',
                sort: true,
                sortProp: 'pickupDate',
                direction: 'asc',
                type: 'date',
                text: '',
              },
              value: DateTime.local().toFormat('yyyy-MM-dd'),
              hideOnFilterBar: true,
            },
            {
              column: {
                _t_id: 'a3bcda20-47ea-439b-aaf3-f94e9fa54fd4',
                prop: ['parentReservation/reservationStatus'],
                filterType: 'eq',
                text: '',
              },
              value: 'upcoming',
              hideOnFilterBar: true,
            },
          ],
        },
        {
          text: 'Previous Trips',
          initialFilters: [
            {
              column: {
                _t_id: '1385ddc5-6987-4f4d-8e8e-8572d3d34bc5',
                prop: 'pickupDate',
                filterType: 'lte',
                sort: true,
                sortProp: 'pickupDate',
                direction: 'desc',
                type: 'date',
                text: '',
              },
              value: DateTime.local().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
              hideOnFilterBar: true,
            },
            {
              column: {
                _t_id: '7ef959e4-8f07-49f5-828a-097fdf40ddba',
                prop: ['parentReservation/reservationStatus'],
                filterType: 'eq',
                text: '',
              },
              value: 'upcoming',
              hideOnFilterBar: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    initialFilters() {
      let allInitialFilters = [
        {
          column: {
            _t_id: '80e50e5c-6da5-4269-9a5b-7998439d6dc1',
            prop: 'allocatedFromReservationId',
            filterType: 'isnull',
            text: '',
            defaultHidden: true,
          },
          value: 'null',
          hideOnFilterBar: true,
        },
        {
          column: {
            _t_id: '36db9a80-26cc-4162-81d0-bf6ee0f75888',
            prop: 'reservationType',
            text: '',
            filterType: 'eq',
            detail: false,
            defaultHidden: true,
          },
          value: 1,
          hideOnFilterBar: true,
        },
      ]

      return allInitialFilters.concat(
        deepClone(this.tabs[this.selectedTab].initialFilters)
      )
    },
  },
  watch: {
    company: {
      immediate: true,
      handler() {
        if (this.company.companyId) {
          this.getTrips()
        }
      },
    },
    selectedTab: {
      immediate: true,
      handler() {
        if (this.company.companyId) {
          this.getTrips()
        }
      },
    },
  },
  methods: {
    formattedDate(date) {
      return DateTime.fromISO(date).toFormat('MM/dd/yy h:mma ZZZZ')
    },
    async getTrips() {
      const filterUtil = filter()
      const sortUtil = sort()

      for (var i = 0; i < this.initialFilters.length; i++) {
        let parentFilter = filterUtil.createParent('and')
        filterUtil.add(parentFilter, this.initialFilters[i])
        if (this.initialFilters[i].column.sort) {
          sortUtil.add(this.initialFilters[i].column)
        }
      }

      const filters = filterUtil.asQueryParams()
      const sorts = sortUtil.asQueryParams()
      const params = {
        filters,
        sorts,
        affiliateCompanyId: this.company.companyId,
        pageSize: this.pageSize,
        page: 1,
      }

      const tripData = await this.$store.dispatch(
        'reservations/affiliatesReservationsTV',
        params
      )
      this.trips = tripData.data.resultList
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-number {
  color: $primary;
}

.see-all-button {
  margin-top: 15px;
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
  color: $gray-medium-light;
}
</style>
