<template>
  <div>
    <v-badge color="primary" :value="isMultiTrip">
      <template #badge>
        <v-tooltip v-if="isMultiTrip" top>
          <template #activator="{ on }">
            <div v-on="on">
              {{ row.totalTrips }}
            </div>
          </template>
          <span class="text-white">
            {{ tooltipText }}
          </span>
        </v-tooltip>
      </template>
      {{ displayText }}
    </v-badge>
  </div>
</template>

<script>
import { numberToString, cityFromAddressName } from '@/utils/string'

export const cityFromAddress = function (address) {
  if (!address) {
    return null
  }

  if (address.city) {
    return address.city
  }
  const city = cityFromAddressName(address.addressName || address.name)
  if (city) {
    return city
  }
  return address.title
}

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
  },

  computed: {
    displayText() {
      const firstPickup = cityFromAddress(this.row.stops?.[0]?.address)
      const firstDropoff =
        cityFromAddress(this.row.stops?.[1]?.address) || firstPickup

      return `${firstPickup} > ${firstDropoff}`
    },

    isMultiTrip() {
      return this.row.totalTrips > 1
    },

    tooltipText() {
      if (!this.isMultiTrip) {
        return ''
      }
      return `This opportunity has ${numberToString(
        this.row.totalTrips
      )} trips.`
    },
  },
}
</script>
