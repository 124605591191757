import { DateTime } from 'luxon'
import { deepClone } from './deepClone'
import {
  getReservationLocalEndDatetime,
  getReservationLocalStartDatetime,
  getReservationLocalPickupTime,
  getReservationLocalDropoffTime,
  getReservationLocalStartTime,
  getReservationLocalEndTime,
  getUnassignedVehicles,
} from './reservation'

export const sortReservationBlocksByVehicle = (
  reservations,
  startOfWeek,
  numberOfDays
) => {
  const map = {}
  for (const reservation of reservations) {
    const itemStartDate = DateTime.fromISO(reservation.startDate)
    const itemEndDate = DateTime.fromISO(reservation.endDate)
    for (let dayIncrement = 0; dayIncrement < numberOfDays; dayIncrement++) {
      const day = DateTime.fromISO(startOfWeek)
        .plus({ days: dayIncrement })
        .startOf('day')
      if (day >= itemStartDate && day <= itemEndDate) {
        for (const va of reservation.vehicleAssignments) {
          if (!map[va.vehicleId]) {
            const newVehicleItem = {
              blocks: [],
              vehicle: va.vehicle,
            }
            map[va.vehicleId] = newVehicleItem
          }
          const foundBlock = map[va.vehicleId].blocks.find(
            (b) => b.startDate === day.toISODate()
          )
          if (!foundBlock) {
            const reservationBlock = {
              startDate: day.toISODate(),
              endDate: day.toISODate(),
              reservations: [deepClone(reservation)],
            }
            map[va.vehicleId].blocks.push(reservationBlock)
          } else {
            foundBlock.reservations.push(deepClone(reservation))
          }
        }
      }
    }
  }
  return map
}

export const sortAvailabilityBlocksByVehicle = (
  vehicleBlocks,
  startOfWeek,
  numberOfDays
) => {
  const map = {}
  for (const ab of vehicleBlocks) {
    if (!map[ab.vehicleId]) {
      const newMapItem = {
        associatedAvailabilityBlock: [],
      }
      map[ab.vehicleId] = newMapItem
    }
    const itemStartDate = DateTime.fromISO(ab.startDate)
    const itemEndDate = DateTime.fromISO(ab.endDate)
    for (let dayIncrement = 0; dayIncrement < numberOfDays; dayIncrement++) {
      const day = DateTime.fromISO(startOfWeek)
        .plus({ days: dayIncrement })
        .startOf('day')
      if (day >= itemStartDate && day <= itemEndDate) {
        const vehicleAvailabilityBlock = deepClone(ab)
        vehicleAvailabilityBlock.startDate = day.toISODate()
        vehicleAvailabilityBlock.endDate = day.toISODate()
        map[ab.vehicleId].associatedAvailabilityBlock.push(
          vehicleAvailabilityBlock
        )
      }
    }
  }
  return map
}

export const sortTypeAvailabilityBlocksByVehicle = (vehicleBlocks) => {
  const map = {}
  for (const ab of vehicleBlocks) {
    if (!map[ab.vehicleTypeId]) {
      const newMapItem = {
        associatedAvailabilityBlock: [],
      }
      map[ab.vehicleTypeId] = newMapItem
    }
    const vehicleAvailabilityBlock = deepClone(ab)
    map[ab.vehicleTypeId].associatedAvailabilityBlock.push(
      vehicleAvailabilityBlock
    )
  }
  return map
}

export const createCompanyFillerBlock = (
  companyId,
  vehicleIdToStartingHeight,
  startOfWeek,
  endOfWeek
) => {
  const companyTop = vehicleIdToStartingHeight[companyId + ':' + companyId]
  const newCompanyFillerBlock = {
    startDate: startOfWeek,
    endDate: endOfWeek,
    top: companyTop,
    isCompanyFillerBlock: true,
  }
  return newCompanyFillerBlock
}

export const getVehicleTypeGeneralAvailabilityBlocks = (
  vehicleKeyRows,
  vehicleIdToStartingHeight,
  startOfWeek,
  numberOfDays,
  vehicleTypes,
  offeredVehicles,
  notOfferedVehicles,
  unassignedVehicles,
  assignedVehicles,
  vehicleBlocks,
  vehicleTypeBlocks,
  companies,
  addInCalendarView,
  addInCalendarAdd
) => {
  const generalVehicleTypeBlocks = []
  for (const vehicleType of vehicleTypes) {
    const unassignedVehiclesByType = unassignedVehicles.filter((uv) => {
      return uv.vehicleTypeId === vehicleType.vehicleTypeId
    })
    const offeredVehiclesByType = offeredVehicles.filter((ov) => {
      return ov.vehicleTypeId === vehicleType.vehicleTypeId
    })
    const notOfferedVehiclesByType = notOfferedVehicles.filter((nov) => {
      return nov.vehicleTypeId === vehicleType.vehicleTypeId
    })
    const assignedVehiclesByType = assignedVehicles.filter((av) => {
      return av.vehicleTypeId === vehicleType.vehicleTypeId
    })
    const vehicleBlocksByType = vehicleBlocks.filter((vb) => {
      return vb.vehicleTypeId === vehicleType.vehicleTypeId
    })
    const vehicleTypeBlocksByType = vehicleTypeBlocks.filter((vtb) => {
      return vtb.vehicleTypeId === vehicleType.vehicleTypeId
    })

    const totalVehicles = vehicleKeyRows.find((vkr) => {
      return !vkr.companyId && vkr.vehicleTypeId === vehicleType.vehicleTypeId
    })?.vehicleTypeQuantity
    for (let dayIncrement = 0; dayIncrement < numberOfDays; dayIncrement++) {
      const start = DateTime.fromISO(startOfWeek)
        .plus({ days: dayIncrement })
        .startOf('day')
      const end = DateTime.fromISO(startOfWeek)
        .plus({ days: dayIncrement })
        .endOf('day')
      if (
        totalVehicles ||
        offeredVehiclesByType ||
        notOfferedVehiclesByType ||
        unassignedVehiclesByType ||
        assignedVehiclesByType ||
        vehicleBlocksByType
      ) {
        const generalVehicleBlock = createGeneralAvailabilityBlockByDate(
          totalVehicles,
          vehicleIdToStartingHeight[
            vehicleType.vehicleTypeId + ':' + vehicleType.vehicleTypeId
          ],
          start,
          end,
          vehicleType.vehicleTypeId,
          vehicleType.vehicleTypeName,
          offeredVehiclesByType,
          notOfferedVehiclesByType,
          unassignedVehiclesByType,
          assignedVehiclesByType,
          vehicleBlocksByType,
          vehicleTypeBlocksByType,
          companies,
          true,
          addInCalendarView,
          false,
          addInCalendarAdd
        )
        if (generalVehicleBlock) {
          generalVehicleTypeBlocks.push(generalVehicleBlock)
        }
      }
    }
  }

  return generalVehicleTypeBlocks
}

export const getGeneralAvailabilityBlocks = (
  vehicleKeyRows,
  vehicleIdToStartingHeight,
  startOfWeek,
  numberOfDays,
  vehicleTypes,
  offeredVehicles,
  unassignedVehicles,
  assignedVehicles,
  vehicleBlocks,
  vehicleTypeBlocks,
  company,
  addInCalendarView,
  addedBlocks,
  deletedBlocks
) => {
  const generalVehicleBlocks = []
  for (const vehicleType of vehicleTypes) {
    const unassignedVehiclesByType = unassignedVehicles.filter((uv) => {
      return uv.vehicleTypeId === vehicleType.vehicleTypeId
    })
    const offeredVehiclesByType = offeredVehicles.filter((ov) => {
      return ov.vehicleTypeId === vehicleType.vehicleTypeId
    })
    const assignedVehiclesByType = assignedVehicles.filter((av) => {
      return av.vehicleTypeId === vehicleType.vehicleTypeId
    })
    const vehicleBlocksByType = vehicleBlocks.filter((vb) => {
      return vb.vehicleTypeId === vehicleType.vehicleTypeId
    })
    const vehicleTypeBlocksByType = vehicleTypeBlocks.filter((vtb) => {
      return vtb.vehicleTypeId === vehicleType.vehicleTypeId
    })
    const totalVehicles = vehicleKeyRows.find((vkr) => {
      return (
        vkr.companyId === company.companyId &&
        vkr.vehicleTypeId === vehicleType.vehicleTypeId
      )
    })?.vehicleTypeQuantity
    for (let dayIncrement = 0; dayIncrement < numberOfDays; dayIncrement++) {
      const start = DateTime.fromISO(startOfWeek)
        .plus({ days: dayIncrement })
        .startOf('day')
      const end = DateTime.fromISO(startOfWeek)
        .plus({ days: dayIncrement })
        .endOf('day')
      const isAddedBlock = !!addedBlocks.find(
        (ab) =>
          (ab.vehicleId === null || ab.vehicleId === undefined) &&
          ab.companyId === company.companyId &&
          ab.vehicleTypeId === vehicleType.vehicleTypeId &&
          ab.startDate === start.toISODate() &&
          ab.endDate === end.toISODate()
      )
      if (
        totalVehicles ||
        offeredVehiclesByType ||
        unassignedVehiclesByType ||
        assignedVehiclesByType ||
        vehicleBlocksByType
      ) {
        const generalVehicleBlock = createGeneralAvailabilityBlockByDate(
          totalVehicles,
          vehicleIdToStartingHeight[
            company.companyId + ':' + vehicleType.vehicleTypeId
          ],
          start,
          end,
          vehicleType.vehicleTypeId,
          vehicleType.vehicleTypeName,
          offeredVehiclesByType,
          [],
          unassignedVehiclesByType,
          assignedVehiclesByType,
          vehicleBlocksByType,
          vehicleTypeBlocksByType,
          [company],
          false,
          addInCalendarView,
          isAddedBlock,
          addedBlocks,
          deletedBlocks
        )
        if (generalVehicleBlock) {
          generalVehicleBlocks.push(generalVehicleBlock)
        }
      }
    }
  }

  return generalVehicleBlocks
}

const createGeneralAvailabilityBlockByDate = (
  totalVehicles,
  top,
  startDate,
  endDate,
  vehicleTypeId,
  vehicleTypeName,
  offeredVehicles,
  notOfferedVehicles,
  unassignedVehicles,
  assignedVehicles,
  vehicleBlocks,
  vehicleTypeBlocks,
  companies,
  isVehicleTypeGeneral,
  addInCalendarView,
  isAddedBlock,
  addedBlocks,
  deletedBlocks
) => {
  if (!top) {
    return
  }
  const newGeneralBlock = {
    top: top,
    startDate: DateTime.fromISO(startDate),
    endDate: DateTime.fromISO(endDate),
    vehicleTypeId: vehicleTypeId,
    vehicleTypeName: vehicleTypeName,
    offeredReservations: [],
    notOfferedReservations: [],
    unassignedReservations: [],
    assignedReservations: [],
    vehicleBlocks: [],
    isGeneralBlock: true,
    addInCalendarView: addInCalendarView,
    availableVehicles: totalVehicles,
    overbookedVehicles: 0,
    notOfferedVehicles: 0,
    companies: companies,
    isAddedBlock: isAddedBlock,
  }

  const newVehicleBlocks = []
  const blockedVehicleIds = []
  for (const vehicleBlock of vehicleBlocks) {
    const itemStartDate = DateTime.fromISO(vehicleBlock.startDate).startOf(
      'day'
    )
    const itemEndDate = DateTime.fromISO(vehicleBlock.endDate).endOf('day')
    if (
      (((itemStartDate <= startDate && itemEndDate >= endDate) ||
        (startDate <= itemStartDate && endDate >= itemStartDate) ||
        (startDate <= itemEndDate && endDate >= itemEndDate)) &&
      !newVehicleBlocks.find((vb) => vb.vehicleId === vehicleBlock.vehicleId))
    ) {
      newGeneralBlock.availableVehicles -= 1
      blockedVehicleIds.push(vehicleBlock.vehicleId)
      newVehicleBlocks.push(vehicleBlock)
    }
  }
  newGeneralBlock.vehicleBlocks = newVehicleBlocks

  for (const vehicleTypeBlock of vehicleTypeBlocks) {
    const itemStartDate = DateTime.fromISO(vehicleTypeBlock.startDate).startOf(
      'day'
    )
    const itemEndDate = DateTime.fromISO(vehicleTypeBlock.endDate).endOf('day')
    if (
      (itemStartDate <= startDate && itemEndDate >= endDate) ||
      (startDate <= itemStartDate && endDate >= itemStartDate) ||
      (startDate <= itemEndDate && endDate >= itemEndDate)
    ) {
      if (isVehicleTypeGeneral) {
        for (const vehicle of vehicleTypeBlock.vehicles) {
          if (
            !newGeneralBlock.vehicleBlocks.find(
              (vb) => vb.vehicleId === vehicle.vehicleId
            )
          ) {
            newGeneralBlock.availableVehicles -= 1
            newGeneralBlock.vehicleBlocks.push({
              availabilityBlockId: vehicleTypeBlock.availabilityBlockId,
              vehicleName: vehicle.vehicleName,
              vehicleId: vehicle.vehicleId,
              companyId: vehicle.companyId,
            })
          }
        }
      }
    }
  }

  for (const offeredVehicle of offeredVehicles) {
    const itemStartDate = DateTime.fromISO(offeredVehicle.startDate).startOf(
      'day'
    )
    const itemEndDate = DateTime.fromISO(offeredVehicle.endDate).endOf('day')
    if (
      (itemStartDate <= startDate && itemEndDate >= endDate) ||
      (startDate <= itemStartDate && endDate >= itemStartDate) ||
      (startDate <= itemEndDate && endDate >= itemEndDate)
    ) {
      newGeneralBlock.availableVehicles -= offeredVehicle.quantity
      newGeneralBlock.offeredReservations.push(offeredVehicle.reservation)
    }
  }

  for (const notOfferedVehicle of notOfferedVehicles) {
    const itemStartDate = DateTime.fromISO(notOfferedVehicle.startDate).startOf(
      'day'
    )
    const itemEndDate = DateTime.fromISO(notOfferedVehicle.endDate).endOf('day')
    if (
      (itemStartDate <= startDate && itemEndDate >= endDate) ||
      (startDate <= itemStartDate && endDate >= itemStartDate) ||
      (startDate <= itemEndDate && endDate >= itemEndDate)
    ) {
      newGeneralBlock.notOfferedVehicles += notOfferedVehicle.quantity
      newGeneralBlock.notOfferedReservations.push(notOfferedVehicle.reservation)
    }
  }

  for (const unassignedVehicle of unassignedVehicles) {
    const itemStartDate = DateTime.fromISO(unassignedVehicle.startDate).startOf(
      'day'
    )
    const itemEndDate = DateTime.fromISO(unassignedVehicle.endDate).endOf('day')
    if (
      (itemStartDate <= startDate && itemEndDate >= endDate) ||
      (startDate <= itemStartDate && endDate >= itemStartDate) ||
      (startDate <= itemEndDate && endDate >= itemEndDate)
    ) {
      newGeneralBlock.availableVehicles -= unassignedVehicle.quantity
      newGeneralBlock.unassignedReservations.push(unassignedVehicle.reservation)
    }
  }

  const uniqueVehicles = {}
  for (const assignedVehicle of assignedVehicles) {
    const itemStartDate = DateTime.fromISO(assignedVehicle.startDate).startOf(
      'day'
    )
    const itemEndDate = DateTime.fromISO(assignedVehicle.endDate).endOf('day')
    if (
      (itemStartDate <= startDate && itemEndDate >= endDate) ||
      (startDate <= itemStartDate && endDate >= itemStartDate) ||
      (startDate <= itemEndDate && endDate >= itemEndDate)
    ) {
      for (const va of assignedVehicle.reservation.vehicleAssignments) {
        if (!blockedVehicleIds.includes(va.vehicleId)) {
          if (!uniqueVehicles[va.vehicleId]) {
            uniqueVehicles[va.vehicleId] = 1
          } else {
            uniqueVehicles[va.vehicleId] = uniqueVehicles[va.vehicleId] + 1
          }
        }
      }
      newGeneralBlock.assignedReservations.push(assignedVehicle.reservation)
    }
  }
  newGeneralBlock.availableVehicles -= Object.keys(uniqueVehicles).length

  const pendingAddedVehicleBlocks = addedBlocks?.filter((a) => a.vehicleId !== undefined && a.vehicleId !== null && a.vehicleTypeId === vehicleTypeId && a.companyId === companies[0]?.companyId)
  if (pendingAddedVehicleBlocks) {
    for (const vehicleBlock of pendingAddedVehicleBlocks) {
      const itemStartDate = DateTime.fromISO(vehicleBlock.startDate).startOf(
        'day'
      )
      const itemEndDate = DateTime.fromISO(vehicleBlock.endDate).endOf('day')
      if (((itemStartDate <= startDate && itemEndDate >= endDate) ||
        (startDate <= itemStartDate && endDate >= itemStartDate) ||
        (startDate <= itemEndDate && endDate >= itemEndDate))) {
          newGeneralBlock.availableVehicles -= 1
      }
    }
  }
  const pendingDeletedVehicleBlocks = deletedBlocks?.filter((a) => a.vehicleId !== undefined && a.vehicleId !== null && a.vehicleTypeId === vehicleTypeId && a.companyId === companies[0]?.companyId)
  if (pendingDeletedVehicleBlocks) {
    for (const vehicleBlock of pendingDeletedVehicleBlocks) {
      const itemStartDate = DateTime.fromISO(vehicleBlock.startDate).startOf(
        'day'
      )
      const itemEndDate = DateTime.fromISO(vehicleBlock.endDate).endOf('day')
      if (((itemStartDate <= startDate && itemEndDate >= endDate) ||
        (startDate <= itemStartDate && endDate >= itemStartDate) ||
        (startDate <= itemEndDate && endDate >= itemEndDate))) {
          newGeneralBlock.availableVehicles += 1
      }
    }
  }

  newGeneralBlock.offeredReservations = newGeneralBlock.offeredReservations.filter(
    (reservation, resIndex, self) =>
      resIndex ===
      self.findIndex((r) => r.reservationId === reservation.reservationId)
  )
  newGeneralBlock.notOfferedReservations = newGeneralBlock.notOfferedReservations.filter(
    (reservation, resIndex, self) =>
      resIndex ===
      self.findIndex((r) => r.reservationId === reservation.reservationId)
  )
  newGeneralBlock.unassignedReservations = newGeneralBlock.unassignedReservations.filter(
    (reservation, resIndex, self) =>
      resIndex ===
      self.findIndex((r) => r.reservationId === reservation.reservationId)
  )
  newGeneralBlock.assignedReservations = newGeneralBlock.assignedReservations.filter(
    (reservation, resIndex, self) =>
      resIndex ===
      self.findIndex((r) => r.reservationId === reservation.reservationId)
  )
  newGeneralBlock.unavailableVehicles =
    totalVehicles - newGeneralBlock.availableVehicles
  if (newGeneralBlock.availableVehicles < 0) {
    newGeneralBlock.overbookedVehicles = 0 - newGeneralBlock.availableVehicles
    newGeneralBlock.availableVehicles = 0
    newGeneralBlock.unavailableVehicles = totalVehicles
  }
  if (
    isVehicleTypeGeneral &&
    newGeneralBlock.availableVehicles - newGeneralBlock.notOfferedVehicles < 0
  ) {
    newGeneralBlock.overbookedVehicles =
      0 -
      (newGeneralBlock.availableVehicles - newGeneralBlock.notOfferedVehicles)
  }
  return newGeneralBlock
}

export const isUnassignedVehicleTypeOverlap = (
  vehiclesList,
  vehicleTypeBlocks,
  startOfWeek,
  endOfWeek
) => {
  let overlap = false
  for (const vtb of vehicleTypeBlocks) {
    const vehicleTypeStart = DateTime.max(
      DateTime.fromISO(vtb.startDate),
      startOfWeek
    )
    const vehicleTypeEnd = DateTime.min(
      DateTime.fromISO(vtb.endDate),
      endOfWeek
    )
    for (const v of vehiclesList) {
      const unassignedStart = DateTime.fromISO(v.startDate)
      if (
        unassignedStart >= vehicleTypeStart &&
        unassignedStart <= vehicleTypeEnd
      ) {
        overlap = true
      }
    }
  }
  return overlap
}

export const convertReservationToAvailabilityBlock = (reservation) => {
  const startDate = getReservationLocalStartDatetime(reservation)
  const endDate = getReservationLocalEndDatetime(reservation)
  const startTime = getReservationLocalStartTime(reservation)
  const endTime = getReservationLocalEndTime(reservation)
  const pickupLocation = reservation?.trip?.stops[0]?.address?.name
  const pickupTime = getReservationLocalPickupTime(reservation)
  const dropoffTime = getReservationLocalDropoffTime(reservation)
  const driversAreFullyAssigned = reservation.assignedDriverPercentage >= 100
  const vehiclesAreFullyAssigned = reservation.assignedVehiclePercentage >= 100
  const referralStatus = reservation.referralStatus
  const reservationStatusKey = reservation.reservationStatusKey
  const unassignedVehicles = getUnassignedVehicles(reservation)
  const companyName = reservation?.company?.name
  const availabilityReservation = {
    reservationId: reservation.reservationId,
    managedReservationId: reservation.managedId,
    parentReservationId: reservation.parentReservationId,
    vehicleAssignments: reservation.vehicleAssignments || [],
    startDate,
    endDate,
    startTime,
    endTime,
    pickupLocation,
    pickupTime,
    dropoffTime,
    requiredVehicles: reservation.requiredVehicles,
    assignedVehicles: reservation.vehicleAssignments,
    companyId: reservation.companyId,
    companyName,
    driversAreFullyAssigned,
    vehiclesAreFullyAssigned,
    unassignedVehicles,
    referralStatus,
    reservationStatusKey,
    isPreBooking: reservation.isPreBooking,
  }

  return availabilityReservation
}

export const AVAILABILITY_ROW_HEIGHT = 40
