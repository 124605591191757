<template>
  <Sheet id="affiliate-detail" class="affiliate-detail">
    <v-layout
      row
      align-center
      style="justify-content: space-between; padding-right: 54px"
    >
      <div>
        <h1 style="display: flex; align-items: center; font-size: 24px">
          {{ companyName }}
          <img v-if="company.isPreferred" height="22" class="padding-l-2" src="@/assets/images/CharterUP.svg" />
        </h1>
      </div>
      <div v-if="!isCharterUpScoreFactorsDisabled">
        <AffiliateRating :rating="company.charterupScore" />
      </div>
    </v-layout>
    <div>
      <v-tabs v-model="selectedTab" class="mb-4 mt-2">
        <template v-for="component in affiliateComponents">
          <v-tab
            :id="`affiliate-tab-${component.text.toLowerCase()}`"
            :key="component.text"
            class="mx-2"
          >
            {{ component.text }}
          </v-tab>
        </template>
      </v-tabs>
      <keep-alive>
        <component
          :is="affiliateComponents[selectedTab].value"
          :id="`${id}-affiliate-component-selector`"
          v-bind="affiliate"
          :company="company"
          is-affiliate-view
          @refresh="$emit('refresh')"
          @see-all-bookings="seeAllBookings"
        />
      </keep-alive>
    </div>
  </Sheet>
</template>

<script>
import { authComputed } from '@/state/helpers'
import { capitalize } from '@/utils/string'
import { EventBus } from '@/utils/event-bus'

import Sheet from '@/layouts/Sheet.vue'
import AffiliateGarages from '@/components/AffiliateGarages.vue'
import AffiliatesSummary from '@/components/AffiliatesSummary.vue'
import AffiliateAdjustmentsMinimums from '@/components/AffiliateAdjustmentsMinimums.vue'
import AffiliateAvailability from '@/components/AffiliateAvailability.vue'
import AffiliateDrivers from '@/components/AffiliateDrivers.vue'
import AffiliatePartnershipSettings from '@/components/AffiliatePartnershipSettings.vue'
import AffiliateRatesMarketRateReviews from '@/components/AffiliateRatesMarketRateReviews.vue'
import AffiliateMarketplace from '@/components/AffiliateMarketplace.vue'
import AffiliateBookings from '@/components/AffiliateBookings.vue'
import AffiliateVehicles from '@/components/AffiliateVehicles.vue'
import AffiliateRating from '@/components/AffiliateRating.vue'
import AffiliateIntegrations from '@/components/AffiliateIntegrations.vue'
import companies from '@/services/companies'

const ALL_BOOKINGS_TAB_INDEX = 7

export default {
  components: {
    Sheet,
    AffiliatesSummary,
    AffiliateAdjustmentsMinimums,
    AffiliateAvailability,
    AffiliateBookings,
    AffiliateDrivers,
    AffiliatePartnershipSettings,
    AffiliateRatesMarketRateReviews,
    AffiliateVehicles,
    AffiliateRating,
    AffiliateIntegrations,
  },
  metaInfo() {
    return {
      title: `${this.company.name}`,
    }
  },
  data() {
    return {
      selectedTab: 0,
      isCharterUpScoreFactorsDisabled: false,
      company: {
        companyId: this.$route.params.id,
      },
      affiliateComponents: [
        { text: 'Summary', value: AffiliatesSummary },
        { text: 'Availability', value: AffiliateAvailability },
        { text: 'Vehicles', value: AffiliateVehicles },
        { text: 'Drivers', value: AffiliateDrivers },
        { text: 'Garages', value: AffiliateGarages },
        { text: 'Marketplace', value: AffiliateMarketplace },
        { text: 'Bookings', value: AffiliateBookings },
        { text: 'Rates', value: AffiliateRatesMarketRateReviews },
        { text: 'Adjustments/Minimums', value: AffiliateAdjustmentsMinimums },
        { text: 'Partnership Settings', value: AffiliatePartnershipSettings },
        { text: 'Integrations', value: AffiliateIntegrations },
      ],
    }
  },
  computed: {
    ...authComputed,
    companyName() {
      return this.company.name
    },
  },
  async mounted() {
    this.loadCompany()
    EventBus.$on('affiliates-details-refresh', () => {
      this.loadCompany()
    })

    const { getters = {} } = this.$store
    this.isCharterUpScoreFactorsDisabled =
      getters['systemParameters/isCharterUpScoreFactorsDisabled']
  },
  methods: {
    capitalize,
    seeAllBookings() {
      this.selectedTab = ALL_BOOKINGS_TAB_INDEX
    },
    async loadCompany() {
      const companyResponse = await companies.getCompany(this.$route.params.id)
      this.company = companyResponse.data.company
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  margin: 0;
}

.offer-btn {
  min-height: 40px;
  color: $white;
}

.layout.row > *:not(:first-child) {
  margin-right: 0;
  margin-left: 8px;
}
</style>
