<template>
  <v-layout row>
    <div class="with-margin">
      <v-tooltip top>
        <template #activator="action">
          <span
            class="item-space"
            style="margin-left: 10px"
            v-on="action.on"
            @click="handler"
          >
            <CRIcon
              :id="`view-edit-row-${row.markupId}`"
              color="primary"
              view-box="0 0 24 24"
              :width="22"
              :height="22"
            >
              remove_red_eye
            </CRIcon>
          </span>
        </template>
        <span>View Details</span>
      </v-tooltip>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    handler: {
      type: Function,
      default: () => null,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 22px;
}

.v-datatable .v-icon {
  margin-right: 2px;
  font-size: 22px;

  &:last-child {
    margin-right: 0;
  }
}

.action-links a,
.action-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-decoration: none;
}

.with-margin {
  margin-right: 2px;
}

.action-text {
  padding-left: 8px;
}
</style>
