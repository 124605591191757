<template>
  <v-container>
    <div>
      <div>
        <div class="headline">Deactivate driver?</div>
        <span>Are you sure you would like to deactivate this driver?</span>
        <div class="button-container">
          <v-spacer />
          <v-btn
            :id="`${id}-activate-driver-button-cancel`"
            class="btn-secondaryaction"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :id="`${id}-deactivate-driver-button-deactivate`"
            class="deactivate-driver"
            flat
            @click="deactivateDriver"
          >
            Deactivate Driver
          </v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import drivers from '@/services/drivers'
import { EventBus } from '@/utils/event-bus'

export default {
  name: 'DeactivateDriver',
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      },
    },
    close: {
      type: Function,
      default: () => {
        return function () {}
      },
    },
    id: {
      type: String,
      default: null,
    },
  },
  methods: {
    deactivateDriver() {
      drivers.deactivateDriver(this.row.userId).then(() => {
        this.$store.dispatch('app/showAlert', {
          message: `Driver successfully deactivated.`,
        })
        this.close()
        EventBus.$emit('global-table-view-refresh')
      })
    },
    cancel() {
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  margin-top: 10px;
  margin-left: -10px;
}

.deactivate-driver {
  color: white;
  background-color: $red;
}
</style>
