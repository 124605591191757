<template>
  <div style="height: 100%">
    <div
      class="recenter-button"
      :class="{
        disabled: !customPosition,
        mobile: $vuetify.breakpoint.xs,
      }"
      @click="handleRecenter"
    >
      <CRIcon width="35" height="35" :style="{ margin: '10px' }">
        crosshairs_gps
      </CRIcon>
    </div>
    <GmapMap
      ref="gMap"
      class="map"
      :zoom="mapConfig.zoom"
      :center="mapConfig.center"
      :options="mapConfig.options"
    />
  </div>
</template>

<script>
import { enterpriseMapStyles, mapStyles } from '@/components/mapStyles.js'
import { gmapApi } from 'vue2-google-maps'

export default {
  props: {
    filteredVehicles: {
      type: Array,
      default: () => [],
    },
    refreshInterval: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      directionsService: null,
      directionsServiceRequestCounter: 0,
      directionDisplays: [],
      map: null,
      bounds: null,
      vehicles: [],
      vehicleMarkers: [],
      infoWindows: [],
      customPosition: false,
      mapConfig: {
        center: { lat: 33.8458485, lng: -84.3716548 },
        zoom: 18,
        options: {
          clickableIcons: false,
          streetViewControl: false,
          fullScreenControl: true,
          mapTypeControl: false,
          styles: enterpriseMapStyles,
          fullscreenControlOptions: {
            position: -1,
          },
          gestureHandling: 'greedy',
          minZoom: 2,
          maxZoom: 20,
        },
      },
    }
  },
  computed: {
    google: gmapApi,
  },
  watch: {
    filteredVehicles: {
      deep: true,
      async handler() {
        await this.clearMap()
        this.drawVehicles()
        if (!this.customPosition) {
          this.handleRecenter()
        }
      },
    },
    directionsServiceRequestCounter() {
      if (this.directionsServiceRequestCounter >= 100) {
        this.directionsServiceRequestCounter = 0
        this.initializeDirectionsService()
      }
    },
  },
  async mounted() {
    this.mapConfig.options.styles = mapStyles
    this.map = await this.$refs.gMap.$mapPromise
    this.bounds = new this.google.maps.LatLngBounds()
    this.initializeDirectionsService()
    setTimeout(() => {
      this.map.addListener('zoom_changed', () => this.handleCenterChanged())
      this.map.addListener('drag', () => this.handleCenterChanged())
    }, 1000)
  },
  methods: {
    clearMap() {
      this.clearInfoWindows()
      this.clearVehicles()
      this.bounds = null
      this.bounds = new this.google.maps.LatLngBounds()
    },
    clearVehicles() {
      for (const marker of this.vehicleMarkers) {
        marker.setMap(null)
      }
      this.vehicleMarkers = []
    },
    clearInfoWindows() {
      for (const infoWindow of this.infoWindows) {
        infoWindow.close()
      }
      for (const infoWindow of this.infoWindows) {
        infoWindow.setMap(null)
      }
      this.infoWindows = []
    },
    initializeDirectionsService() {
      this.directionsService = (() => {
        const ds = new this.google.maps.DirectionsService()
        let counter = 1
        return (dOpts, dc) => {
          counter++
          setTimeout(() => {
            ds.route(dOpts, dc)
            counter--
          }, 80 * counter)
        }
      })()
    },
    drawVehicles() {
      const bounds = this.bounds
      for (const vehicle of this.filteredVehicles) {
        const infoWindow = this.makeVehicleInfoWindow(vehicle)
        const marker = this.makeVehicleMarker(
          vehicle.isInProgress ? '#ea7721' : '#bababa',
          vehicle.lat,
          vehicle.lng,
          infoWindow,
          vehicle.markerLabel,
          vehicle.isInProgress ? '#000000' : '#ffffff'
        )
        bounds.extend(marker.getPosition())
        marker.setMap(this.map)
      }
      if (!this.customPosition) {
        this.fitVehiclesInBounds(bounds)
      }
    },
    makeVehicleMarker(
      color,
      latitude,
      longitude,
      infoWindow,
      number,
      numberColor
    ) {
      const icon = {
        path:
          'M21,0.2c-11.6,0-21,9.4-21,21C0,27.4,2.7,33,7.1,36.9L21,50.8l13.9-13.9C39.3,33,42,27.4,42,21.2 C42,9.6,32.6,0.2,21,0.2z',
        fillColor: color,
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 0.75,
        anchor: new this.google.maps.Point(21, 50.8),
        labelOrigin: { x: 21, y: 21 },
      }

      const marker = this.makeMarker(
        this.map,
        latitude,
        longitude,
        icon,
        infoWindow,
        {
          color: numberColor,
          fontSize: '14px',
          fontWeight: '600',
          text: number.toString(),
        }
      )
      marker.setZIndex((number - 1) * 2)
      this.vehicleMarkers.push(marker)
      return marker
    },
    makeMarker(map, latitude, longitude, icon, infoWindow, label) {
      const marker = new this.google.maps.Marker({
        map: this.map,
        icon,
        position: new this.google.maps.LatLng(latitude, longitude),
        label: label,
      })
      if (infoWindow) {
        marker.addListener('mouseover', () => {
          infoWindow.open(map, marker)
        })
        marker.addListener('mouseout', () => {
          infoWindow.close(map, marker)
        })
      }
      return marker
    },
    makeVehicleInfoWindow(vehicle) {
      const infowindow = `
        <div style="height: 15px">
          <p>${vehicle.vehicleName}</p>
        </div>
        `
      const infoWindowObj = new this.google.maps.InfoWindow({
        content: infowindow,
      })
      this.infoWindows.push(infoWindowObj)
      return infoWindowObj
    },
    handleCenterChanged() {
      this.customPosition = true
    },
    handleRecenter() {
      this.fitVehiclesInBounds()
      setTimeout(() => {
        this.customPosition = false
      }, 0)
    },
    fitVehiclesInBounds(bounds = null) {
      if (bounds === null) {
        const sw = new this.google.maps.LatLng(34, -118)
        const ne = new this.google.maps.LatLng(45, -69)
        bounds = new this.google.maps.LatLngBounds(sw, ne)
        for (const marker of this.vehicleMarkers) {
          bounds.extend(marker.getPosition())
        }
      }
      this.bounds = bounds
      this.map.fitBounds(this.bounds)
      this.customPosition = false
    },
  },
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 650px;
  z-index: 0;
  margin-top: -50px;
}

.recenter-button {
  z-index: 3;
  position: relative;
  top: -2px;
  background: $white;
  border: 1px solid $border-gray !important;
  width: 48px;
  height: 48px;
  color: $primary !important;
  &.disabled {
    color: $border-gray !important;
    cursor: default !important;
  }
  &.public {
    margin-top: 68px;
    &.mobile {
      margin-top: 58px;
    }
  }
}

::v-deep button.gm-ui-hover-effect {
  display: none !important;
}

#markerLayer img {
  animation: pulse 0.5s infinite alternate;
  -webkit-animation: pulse 0.5s infinite alternate;
  transform-origin: center;
  -webkit-transform-origin: center;
}
</style>

<style lang="scss">
@media only screen and (max-width: 599px) {
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    display: none !important;
  }
}
</style>
