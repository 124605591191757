<template>
  <v-tooltip top>
    <template #activator="{ on, attrs }">
      <div
        style="
          height: 9px;
          width: 9px;
          border-radius: 25px;
          display: inline-flex;
        "
        :style="`background-color: ${
          hasRecentTrackingData ? $cr.theme.successNew : $cr.theme.errorNew
        }`"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <div v-if="!lastSyncOnly">
      <span class="font-weight-bold">Vehicle type:</span>
      {{ vehicleTypeName || 'N/A' }}
    </div>
    <div v-if="!lastSyncOnly">
      <span class="font-weight-bold">ELD type:</span>
      {{ eldType || 'N/A' }}
    </div>
    <div>
      <span class="font-weight-bold">Last report time:</span>
      {{ lastSyncDateTimeAgo }}
    </div>
    <div v-if="!lastSyncOnly">
      <span class="font-weight-bold">Device ID:</span>
      {{ trackerNumber || 'N/A' }}
    </div>
  </v-tooltip>
</template>

<script>
import { timeAgo } from '@/utils/time'
import { DateTime, Interval } from 'luxon'
import { mapGetters } from 'vuex'

const RECENT_TRACKING_MINUTES = 5

export default {
  props: {
    lastSyncOnly: { type: Boolean, default: false },
    vehicleId: { type: Number, default: null },
    vehicleTypeName: { type: String, default: null },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      devicesByVehicleIds: 'trackingDevices/devicesByVehicleIds',
    }),
    lastSyncDateTimeAgo() {
      if (this.lastSyncDate) {
        return timeAgo(this.lastSyncDate)
      }

      return 'N/A'
    },
    vehicleDevice() {
      return this.devicesByVehicleIds(this.vehicleId)
    },
    eldType() {
      return this.vehicleDevice?.eldType?.label
    },
    lastSyncDate() {
      return this.vehicleDevice?.lastReportTime
    },
    trackerNumber() {
      return this.vehicleDevice?.deviceId
    },
    hasRecentTrackingData() {
      if (!this.lastSyncDate) {
        return false
      }

      const lastSyncDateTime = DateTime.fromISO(this.lastSyncDate)
      const trackingDataInterval = Interval.fromDateTimes(
        lastSyncDateTime,
        DateTime.utc()
      )

      return trackingDataInterval.length('minutes') <= RECENT_TRACKING_MINUTES
    },
  },
}
</script>

<style lang="scss" scoped></style>
