<template>
  <div>
    <v-tabs v-model="selectedTab" class="mb-4">
      <template v-for="tab in tabs">
        <v-tab
          :id="`affiliate-rates-market-rate-reviews-tab-${tab.text.toLowerCase()}`"
          :key="`affiliate-rates-market-rate-reviews-tab-${tab.text.toLowerCase()}`"
          class="mx-2"
        >
          {{ tab.text }}
        </v-tab>
      </template>
    </v-tabs>
    <RatesList
      v-show="viewRates"
      :company="company"
      is-affiliate-view
      style="margin-left: 15px"
    />
    <MarketRateReviewsList
      v-show="viewMarketRateReviews"
      :company="company"
      is-affiliate-view
      style="margin-left: 15px"
    />
  </div>
</template>

<script>
import RatesList from '@/views/RatesList.vue'
import MarketRateReviewsList from '@/views/MarketRateReviewList.vue'

const RATES_TAB = 0
const MARKET_RATE_REVIEWS_TAB = 1

export default {
  components: {
    RatesList,
    MarketRateReviewsList,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          _t_id: 'efc9c47b-d284-40f1-89aa-7b6d6f34cc60',
          text: 'Rates',
          action: () => {
            this.viewRates = true
            this.viewMarketRateReviews = false
          },
        },
        {
          _t_id: 'd84e84fe-e509-4777-b35a-73187df523f5',
          text: 'Market Rate Reviews',
          action: () => {
            this.viewRates = false
            this.viewMarketRateReviews = true
          },
        },
      ],
    }
  },
  computed: {
    viewRates() {
      return this.selectedTab == RATES_TAB
    },
    viewMarketRateReviews() {
      return this.selectedTab == MARKET_RATE_REVIEWS_TAB
    },
  },
  methods: {
    isTabActive(tab) {
      if (tab._t_id === 'efc9c47b-d284-40f1-89aa-7b6d6f34cc60') {
        return this.viewRates
      }
      if (tab._t_id === 'd84e84fe-e509-4777-b35a-73187df523f5') {
        return this.viewMarketRateReviews
      }
      return false
    },
  },
}
</script>
<style lang="scss" scoped>
.affiliate-rates-market-rate-reviews-tab {
  line-height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  color: $gray-medium-light;
  border-width: 0 0 2px 0;
  border-style: solid;
  cursor: pointer;
}
.selected-tab {
  color: $blue;
  border-color: $blue;
}
.unselected-tab {
  border-style: none;
}
.unselected-tab:hover {
  border-style: solid !important;
}
</style>
