<template>
  <div style="position: relative; margin-left: 7px">
    <v-layout class="summary-section" row>
      <v-flex style="padding: 10px 20px 20px 10px; width: 50%">
        <AffiliatesSummaryBox :company="company" />
      </v-flex>
      <v-flex
        class="center-divider"
        style="padding: 10px 20px 20px 10px; width: 50%"
      >
        <AffiliatesTripsSummary
          :company="company"
          @see-all-bookings="$emit('see-all-bookings')"
        />
      </v-flex>
    </v-layout>
    <v-layout row style="padding-top: 40px">
      <AffiliatesSummaryTracking :company="company" />
    </v-layout>
  </div>
</template>

<script>
import AffiliatesSummaryTracking from '@/components/AffiliatesSummaryTracking.vue'
import AffiliatesSummaryBox from '@/components/AffiliatesSummaryBox.vue'
import AffiliatesTripsSummary from '@/components/AffiliatesTripsSummary.vue'

export default {
  components: {
    AffiliatesSummaryTracking,
    AffiliatesSummaryBox,
    AffiliatesTripsSummary,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-section {
  border: 1px solid $gray-light;
  border-radius: 5px;
}

.center-divider {
  border-left: 1px solid $gray-light;
}
</style>
