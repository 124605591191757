<template>
  <v-layout class="rating-border">
    <div class="mr-2">
      {{ label }}
    </div>
    <CharterUpRatingNewBus v-if="!rating" style="width: 46px" />
    <div
      v-for="starIndex in 5"
      v-else
      :key="`star-${starIndex}-${rating}`"
      :style="{ 'margin-right': starIndex !== 5 ? '2px' : 0 }"
      style="
        border-radius: 5px;
        display: inline-flex;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        position: relative;
      "
    >
      <v-icon color="white" size="20" style="z-index: 1">star</v-icon>
      <div
        :class="rating >= starIndex - 0.5 ? `${color}` : 'gray'"
        style="
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 12px;
          height: 100%;
          z-index: 0;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        "
      />
      <div
        :class="rating >= starIndex ? `${color}` : 'gray'"
        style="
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 12px;
          height: 100%;
          z-index: 0;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        "
      />
    </div>
  </v-layout>
</template>
<script>
import CharterUpRatingNewBus from '@/components/CharterUpRatingNewBus.vue'
export default {
  components: {
    CharterUpRatingNewBus,
  },
  props: {
    rating: {
      type: Number,
      default: () => 5,
    },
  },
  computed: {
    color() {
      return this.ratingToTextAndColor.color
    },
    label() {
      return this.ratingToTextAndColor.text
    },
    ratingToTextAndColor() {
      let color = 'blue'
      let text = 'Fantastic'
      if (!this.rating) {
        text = 'New'
        return { color, text }
      }
      if (this.rating < 2) {
        color = 'red'
        text = 'Bad'
        return { color, text }
      }
      if (this.rating < 3) {
        color = 'orange'
        text = 'Poor'
        return { color, text }
      }
      if (this.rating < 4) {
        color = 'yellow'
        text = 'Average'
        return { color, text }
      }
      if (this.rating === 4) {
        color = 'green'
        text = 'Great'
        return { color, text }
      }
      return { color, text }
    },
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.rating-border {
  display: flex;
  align-items: center;
  border: 1px solid $gray-light;
  border-radius: 4px;
  align-items: center;
  padding: 10px;
}
.blue {
  background-color: $blue;
}
.red {
  background-color: $red;
}
.orange {
  background-color: $orange;
}
.yellow {
  background-color: $yellow;
}
.green {
  background-color: $green;
}
.gray {
  background-color: $gray-mid-light;
}
</style>
