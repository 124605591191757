<template>
  <div>
    <span
      v-for="(vehicle, vehicleIndex) in sortedVehicles"
      :key="`required-vehicle-${vehicle.vehicleTypeId}-${vehicleIndex}`"
    >
      {{ formatVehicle(vehicle) }}
      <br />
    </span>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import { pluralize } from '@/utils/string'

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
  },

  computed: {
    sortedVehicles() {
      const vehicles = deepClone(this.row.requiredVehicles)
      return vehicles.sort((a, b) => a.vehicleTypeId - b.vehicleTypeId)
    },
  },
  methods: {
    formatVehicle(vehicle) {
      return `${vehicle.requiredVehicles} ${pluralize(
        vehicle.requiredVehicles,
        vehicle.vehicleType
      )}`
    },
  },
}
</script>
