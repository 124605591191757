<template>
  <div>
    <BaseTableWrapper
      :table-init="tableInit"
      :initial-filters="initialFilters"
      style="padding-top: 0"
      :style="
        isAffiliateView ? 'padding-top: 0; padding-left: 0;' : 'padding-top: 0'
      "
      :remove-padding="isAffiliateView"
    >
      <h1 v-if="!isAffiliateView" class="page-header">Minimums</h1>
    </BaseTableWrapper>
  </div>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import ViewTableItem from '@/components/ViewTableItem.vue'
import minimum from '@/services/minimum'
import GenericViewActionWrapper from '@/utils/GenericViewActionWrapper'
import { DateTime } from 'luxon'
import { EventBus } from '@/utils/event-bus'
import { authComputed } from '@/state/helpers'

export default {
  components: {
    BaseTableWrapper,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    isAffiliateView: {
      type: Boolean,
    },
  },
  data() {
    return {
      initialFilters: [],
      tableInit: {
        id: 'minimum-list',
        detailKeyId: 'minimumId',
        tableId: 'minimum_tv_view',
        addNewEnabled: !this.isAffiliateView,
        addNewHandler: this.addMinimum,
        areStatusFiltersShowing: true,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        forceRefresh: 0,
        columns: [],
        collection: 'Minimums',
        enableColumnConfig: !this.isAffiliateView,
        enableExport: !this.isAffiliateView,
        enableSavedViews: !this.isAffiliateView,
        additionalQueries: null,
        action: (params) => minimum.tableView(params),
      },
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    let columns = [
      {
        _t_id: '62a09068',
        prop: '/',
        text: 'Actions',
        component: GenericViewActionWrapper(ViewTableItem, this.viewMinimum),
        sort: false,
        filter: false,
        detail: false,
        type: 'actions',
        shrinkWidth: true,
      },
    ]
    if (!this.isAffiliateView) {
      columns.push({
        _t_id: '34ab7fb0',
        prop: 'marketMinimumGroupId',
        text: 'ID',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        detail: false,
        defaultSort: true,
      })
    }
    columns.push(
      {
        _t_id: 'f3300f96',
        prop: 'marketMinimumGroupName',
        text: 'Name',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'name',
        detail: false,
      },
      {
        _t_id: 'd640090e-3596-4382-8d5d-d100af7fc050',
        prop: 'vehicleTypes',
        text: 'Vehicle Types',
        computedText: (vehicleTypes) => this.mapVehicleTypes(vehicleTypes),
        sort: false,
        filter: false,
        type: 'text',
        detail: false,
      },
      {
        _t_id: 'f5a3ec7f',
        prop: 'days',
        text: 'Days',
        computedText: (days) => this.mapDays(days),
        sort: true,
        filter: false,
        type: 'text',
        detail: false,
      },
      {
        _t_id: 'd194b12c',
        prop: 'startDate',
        text: 'Start Date',
        computedText: (d) =>
          d ? DateTime.fromISO(d).toUTC().toISODate() : null,
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        detail: false,
      },
      {
        _t_id: '46845e2f',
        prop: 'endDate',
        text: 'End Date',
        computedText: (d) =>
          d ? DateTime.fromISO(d).toUTC().toISODate() : null,
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        detail: false,
      },
      {
        _t_id: 'e4db7524-213a-4f9c-824b-b1525f5d04aa',
        prop: 'companyId',
        text: 'Company ID',
        sort: false,
        filter: true,
        type: 'number',
        filterType: 'eq',
        detail: false,
        defaultHidden: true,
      }
    )
    if (!this.isAffiliateView) {
      columns.push(
        {
          _t_id: '676f88fe',
          prop: 'marketName',
          text: 'Market',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          filterProp: 'market.name',
          sortProp: 'market.name',
          detail: false,
        },
        {
          _t_id: '501bfcf4',
          prop: 'createdBy',
          text: 'Created By',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          detail: false,
        },
        {
          _t_id: '8a9e10b6',
          prop: 'createdOn',
          text: 'Created On',
          computedText: (d) =>
            d ? DateTime.fromISO(d).toUTC().toISODate() : null,
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          detail: false,
        },
        {
          _t_id: 'bd44a75e',
          prop: 'updatedBy',
          text: 'Updated By',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          detail: false,
        },
        {
          _t_id: 'c1b2917a',
          prop: 'updatedOn',
          text: 'Updated On',
          computedText: (d) =>
            d ? DateTime.fromISO(d).toUTC().toISODate() : null,
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          detail: false,
        }
      )
    }

    this.tableInit.columns.push(...columns)

    if (this.isAffiliateView) {
      this.tableInit.additionalQueries = 'isAffiliateDetailsView=true'
      this.initialFilters = [
        {
          column: columns.find(
            (column) => column._t_id === 'e4db7524-213a-4f9c-824b-b1525f5d04aa'
          ),
          value: this.company.companyId.toString(),
          hideOnFilterBar: true,
        },
      ]
    }

    EventBus.$on('market-minimum-tv-refresh', this.refreshTable)
  },
  methods: {
    mapDays(days) {
      return days.replace(/[1-7]/g, function (m) {
        return {
          1: 'Monday',
          2: 'Tuesday',
          3: 'Wednesday',
          4: 'Thursday',
          5: 'Friday',
          6: 'Saturday',
          7: 'Sunday',
        }[m]
      })
    },
    mapVehicleTypes(vehicleTypes) {
      if (!vehicleTypes) {
        return
      }
      return vehicleTypes
        .replace(
          /charter_bus|mini_bus|sprinter|party_bus|sedan|suv|limousine|school_bus|van/g,
          function (m) {
            return {
              charter_bus: 'Charter Bus',
              mini_bus: 'Mini Bus',
              sprinter: 'Sprinter',
              party_bus: 'Party Bus',
              sedan: 'Sedan',
              suv: 'SUV',
              limousine: 'Limousine',
              school_bus: 'School bus',
              van: 'Van',
            }[m]
          }
        )
        .replace(/\|/, ', ')
    },
    async addMinimum() {
      this.$store.dispatch('app/openSidebarDialog', {
        component: () => import('@/components/MarketMinimumSidebar.vue'),
        data: {
          mode: 'add',
          title: 'Add Minimum',
        },
      })
    },
    async viewMinimum(row) {
      if (this.isAffiliateView) {
        this.$store.dispatch('app/openSidebarDialog', {
          component: () => import('@/components/AffiliateMinimumSidebar.vue'),
          data: {
            id: row.marketMinimumGroupId,
            row: row,
            mode: 'view',
            title: 'Minimum Details',
            isAffiliateView: this.isAffiliateView,
          },
        })
      } else {
        this.$store.dispatch('app/openSidebarDialog', {
          component: () => import('@/components/MarketMinimumSidebar.vue'),
          data: {
            id: row.marketMinimumGroupId,
            row: row,
            mode: 'view',
            title: 'Edit Minimum',
          },
        })
      }
    },
    refreshTable() {
      this.tableInit.forceRefresh++
    },
  },
}
</script>

<style scoped>
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}

::v-deep .layout.status-filters.row.justify-space-between {
  justify-content: end;
}
</style>
