<template>
  <div>
    <BaseTableWrapper
      :table-init="tableInit"
      :enable-export="false"
      :initial-filters="initialFilters"
      style="padding-top: 0; padding-left: 0"
      remove-padding
    >
      <h1 class="page-header"></h1>
    </BaseTableWrapper>
  </div>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { DateTime } from 'luxon'
import ViewTableItem from '@/components/ViewTableItem.vue'
import markups from '@/services/markups'
import GenericViewActionWrapper from '@/utils/GenericViewActionWrapper'

const daysMap = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
}

export default {
  components: {
    BaseTableWrapper,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    isAffiliateView: {
      type: Boolean,
    },
  },
  data() {
    return {
      isAdmin: false,
      refreshFn: null,
      initialFilters: [],
      tableInit: {
        id: 'affiliate-adjustment-list',
        detailKeyId: 'markupId',
        tableId: 'affiliate-adjustment_tv_view',
        addNewEnabled: false,
        areStatusFiltersShowing: true,
        addNewHandler: this.addNew,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        forceRefresh: 0,
        columns: [],
        collection: 'Markups',
        action: (params) => markups.tableView(params),
        enableColumnConfig: false,
        enableExport: false,
        enableSavedViews: false,
        additionalQueries: null,
      },
    }
  },
  mounted() {
    let columns = [
      {
        _t_id: 'bd516cfa-01c1-4053-bc42-6e43e95dfae4',
        prop: '/',
        text: 'Actions',
        component: GenericViewActionWrapper(ViewTableItem, this.viewAndEdit),
        sort: false,
        filter: false,
        detail: false,
        type: 'actions',
      },
      {
        _t_id: '6cf8f4e6-1e2a-41bf-8c26-8be070b2904d',
        prop: 'name',
        text: 'Name',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'name',
        detail: false,
      },
      {
        _t_id: 'f12ba415-01be-4e34-a5dc-658191dae3db',
        prop: 'markupPercentage',
        text: 'Markup %',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'markupPercentage',
        detail: false,
      },
      {
        _t_id: '6d1ce8d5-30b5-446b-aab1-14cbce171cfd',
        prop: 'vehicleType/label',
        text: 'Vehicle Type',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'vehicleType',
        detail: false,
      },
      {
        _t_id: '8420930a-dc4e-40de-83cf-fdfaf459e5f9',
        prop: 'rateType/label',
        text: 'Rate Type',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'rateType',
        detail: false,
      },
      {
        _t_id: 'b5d5f858-5fa9-4e4e-97d8-6b98a4e794b2',
        prop: 'markupDays',
        text: 'Days',
        type: 'text',
        filterType: 'contains',
        computedText: (days) => {
          const sortedDays = [...days]
          return sortedDays
            .sort((a, b) => a.day - b.day)
            .map((d) => daysMap[d.day])
            .join(', ')
        },
        detail: false,
      },
      {
        _t_id: '8420930a-dc4e-40de-83cf-fdfaf459e5f9',
        prop: 'rateType/label',
        text: 'Rate Type',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'rateType',
        detail: false,
      },
      {
        _t_id: '22e36c12-9f6e-4f60-9034-94de5f483160',
        prop: 'tripStartDatetime',
        text: 'Start Date',
        computedText: (d) =>
          d ? DateTime.fromISO(d).toUTC().toISODate() : null,
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        sortProp: 'tripStartDatetime',
        detail: false,
      },
      {
        _t_id: '98fb4981-e1e0-468a-b012-8a971bdd01c1',
        prop: 'tripEndDatetime',
        text: 'End Date',
        computedText: (d) =>
          d ? DateTime.fromISO(d).toUTC().toISODate() : null,
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'eq',
        sortProp: 'tripEndDatetime',
        detail: false,
      },
      {
        _t_id: '0999c737-2660-4e86-8be4-1b2d49bf235c',
        prop: 'companyId',
        text: 'Company ID',
        sort: false,
        filter: true,
        type: 'number',
        filterType: 'eq',
        detail: false,
        defaultHidden: true,
      },
    ]
    this.tableInit.columns.push(...columns)

    if (this.isAffiliateView) {
      this.tableInit.additionalQueries = 'isAffiliateDetailsView=true'
      this.initialFilters = [
        {
          column: columns.find(
            (column) => column._t_id === '0999c737-2660-4e86-8be4-1b2d49bf235c'
          ),
          value: this.company.companyId.toString(),
          hideOnFilterBar: true,
        },
      ]
    }
  },
  methods: {
    addNew() {
      const component = () => import('@/components/AdjustmentForm.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          mode: 'add',
          company: this.company,
          markup: null,
          title: 'Add Adjustment',
        },
        component,
      })
    },
    viewAndEdit(data) {
      const component = () => import('@/components/AdjustmentForm.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          mode: 'view',
          company: this.company,
          markup: data,
          title: 'Adjustment Details',
        },
        component,
      })
    },
  },
}
</script>

<style scoped>
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}

::v-deep .layout.status-filters.row.justify-space-between {
  justify-content: end;
}
</style>
