import Vue from 'vue'

export default function (component, handler) {
  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          id: this.row.marketMinimumGroupId,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          handler: () => {
            handler(this.row)
          },
        },
      })
    },
  })
}
