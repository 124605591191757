import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { MarketMinimumGroup, MarketMinimumGroupResult } from '@/models/MarketMinimum'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Returns a TableViewResult containing an array of MarketMinimumGroup objects.
   *
   * @param params - An object containing the request parameters, including sorting, filtering, pagination, and page size.
   * @returns the market minimum data for the table view.
   */
  tableView(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<MarketMinimumGroup>>> {
    const {
      pageSize = 10,
      page = 1,
      sorts = null,
      filters = null,
      additionalQueries = null,
    } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    if (additionalQueries) {
      query += `&${additionalQueries}`
    }
    query = encodeURI(query)

    return httpService.get(`https://${host}/tables/marketMinimumGroups?${query}`)
  },
  /**
   * Returns a MarketMinimumGroupResult object for the specified market minimum group.
   *
   * @param marketMinimumGroupId - The ID of the market minimum group to retrieve
   * @returns a MarketMinimumGroupResult object for the specified market minimum group.
   */
  getGroup(minimumGroupId: number): Promise<AxiosResponse<MarketMinimumGroupResult>> {
    const host = baseUrl()
    const url = `https://${host}/marketMinimum/${minimumGroupId}`

    return httpService.get(url)
  },
  createGroup(params: { payload: MarketMinimumGroup}): Promise<AxiosResponse<MarketMinimumGroupResult>> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/marketMinimum`

    return httpService.post(url, payload)
  },
  createGroups(params: any): Promise<AxiosResponse> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/marketMinimum/batch`

    return httpService.post(url, payload)
  },
  updateGroup(params: { payload: MarketMinimumGroup}): Promise<AxiosResponse<MarketMinimumGroupResult>> {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/marketMinimum`

    return httpService.patch(url, payload)
  },
  deleteGroup(minimumGroupId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/marketMinimum/${minimumGroupId}`

    return httpService.delete(url)
  },
}
