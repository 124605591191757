<template>
  <div>
    <v-tabs v-model="selectedTab" class="mb-4">
      <template v-for="tab in tabs">
        <v-tab
          :id="`affiliate-bookings-tab-${tab.text.toLowerCase()}`"
          :key="tab.text"
          class="mx-2"
        >
          {{ tab.text }}
        </v-tab>
      </template>
    </v-tabs>
    <DataTable
      ref="dataTable"
      :initial-filters="initialFilters"
      v-bind.sync="tableProps"
      style="margin-left: 15px"
    >
      <template #multiSelectActions>
        <v-menu>
          <template #activator="{ on }">
            <v-btn class="btn-secondaryaction" v-on="on">
              Payment Actions
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile
              :id="`provider-payments-list-title-pay-provider`"
              @click="showDialog('payProvider')"
            >
              <v-list-tile-title>Pay Provider</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :id="`provider-payments-list-title-add-charge`"
              @click="showDialog('addCharge')"
            >
              <v-list-tile-title>Add Charge</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :id="`provider-payments-list-title-add-refund`"
              @click="showDialog('addRefund')"
            >
              <v-list-tile-title>Add Refund</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :id="`provider-payments-list-title-reduce-charge`"
              @click="showDialog('reduceCharge')"
            >
              <v-list-tile-title>Reduce Charge</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </template>
    </DataTable>
    <BookingsSidebar
      :active="active"
      :row-data="rowData"
      :close-handler="closeHandler"
      :mode="mode"
    />
    <PaymentSidebarWrapper
      :id="'affiliate-bookings-payments-sidebar'"
      v-model="dialogType"
      :rows="tableProps.selectedRows"
      @close-modal="closeDialog"
      @remove-item="removeItem"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import PaymentsListDetail from '@/components/PaymentsListDetail.vue'
import PaymentSidebarWrapper from '@/components/PaymentSidebarWrapper.vue'
import BookingsActions from '@/components/BookingsActions.vue'
import BookingsSidebar from '@/components/BookingsSidebar.vue'
import ReservationActionableColumn from '@/components/ReservationActionableColumn.vue'
import { mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { currencyFilter } from '@/utils/currency'
import { formatReservationPickupDestinationText } from '@/utils/string'
import { datePredefined, calculatedValues } from '@/utils/predefined'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import { v4 } from 'uuid'
import { deepClone } from '@/utils/deepClone'
import Vue from 'vue'

const paymentStatusMap = [
  { text: 'Unpaid', value: 'not_paid' },
  { text: 'Partially Paid', value: 'partially_paid' },
  { text: 'Fully Paid', value: 'fully_paid' },
]

const tabs = [
  {
    text: 'Opportunities',
    tabFilters: [
      {
        column: {
          _t_id: '9449ac8d-a3e8-4d65-8f74-7a37ce43121b',
          prop: 'referralStatus',
          filterType: 'eq',
          text: '',
          type: 'text',
        },
        value: 'offered',
        hideOnFilterBar: true,
      },
    ],
  },
  {
    text: 'Next 7 Days',
    tabFilters: [
      {
        column: {
          _t_id: '6866dfa7-da1c-4b85-b145-33967909227b',
          prop: 'pickupDate',
          filterType: 'gte',
          type: 'date',
          text: '',
        },
        value: DateTime.utc().toFormat('yyyy-MM-dd'),
        hideOnFilterBar: true,
      },
      {
        column: {
          _t_id: 'b678f181-1b95-44da-999f-876d263dd99d',
          prop: 'pickupDate',
          filterType: 'lte',
          sortProp: 'pickupDate',
          direction: 'desc',
          type: 'date',
          text: '',
        },
        value: DateTime.utc().plus({ days: 7 }).toFormat('yyyy-MM-dd'),
        hideOnFilterBar: true,
      },
    ],
  },
  {
    text: 'Upcoming',
    tabFilters: [
      {
        column: {
          _t_id: '1e980db8-3dc5-4207-b263-132b8d55c9fe',
          prop: 'pickupDate',
          filterType: 'gte',
          sortProp: 'pickupDate',
          sort: true,
          direction: 'asc',
          type: 'date',
          text: '',
        },
        value: DateTime.local().toFormat('yyyy-MM-dd'),
        hideOnFilterBar: true,
      },
      {
        column: {
          _t_id: 'fdaaae5b-b7cd-478d-bd90-ad8a7f31a077',
          prop: ['parentReservation/reservationStatus'],
          filterType: 'eq',
          text: '',
        },
        value: 'upcoming',
        hideOnFilterBar: true,
      },
    ],
  },
  {
    text: 'In Progress',
    tabFilters: [
      {
        column: {
          _t_id: '058aca4b-8784-4204-8471-d6373f043979',
          prop: ['parentReservation/reservationStatus'],
          filterType: 'eq',
          text: 'In Progress',
        },
        value: 'started',
        hideOnFilterBar: true,
      },
    ],
  },
  {
    text: 'Finished',
    tabFilters: [
      {
        column: {
          _t_id: '916d5383-1529-4b0b-b4af-01ffd3ad1737',
          prop: ['parentReservation/reservationStatus'],
          filterType: 'eq',
          text: 'Finished',
        },
        value: 'finished',
        hideOnFilterBar: true,
      },
    ],
  },
  { text: 'All', tabFilters: [] },
]

export default {
  components: {
    DataTable,
    BookingsSidebar,
    PaymentSidebarWrapper,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogType: '',
      isAdmin: false,
      active: false,
      mode: 'view',
      refreshFn: null,
      tabs,
      selectedTab: 5,
      tableProps: {
        total: 0,
        tableId: 'affiliate_bookings-list',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        detail: PaymentsListDetail,
        loading: true,
        enableSelectableRows: true,
        selectedRows: [],
        calculatedValues,
        id: 'affiliate-bookings-list',
        columns: [
          {
            _t_id: '696907b6-d7bc-47f3-8b53-16bfdf60d537',
            elementId: 'actions',
            text: 'Actions',
            component: this.createComponent(BookingsActions, this.viewSidebar),
            value: 'actions',
            type: 'actions',
          },
          {
            _t_id: 'c41eb7d4-cb58-43ba-ab1f-36371f828a1a',
            prop: 'managedId',
            text: 'ID',
            filter: true,
            type: 'number',
            filterType: 'contains',
            sortProp: 'managedId',
          },
          {
            _t_id: '0e19fabc-1d61-456e-aa72-c33180aad06c',
            prop: 'createdOn',
            text: 'Booked On',
            filter: true,
            type: 'date',
            filterType: 'contains',
            sortProp: 'createdOn',
            computedText: (item) =>
              DateTime.fromISO(item).toFormat('MM/dd/yyyy HH:mm'),
            defaultHidden: true,
          },
          {
            _t_id: '815f3634-0e12-487f-8e0a-2a7244f6ff8a',
            prop: ['firstPickupAddressName', 'firstDropoffAddressName'],
            text: 'Pickup > Destination',
            type: 'text',
            computedText: this.pickupDestinationComputedText,
            filterType: 'contains',
          },
          {
            _t_id: 'ddc3adcf-5a71-40f3-90c3-d4c1139b1c3d',
            prop: 'pickupDate',
            text: 'Pickup Date',
            filter: true,
            type: 'date',
            filterType: 'eq',
            sortProp: 'pickupDate',
            computedText: (item) =>
              DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'a85525ad-d286-4650-815f-56ea0fe498ca',
            prop: 'dropoffDate',
            text: 'Dropoff Date',
            filter: true,
            type: 'date',
            filterType: 'eq',
            sortProp: 'dropoffDate',
            computedText: (item) =>
              DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: '5cb2d5b2-1199-4ad4-8dd8-21b2b4c76a2b',
            prop: 'requiredVehicles',
            text: 'Vehicles',
            type: 'number',
            filterType: 'eq',
            sortProp: 'requiredVehicles',
          },
          {
            _t_id: '3de474a8-aac3-4835-96d1-8befcc1c4640',
            prop: 'requiredDrivers',
            text: 'Drivers',
            type: 'number',
            filterType: 'eq',
            sortProp: 'requiredDrivers',
          },
          {
            _t_id: 'e74eeec8-30f8-4f70-bb0f-8822ef8a7b47',
            prop: 'amount',
            text: 'Referral Total',
            type: 'number',
            filterType: 'eq',
            displayType: 'info-icon',
            computedText: this.referralTotalComputedText,
            component: ReservationActionableColumn,
          },
          {
            _t_id: '91472f72-967a-4200-8ab3-28f86df109f3',
            prop: 'balance',
            text: 'Balance',
            type: 'number',
            filterType: 'eq',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: '2e5d9e97-a085-44ea-9307-3df60599a01a',
            prop: 'referralPaymentStatus',
            text: 'Payment Status',
            computedText: (item) =>
              this.mapCategoryText(paymentStatusMap, item),
            filter: false,
            type: 'text',
            filterType: 'contains',
            detail: false,
          },
          {
            _t_id: '822e8b93-3660-49fc-bb01-7cbdcf06c73f',
            prop: 'amount',
            text: 'Price',
            type: 'number',
            filterType: 'eq',
            computedText: (item) => currencyFilter(item),
            defaultHidden: true,
          },
        ],
      },
    }
  },
  computed: {
    ...authComputed,
    initialFilters() {
      let initialFilters = [
        {
          column: {
            _t_id: '80e50e5c-6da5-4269-9a5b-7998439d6dc1',
            prop: 'allocatedFromReservationId',
            filterType: 'isnull',
            text: '',
            defaultHidden: true,
          },
          value: 'null',
          hideOnFilterBar: true,
        },
        {
          column: {
            _t_id: '36db9a80-26cc-4162-81d0-bf6ee0f75888',
            prop: 'reservationType',
            sort: false,
            filter: true,
            text: '',
            filterType: 'eq',
            detail: false,
            defaultHidden: true,
          },
          value: 1,
          hideOnFilterBar: true,
        },
      ]

      return initialFilters.concat(tabs[this.selectedTab]?.tabFilters || [])
    },
  },
  mounted() {
    EventBus.$on('global-table-view-refresh', () => {
      this.refresh()
    })
    this.$refs.dataTable.setInitialFilters()
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    viewSidebar(data) {
      this.active = true
      this.rowData = data
      this.mode = 'view'
    },
    closeHandler(refreshQuery) {
      if (refreshQuery) {
        this.tableInit.forceRefresh = 1 + Math.random()
      }
      this.active = false
    },
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
    pickupDestinationComputedText(row, column, action) {
      // const { firstPickupAddressName, firstDropoffAddressName } = column
      // return firstPickupAddressName + ' > ' + firstDropoffAddressName
      return formatReservationPickupDestinationText(column)
    },
    referralTotalComputedText(row, column, action) {
      const { referralPaymentStatus, referralBalance, amount } = row
      const numericReferralBalance =
        typeof referralBalance === 'number' ? referralBalance : 0
      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: 'Unpaid',
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${
          paymentStatusHeaderMap[referralPaymentStatus || 'not_paid']
        }</h6>
          <p>Total Owed: ${currencyFilter(amount)}${
          referralPaymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(numericReferralBalance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[referralPaymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[referralPaymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, amount),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    closeDialog() {
      this.actionsDialog = false
      this.dialogType = ''
      this.refresh()
    },
    showDialog(type) {
      if (this.tableProps.selectedRows.length === 0) {
        return this.showAlert({
          type: 'error',
          message: 'You must select at least one reservation.',
        })
      }

      // check if selectedItems are from the same provider
      const differentReferrer = this.tableProps.selectedRows.find((item) => {
        return (
          item.item.referredBy !==
          this.tableProps.selectedRows[0].item.referredBy
        )
      })
      if (differentReferrer) {
        this.showAlert({
          message: 'Please select a single provider to proceed.',
          type: 'error',
        })
      } else {
        this.dialogType = type
      }
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }

      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getTable() {
      const requestId = v4()
      this.latestRequestId = requestId
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const params = {
        affiliateCompanyId: this.company.companyId,
        sorts,
        filters,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
      }
      this.tableProps.loading = true

      const reservationData = await this.$store.dispatch(
        'reservations/affiliatesReservationsTV',
        params
      )

      if (this.latestRequestId !== requestId) {
        return
      }

      const reservations = reservationData.data

      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = reservations.resultList
      this.tableProps.total = reservations.count
      this.tableProps.loading = false
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      if (
        this.currentPage != pagination.page ||
        this.itemsPerPage != pagination.rowsPerPage
      ) {
        this.currentPage = pagination.page
        this.itemsPerPage = pagination.rowsPerPage
        this.refresh()
      }
    },
    createComponent(component, handler) {
      return Vue.component('WrappedActions', {
        props: {
          row: {
            type: Object,
            default: () => ({}),
          },
          handleAction: {
            type: Function,
            default: () => null,
          },
          rowProps: {
            type: Object,
            default: () => ({}),
          },
        },
        render(createElement) {
          return createElement(component, {
            props: {
              row: this.row,
              handleAction: this.handleAction,
              rowProps: this.rowProps,
              handler: () => {
                handler(this.row)
              },
            },
          })
        },
      })
    },
  },
}
</script>

<style scoped>
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}

::v-deep .layout.status-filters.row.justify-space-between {
  justify-content: end;
}
</style>
