<template>
  <div class="solid-side-border">
    <div
      style="
        height: 36px;
        position: relative;
        padding-top: 3px;
        padding-left: 10px;
        display: flex;
        justify-content: space-between;
      "
    >
      <p style="margin-bottom: 0">Vehicle Type</p>
      <v-btn
        class="calendar-buttons"
        x-small
        icon
        color="primary"
        @click="$emit('shift-calendar-display-left')"
      >
        <CRIcon color="white" view-box="0 0 24 24" width="20px" height="20px">
          keyboard_arrow_left
        </CRIcon>
      </v-btn>
    </div>
    <template v-for="(row, index) in vehicleRows">
      <div
        :key="`${index}-${row.distanceFromTop}-vehicle-block`"
        class="row"
        :class="{ 'dark-background': row.companyName }"
        :style="`height: ${row.rowHeight}px`"
      >
        <div v-if="!row.header && row.vehicle" class="row__non-header-image">
          <img
            v-if="row.vehicle.imagePath"
            :src="getImageSrc(row.vehicle.imagePath)"
            width="32"
            height="32"
            class="icon-border"
          />
          <CRIcon
            v-else
            :key="`${index}-${row.distanceFromTop}-vehicle-icon-directions-bus`"
            view-box="0 0 24 24"
            :width="32"
            :height="32"
            color="#9c9c9c"
            class="icon-border"
          >
            directions_bus
          </CRIcon>
        </div>
        <div v-if="row.header" class="row--header">
          <v-menu
            v-if="row.companyName"
            open-on-hover
            bottom
            offset-y
            :close-on-content-click="false"
          >
            <template #activator="{ on }">
              <p :class="{ 'not-bold': row.notBold }" v-on="on">
                {{ row.companyName }}
                {{
                  row.vehicleTypeQuantity ? `(${row.vehicleTypeQuantity})` : ''
                }}
              </p>
            </template>
            <v-list class="company-info-list">
              <v-list-item
                v-for="(item, infoIndex) in row.companyInfo"
                :key="`${index}-${infoIndex}-${row.distanceFromTop}-company-info`"
                class="company-info-list-item"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
                <br />
              </v-list-item>
            </v-list>
          </v-menu>
          <p v-if="row.vehicleType" :class="{ 'not-bold': row.notBold }">
            {{ row.vehicleType }}
            {{ row.vehicleTypeQuantity ? `(${row.vehicleTypeQuantity})` : '' }}
          </p>
          <div style="height: 24px; weight: 24px">
            <CRIcon
              v-if="row.hidden && (row.vehicleType || row.companyName)"
              :key="`${index}-${row.distanceFromTop}-icon-arrow-drop-down`"
              view-box="0 0 24 24"
              :width="24"
              :height="24"
              color="blue"
              class="arrow-icon"
              @click.native="handleDropdownClick(row)"
            >
              keyboard_arrow_down
            </CRIcon>
            <CRIcon
              v-else-if="!row.hidden && (row.vehicleType || row.companyName)"
              :key="`${index}-${row.distanceFromTop}-icon-arrow-up`"
              view-box="0 0 24 24"
              :width="24"
              :height="24"
              color="blue"
              class="arrow-icon"
              @click.native="handleDropdownClick(row)"
            >
              keyboard_arrow_up
            </CRIcon>
          </div>
        </div>
        <div v-else class="row--non-header">
          <p v-if="row.vehicleType">
            {{ row.vehicleType }}
            {{ row.vehicleTypeQuantity ? `(${row.vehicleTypeQuantity})` : '' }}
          </p>
          <p v-if="row.vehicle">
            {{ row.vehicle.vehicleName }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { baseUrl } from '@/utils/env'
import CRIcon from '@/cr/components/CRIcon.vue'

export default {
  components: { CRIcon },
  props: {
    vehicleRows: {
      type: Array,
      required: true,
    },
    isVehicleTypeFirst: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getImageSrc(imagePath) {
      return `https://${baseUrl(null)}${imagePath}`
    },
    handleDropdownClick(row) {
      if (!this.isVehicleTypeFirst) {
        this.$emit(
          'dropdown-click',
          row.vehicleType
            ? row.companyId + ':' + row.vehicleType
            : row.companyName
        )
      } else {
        this.$emit(
          'dropdown-click',
          row.companyId
            ? row.vehicleTypeId + ':' + row.companyId
            : row.vehicleTypeId
        )
      }
    },
  },
}
</script>
<style lang="scss" scoped>
$gray-header: $input-background-gray;
::v-deep .vehicles-list-select {
  height: 30px;
  max-height: 30px;

  .v-input__slot {
    min-height: 30px !important;
    max-height: 30px;
  }
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;

  &--header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;

    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bolder;
    }
  }

  &--non-header {
    padding-left: 10px;

    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__non-header-image {
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }
}
.solid-side-border {
  width: 180px;
  max-width: 180px;
}
.icon-border {
  border-radius: 50%;
  border-color: #e6e8ea;
  border-style: solid;
  border-width: 2px;
  margin-right: 1px;
}
.dark-background {
  background: $gray-header;
}
.not-bold {
  font-weight: normal !important;
}
.arrow-icon {
  padding-right: 4px;
  :hover {
    cursor: pointer;
  }
}
.calendar-buttons {
  width: 20px;
  height: 28px;
  border-radius: 2px;
  background: $primary;
  margin-top: 0;
  margin-left: 4px;
  margin-right: 4px;
}
.company-info-list {
  background: rgba($gray, 0.9);
  color: $white;
}
.company-info-list-item {
  padding: 10px;
}
</style>
