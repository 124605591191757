<template>
  <CRSidebarDialog
    v-model="active"
    persistent
    @input="closeHandlerWrapper(false)"
  >
    <template #title>
      {{ rowData.garageName }}
    </template>
    <div class="garage-sidebar">
      <div class="garage-sidebar--vehicle-info">
        <div class="garage-sidebar--garage-address">
          <b>Garage Address:</b>
          {{ rowData.address ? rowData.address.street1 : '' }}
          {{ rowData.address ? rowData.address.street2 : '' }}
        </div>
        <div v-if="!rowData.noOfVehicles" style="margin-top: 10px">
          This garage has no vehicles.
        </div>
        <div
          v-for="vehicle of garageVehicles"
          :key="`vehicle-${vehicle.vehicleId}`"
          class="garage-sidebar--vehicle-row"
        >
          <img
            class="bus-icon"
            width="35px"
            height="35px"
            src="@/assets/images/icon-directions-bus-gray.svg"
          />
          <span style="align-items: center">
            <b class="margin-r-1">{{ vehicle.vehicleName }}</b>
            <ReservationVehicleTrackingIcon
              :vehicle-id="vehicle.vehicleId"
              :vehicle-type-name="vehicle.vehicleTypeName"
            />
          </span>
          <b style="padding: 0 10px">|</b>
          <div>{{ vehicle.vehicleTypeName }}</div>
        </div>
      </div>
    </div>
  </CRSidebarDialog>
</template>

<script>
import ReservationVehicleTrackingIcon from './ReservationVehicleTrackingIcon.vue'
import garages from '@/services/garages'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'

export default {
  components: {
    ReservationVehicleTrackingIcon,
  },
  props: {
    mode: {
      type: String,
      default: 'view',
    },
    active: {
      type: Boolean,
      default: false,
    },
    rowData: {
      type: Object,
      default: () => ({}),
    },
    closeHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      garage: {},
      isNewGarageEntitiesEnabled: false,
    }
  },
  computed: {
    garageVehicles() {
      if (this.isNewGarageEntitiesEnabled) {
        return this.garage.vehicles
      } else {
        return this.garage.vehicleDTOs
      }
    }
  },
  watch: {
    async active(newValue) {
      if (newValue) {
        if (this.isNewGarageEntitiesEnabled) {
          const { data } = await garages.getGarageV2(this.rowData.garageId)
          this.garage = data
        } else {
          const { data } = await garages.getGarage(this.rowData.garageId)
          this.garage = data
        }
      }
    },
  },
  async mounted() {
    this.isNewGarageEntitiesEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.NewGarageEntities
    )
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    closeHandlerWrapper(refreshStateAfterAction) {
      if (this.internalMode !== 'add') {
        this.internalMode = 'view'
      }

      if (this.internalMode === 'add') {
        this.resetForm()
      }
      this.closeHandler(refreshStateAfterAction)
    },
  },
}
</script>

<style lang="scss" scoped>
.bus-icon {
  padding: 1px;
  margin-top: 4px;
  margin-right: 10px;
  border: 1px solid $gray-medium-light;
  border-radius: 50%;
}

.garage-sidebar {
  display: flex;
  flex-direction: column;
  width: 500px;

  &--vehicle-info {
    margin: 0 !important;
    padding: 15px 40px;
  }

  &--garage-address {
    margin-bottom: 10px;
  }

  &--vehicle-row {
    display: flex;
    align-items: center;
    padding: 15px 0;
  }
}
</style>
