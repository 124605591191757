
import { DateTime } from 'luxon'

export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
  },

  computed: {
    expiration() {
      const now = DateTime.local()
      const expiration = DateTime.fromISO(this.row.biddingEndDate)
      const isExpired = expiration < now
      const isOneDayFromExpiration = expiration < now.plus({ days: 1 })
      return {
        text: isExpired
          ? 'Expired'
          : expiration.diff(now).toFormat("dd'd' hh'h' mm'm' ss's'"),
        color: isOneDayFromExpiration ? 'error' : 'black',
      }
    },
  },
}
