<template>
  <div
    :class="{
      'pt-3': !displayEdit,
    }"
  >
    <PartnershipSettings
      :id="`${id}-partnership-settings`"
      ref="partnershipsettings"
      :mode="mode"
      :display-edit="displayEdit"
      :company="company"
      @toggle-edit="toggleEdit"
      @cancel-edit="cancelEdit"
      @submit="submit"
    />
  </div>
</template>

<script>
import PartnershipSettings from '@/components/PartnershipSettings.vue'
import companies from '@/services/companies'

export default {
  components: {
    PartnershipSettings,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mode: 'view',
    }
  },
  computed: {
    displayEdit() {
      return this.$store.getters['auth/hasPermission'](
        'canEditAffiliatesDetails'
      )
    },
  },
  methods: {
    toggleEdit() {
      this.mode = 'edit'
    },
    cancelEdit() {
      this.mode = 'view'
    },
    async submit() {
      this.loading = true
      let reply = {}

      if (!this.$refs.partnershipsettings.isFormValid()) {
        this.loading = false
        this.displayErr(
          'Some fields are missing that may be required or are invalid.',
          true
        )
        return
      }

      const submission = JSON.parse(JSON.stringify(this.company))

      const partnerPayload = await this.$refs.partnershipsettings.processPayload()

      Object.entries(partnerPayload).map((item) => {
        const key = item[0]
        const value = item[1]
        submission[key] = value
      })

      try {
        const params = {
          id: this.company.companyId,
          payload: submission,
        }
        reply = await companies.modifyCompany(params)

        await this.$refs.partnershipsettings.processPdf()
      } catch (e) {
        this.loading = false
        this.disabled = !this.disabled

        this.displayErr(e, true)
        return
      }

      this.loading = false
      this.mode = 'view'

      const companyResponse = await companies.getCompany(
        reply.data.company.companyId
      )
      this.row = companyResponse?.data.company

      await this.$refs.partnershipsettings.updatePage()

      this.$store.dispatch('app/showAlert', {
        message: 'Partnership settings saved successfully.',
      })
    },
  },
}
</script>
