<template>
  <BaseTableWrapper
    :special-text-formatting="specialTextFormatting"
    :table-init="tableInit"
    :initial-filters="initialFilters"
    :show-table-header="false"
    style="margin-left: 15px"
  >
    <h1 class="page-header"></h1>
  </BaseTableWrapper>
</template>

<script>
import drivers from '@/services/drivers'
import DriverViewDetail from '@/components/DriverViewDetail.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import BaseActionsRequiresWrapperVue from '@/components/BaseActionsRequiresWrapper.vue'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { phoneFormatFilter } from '@/utils/phone'
import { authComputed } from '@/state/helpers'
import GenericActionableColumn from '@/components/GenericActionableColumn.vue'

const actions = ['view-affiliate-driver-sidebar']

const columns = [
  {
    _t_id: '5fe46062',
    prop: '/',
    text: 'Actions',
    component: actionsWrapper(BaseActionsRequiresWrapperVue, 'driver', actions),
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '5fe46620',
    prop: 'name',
    text: 'Driver Name',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    defaultSort: true,
  },
  {
    _t_id: '95ffdd3e',
    prop: 'email',
    text: 'Email',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
  },
  {
    _t_id: '5fe468b4',
    prop: 'phoneNumber',
    text: 'Phone Number',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
  },
  {
    _t_id: '859a8082',
    prop: 'licenseNumber',
    text: 'License Number',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
  },
  {
    _t_id: '2c62ad79',
    prop: 'licenseState',
    text: 'State',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
  },
  {
    _t_id: 'a34e6c93',
    prop: ['licenseExpirationMonth', 'licenseExpirationYear'],
    displayType: 'license-expiration-date',
    component: GenericActionableColumn,
    text: 'License Expiration',
    sort: false,
    filter: false,
    type: 'text',
  },
  {
    _t_id: '65d77ce3',
    prop: 'companyId',
    text: 'Company ID',
    sort: false,
    filter: true,
    type: 'number',
    filterType: 'eq',
    detail: false,
    defaultHidden: true,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    isAffiliateView: {
      type: Boolean,
    },
  },
  data() {
    return {
      isAdmin: false,
      specialTextFormatting: {
        phoneNumber: phoneFormatFilter,
      },
      tableInit: {
        detail: DriverViewDetail,
        detailKeyId: 'userId',
        tableId: 'drivers_tv_view',
        initialFilters: [],
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'Drivers',
        rowClass: this.rowClass.bind(this),
        additionalQueries: null,
        action: (params) => drivers.getDrivers(params),
      },
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    if (this.isAffiliateView) {
      this.tableInit.additionalQueries = 'isAffiliateDetailsView=true'
      this.initialFilters = [
        {
          column: columns.find((column) => column._t_id === '65d77ce3'),
          value: this.company.companyId.toString(),
          hideOnFilterBar: true,
        },
      ]
    }
  },
  methods: {
    rowClass(rowProps) {
      const { item } = rowProps
      if (item.driverActivated !== null && !item.driverActivated) {
        return 'error-background'
      }
    },
  },
}
</script>
