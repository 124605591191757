import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Markup, MarkupResult } from '@/models/dto/Markup'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Retrieves a paginated list of MarketMinimumGroup objects.
   *
   * @param params - An object containing the request parameters, including sorting, filtering, pagination, and page size.
   * @returns A TableViewResult of MarketMinimumGroup objects.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Markup>>> {
    const {
      pageSize = 10,
      page = 1,
      sorts = null,
      filters = null,
      additionalQueries = null,
    } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    if (additionalQueries) {
      query += `&${additionalQueries}`
    }
    query = encodeURI(query)

    return httpService.get(`https://${host}/tables/markups?${query}`) // Hey, just in case there's a git blame here, this is not a market rate but the actual markets.
  },
  /**
   * Edits an existing MarketMinimumGroup object.
   *
   * @param marketMinimumGroup - The updated MarketMinimumGroup object.
   * @returns a MarketMinimumGroupResult object.
   */
  update(params: { id: number, payload: Markup}): Promise<AxiosResponse<MarkupResult>> {
    const host = baseUrl()
    const url = `https://${host}/markup/${params.id}`

    return httpService.patch(url, params.payload)
  },
  /**
   * Adds a new MarketMinimumGroup object.
   *
   * @param marketMinimumGroup - The MarketMinimumGroup to add.
   * @returns a MarketMinimumGroupResult object.
   */
  create(payload: { payload: Markup}): Promise<AxiosResponse<MarkupResult>> {
    const host = baseUrl()
    const url = `https://${host}/markup`

    return httpService.post(url, payload.payload)
  },
  createByBatch(payload: { payload: any }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/markup/batch`

    return httpService.post(url, payload.payload)
  },
  /**
   * Deletes an existing MarketMinimumGroup object by its ID.
   *
   * @param marketMinimumGroupId - The ID of the MarketMinimumGroup to delete.
   */
  delete(id: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/markup/${id}`

    return httpService.delete(url)
  },
}
