<template>
  <v-layout>
    <v-flex shrink xs3>
      <AffiliateSummaryVehicleList
        :company="company"
        :vehicles="filteredVehicles"
        :loading="loading"
        @toggle-filter="inProgressFilter = !inProgressFilter"
      />
    </v-flex>
    <v-flex grow xs9>
      <CountdownTimer
        v-show="false"
        :time-limit="standardRefreshInterval"
        :size="timerSize"
        :style="$vuetify.breakpoint.xs ? 'margin-right: -14px' : ''"
        @countdown-finished="refresh"
      />
      <AffiliatesSummaryTrackingMap
        id="summaryMap"
        ref="summaryMap"
        :filtered-vehicles="vehicleLocations"
        :refresh-interval="standardRefreshInterval"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import AffiliateSummaryVehicleList from '@/components/AffiliatesSummaryVehicleList.vue'
import AffiliatesSummaryTrackingMap from '@/components/AffiliatesSummaryTrackingMap.vue'
import CountdownTimer from '@/components/CountdownTimer.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    AffiliateSummaryVehicleList,
    AffiliatesSummaryTrackingMap,
    CountdownTimer,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      vehicles: [],
      trackingVehicles: [],
      inProgressFilter: false,
      standardRefreshInterval: 30,
      hoverItem: 0,
    }
  },
  computed: {
    timerSize() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return 40
      }
      return 30
    },
    filteredVehicles() {
      if (this.inProgressFilter) {
        return this.vehicles.filter((v) => this.isVehicleInProgress(v))
      }
      return this.vehicles
    },
    vehicleLocations() {
      const vehicleLocations = []
      for (const [index, vehicle] of this.filteredVehicles.entries()) {
        const foundDevice = this.trackingVehicles.find(
          (tv) => tv.vehicleId === vehicle.vehicleId
        )
        if (foundDevice) {
          const newVehicleLocation = {
            vehicleId: vehicle.vehicleId,
            vehicleName: vehicle.vehicleName,
            lat: foundDevice.lat,
            lng: foundDevice.lng,
            markerLabel: index + 1,
            isInProgress: this.isVehicleInProgress(vehicle),
          }
          vehicleLocations.push(newVehicleLocation)
        }
      }
      return vehicleLocations
    },
  },
  async mounted() {
    this.loading = true
    await this.getVehicles()
    await this.getTrackingDevices()
    this.loading = false
  },
  methods: {
    ...mapActions({
      getTrackingDevicesByVehicleIdsV2:
        'trackingDevices/getTrackingDevicesByVehicleIdsV2',
    }),
    refresh() {
      this.loading = true
      this.computeRefreshInterval()
      this.getVehicles()
      this.loading = false
    },
    computeRefreshInterval() {
      const resStatuses = this.filteredVehicles.map(
        (vehicle) => vehicle?.lastReservationStatus
      )
      const finishedCount = resStatuses.filter(
        (status) => status === 'finished'
      ).length
      this.standardRefreshInterval =
        resStatuses.length === finishedCount ? 120 : 30
    },
    async getVehicles() {
      if (!this.company?.companyId) {
        return
      }
      const res = await this.$store.dispatch(
        'vehicles/getAffiliateSummaryVehicles',
        this.company.companyId
      )
      this.vehicles = res.data.vehicles.sort((a, b) => {
        if (
          a.lastReservationStatus === 'started' &&
          b.lastReservationStatus === 'started'
        ) {
          return a.lastSyncDate > b.lastSyncDate ? 0 : -1
        }
        if (a.lastReservationStatus === 'started') {
          return -1
        }
        if (b.lastReservationStatus === 'started') {
          return 0
        }
        if (a.isUnavailable) {
          return 0
        }
        if (b.isUnavailable) {
          return -1
        }
        return a.vehicleId > b.vehicleId ? 0 : -1
      })
    },
    async getTrackingDevices() {
      if (!this.vehicles?.length) {
        return
      }
      const params = {
        requestBody: { vehicleIds: this.vehicles.map((v) => v.vehicleId) },
        prioritizeEld: true,
      }
      const res = await this.getTrackingDevicesByVehicleIdsV2(params)
      this.trackingVehicles = res.data.devices
    },
    isVehicleInProgress(vehicle) {
      return vehicle?.lastReservationStatus === 'started'
    },
  },
}
</script>
