<template>
  <span>
    <ReservationVehicleTrackingIcon
      class="margin-r-1"
      last-sync-only
      :vehicle-id="row.vehicleId"
      :vehicle-type-name="row.vehicleTypeName"
    />
    {{ row.vehicleName }}
  </span>
</template>

<script>
import ReservationVehicleTrackingIcon from './ReservationVehicleTrackingIcon.vue'

export default {
  components: {
    ReservationVehicleTrackingIcon,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
}
</script>
