<template>
  <v-layout>
    <CRSelect
      :id="`${id}-affiliates-market-filter-select-nearest-market-id`"
      ref="radiusMarket"
      v-model="marketSelection"
      placeholder="Market"
      :items="markets"
      clear-icon="replay"
      :clearable="true"
      flat
      hide-details
      item-text="marketName"
      item-value="marketId"
      solo
      class="market-selector"
      style="max-width: 170px"
      @input="filterAffiliates"
      @click:clear="resetFilter"
    />
    <CRSelect
      :id="`availability-affiliates-filter-select-partner-type-id`"
      v-model="selectedPartnerType"
      :items="partnerTypes"
      placeholder="Partner Type"
      clear-icon="replay"
      :clearable="true"
      flat
      hide-details
      item-text="label"
      item-value="id"
      :disabled="!isFiltered && !selectedPartnerType"
      solo
      :all="selectedPartnerType === -1"
      class="partner-selector"
      style="max-width: 160px"
      @input="setPartnerTypeFilter"
      @click.clear="selectedPartnerType = -1"
    />
    <CRSelect
      v-if="isFiltered"
      :id="`availability-affiliates-filter-select-market-id`"
      v-model="selectedAffiliate"
      :items="affiliateList"
      placeholder="Affiliate"
      clear-icon="replay"
      :clearable="true"
      flat
      hide-details
      item-text="name"
      item-value="companyId"
      solo
      class="affiliate-selector"
      style="max-width: 240px"
      @change="setFilter"
      @click:clear="clearAffiliate"
    />

    <AffiliateAutoComplete
      v-else
      hide-details
      @change="selectAutocompleteItem"
      @autocomplete-clear="clearSelected"
    />
  </v-layout>
</template>

<script>
import AffiliateAutoComplete from '@/components/AffiliateAutoComplete.vue'
import affiliates from '@/services/affiliates'
import markets from '@/services/markets'
import { authComputed } from '@/state/helpers'
import { filter } from '@/utils/filter'
import { PartnerTypeId } from '@/utils/enum'

export default {
  components: {
    AffiliateAutoComplete,
  },
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
    prepopulate: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      markets: [],
      filteredMarkets: [],
      activeMarketLocationFilter: undefined,
      isFiltered: false,
      selectedAffiliate: null,
      autofilledAffiliates: [],
      partnerTypes: [],
      selectedPartnerType: null,
    }
  },
  computed: {
    ...authComputed,
    affiliateList() {
      if (
        this.selectedPartnerType &&
        this.selectedPartnerType != -1 &&
        this.selectedPartnerType != -2
      ) {
        return this.filteredMarkets.filter(
          (fm) => fm.partnerTypeId === this.selectedPartnerType
        )
      }
      if (this.selectedPartnerType == -2) {
        const partnerTypeIds = [
          PartnerTypeId.Platinum,
          PartnerTypeId.Gold,
          PartnerTypeId.Silver,
          PartnerTypeId.Bronze,
        ]
        return this.filteredMarkets.filter((fm) =>
          partnerTypeIds.includes(fm.partnerTypeId)
        )
      }
      return this.filteredMarkets
    },
  },
  async mounted() {
    const partnerTypeResults = await this.$store
      .dispatch('types/getPartnerTypes', {
        pageSize: -1,
      })
      .catch((e) => {
        console.error(e)
      })
    this.partnerTypes = partnerTypeResults.data.resultList.filter(
      (pt) => !(pt.id === 1 || pt.id === 2)
    )
    this.partnerTypes.unshift({ id: -1, key: 'all', label: 'All' })
    this.partnerTypes.splice(5, 0, { id: -2, key: 'partner', label: 'Partner' })

    const marketsData = await markets.tableView({
      pageSize: -1,
    })

    this.markets = marketsData?.data?.resultList || []
    this.markets.sort((a, b) => (a.marketName > b.marketName ? 0 : -1))
    const activeMarketLocationFilter = this.findFilter(
      this.marketLocationFilter
    )
    if (activeMarketLocationFilter) {
      this.activeMarketLocationFilter = activeMarketLocationFilter
      this.$nextTick(() => {
        const rebuiltMarketSelections = activeMarketLocationFilter.value || ''
        this.marketSelections = rebuiltMarketSelections
          .split(' ')
          .map((marketSelection) => parseInt(marketSelection, 10))
        this.addFilter()
      })
    }
    if (this.company?.companyId) {
      await this.filterAffiliates(this.company.nearestMarket.marketId, true)
      this.marketSelection = this.company.nearestMarket.marketId
      this.selectedPartnerType = this.company.partnerTypeId
      this.selectedAffiliate = this.company.companyId
      this.setFilter([this.company.companyId])
    } else if (this.prepopulate) {
      await this.filterAffiliates(this.markets[0].marketId)
    }
  },
  methods: {
    resetFilter() {
      this.isFiltered = false
      this.selectedPartnerType = null
      this.clearAffiliate()
    },
    clearSelected() {
      this.marketSelection = null
      this.selectedPartnerType = null
      this.clearAffiliate()
    },
    clearAffiliate() {
      this.selectedAffiliate = null
      if (this.isFiltered && this.affiliateList?.length) {
        this.setFilter(this.affiliateList.map((m) => m.companyId))
      } else {
        this.$emit('company-selected', null)
      }
    },
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    async setPartnerTypeFilter(e) {
      if (!e) {
        this.selectedPartnerType = -1
      }
      this.selectedAffiliate = null
      if (this.isFiltered && this.affiliateList?.length) {
        if (this.affiliateList?.length === 1) {
          this.selectedAffiliate = this.affiliateList[0]?.companyId
        }
        await this.setFilter(this.affiliateList.map((al) => al.companyId))
      } else {
        this.$emit('company-selected', null)
      }
    },
    async filterAffiliates(nearestMarket, firstLoad = false) {
      this.selectedAffiliate = null
      this.marketSelection = nearestMarket
      const filterObject = {
        column: {
          _t_id: '066262b5-4d4d-44de-bb11-2e6bf8bb7e19',
          prop: 'nearestMarketId',
          filterType: 'eq',
        },
      }
      filterObject.value = nearestMarket
      if (typeof nearestMarket === 'number') {
        if (nearestMarket.length <= 0) {
          return
        }
      } else {
        return
      }
      const nearestMarketSearch = filter()
      const parentFilter = nearestMarketSearch.createParent('and')
      nearestMarketSearch.add(parentFilter, filterObject)

      const params = {
        filters: nearestMarketSearch.asQueryParams(),
        pageSize: -1,
      }

      const matchedAffiliates = await affiliates
        .getAffiliatesMap(params)
        .catch((error) => {
          return false
        })

      this.isFiltered = true
      this.filteredMarkets = matchedAffiliates.data.resultList
      if (!firstLoad) {
        this.selectedPartnerType = -1
        if (this.affiliateList?.length) {
          if (this.affiliateList?.length == 1) {
            this.selectedAffiliate = this.affiliateList[0].companyId
          }
          await this.setFilter(this.affiliateList.map((al) => al.companyId))
        } else {
          this.$emit('company-selected', null)
        }
      }
    },
    selectAutocompleteItem(e) {
      this.selectedAffiliate = e
      this.setFilter([e.companyId])
      this.autofilledAffiliates = []
    },
    setFilter(e) {
      if (e === undefined) {
        return
      }
      if (!Array.isArray(e)) {
        e = [e]
      }
      this.$emit('company-selected', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.market-selector,
.partner-selector,
.affiliate-selector {
  margin-left: 10px;
  min-height: 40px;
}

.market-selector::v-deep,
.partner-selector::v-deep,
.affiliate-selector::v-deep {
  .v-select__selections {
    max-width: 80%;
  }
  .v-select__selection {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    margin-right: 0;
  }
}
</style>
