<template>
  <AffiliateDriversList :company="company" is-affiliate-view />
</template>

<script>
import AffiliateDriversList from '@/views/AffiliateDriversList.vue'

export default {
  components: {
    AffiliateDriversList,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
