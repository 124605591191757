<template>
  <v-card-title primary-title>
    <div class="cr-base-form-display-info">
      <div class="headline">
        {{ row.firstName }}
        {{ row.lastName }}
      </div>
      <div>
        Phone:
        <a :href="`tel:${row.phoneNumber}`">{{ row.phoneNumber }}</a>
      </div>
      <div>
        Email:
        <a :href="`mailto:${row.email}`">{{ row.email }}</a>
      </div>
    </div>
  </v-card-title>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
      required: true,
      name: {
        type: String,
        default: '',
      },
      vehicleType: {
        type: String,
        default: '',
      },
      garageName: {
        type: String,
        default: '',
      },
      vehicleId: {
        type: String,
        default: '',
      },
    },
  },
  mounted() {},
}
</script>
