var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"justify-space-between align-center",staticStyle:{"display":"flex","margin-right":"10px"}},[_c('div',{staticStyle:{"padding-bottom":"10px"}},[_c('v-checkbox',{staticStyle:{"margin-top":"0","padding-top":"0","padding-left":"10px"},attrs:{"label":"Show only In Progress Vehicles","hide-details":""},on:{"change":function($event){return _vm.$emit('toggle-filter')}},model:{value:(_vm.inProgressFilter),callback:function ($$v) {_vm.inProgressFilter=$$v},expression:"inProgressFilter"}})],1)]),_c('v-flex',{staticStyle:{"height":"600px","overflow-y":"auto"}},[(_vm.loading)?_vm._l(([0, 1, 3]),function(i){return _c('v-flex',{key:i,staticClass:"vehicle-card"},[_c('SkeletonBox',{staticStyle:{"height":"200px"}})],1)}):_vm._l((_vm.vehicles),function(vehicle,vehicleIndex){return _c('v-flex',{key:vehicle.vehicleId,staticClass:"vehicle-card",class:{
          'unavailable-vehicle': vehicle.isUnavailable,
          'in-progress-vehicle': _vm.isVehicleInProgress(vehicle),
        }},[_c('v-layout',{attrs:{"row":""}},[_c('div',{staticStyle:{"padding-top":"15px","padding-left":"15px"}},[_c('p',{class:{
                'unavailable-index': vehicle.isUnavailable,
                'in-progress-index': _vm.isVehicleInProgress(vehicle),
              },staticStyle:{"font-size":"16px"}},[_c('b',[_vm._v(_vm._s(vehicleIndex + 1))])])]),_c('div',{staticStyle:{"padding":"15px 15px"}},[_c('div',[_c('p',{staticClass:"vehicle-title"},[_vm._v(" "+_vm._s(vehicle.vehicleName)+" ")])]),(_vm.isVehicleInProgress(vehicle))?_c('div',{staticStyle:{"padding-bottom":"10px","display":"flex"}},[_c('CRTag',{attrs:{"label":_vm.lastReservationIsTracking ? 'Tracking' : 'Not Tracking',"color":_vm.lastReservationIsTracking ? 'green' : 'red'}}),_c('div',[(
                    vehicle.onTimeStatusId === 2 ||
                    vehicle.onTimeStatusId === 3
                  )?_c('CRTag',{attrs:{"label":"On Time","color":"green"}}):(vehicle.onTimeStatusId === 1)?_c('CRTag',{attrs:{"label":"Late","color":"red"}}):_vm._e()],1)],1):_vm._e(),_c('div',{staticStyle:{"padding-bottom":"10px"}},[_c('p',[_vm._v(" "+_vm._s(vehicle.vehicleType.label)+" ")]),_c('p',[_vm._v(" Device: "),_c('b',[_vm._v(_vm._s(_vm.getTrackerInfo(vehicle)))])])]),_c('div',{staticStyle:{"padding-bottom":"10px"}},[_c('p',[_vm._v(" Last Reservation ID: "),(vehicle.lastReservation)?_c('router-link',{staticClass:"reservation-link",attrs:{"id":`reservation-link-${vehicle.lastReservation}`,"target":"_blank","to":{
                    name: 'reservation-detail',
                    params: { id: vehicle.lastParentManagedId },
                  }}},[_vm._v(" #"+_vm._s(vehicle.lastManagedId)+" ")]):_c('span',[_c('b',[_vm._v("N/A")])])],1),(_vm.isVehicleInProgress(vehicle))?_c('p',[_vm._v(" Contract: "),_c('b',[_vm._v(_vm._s(_vm.getContractInfo(vehicle)))])]):_vm._e()]),_c('p',[_vm._v(" Last Report Time: "),_c('b',[_vm._v(" "+_vm._s(vehicle.lastSyncDate ? _vm.isoToString(vehicle.lastSyncDate) : 'N/A')+" ")])]),_c('p',[_vm._v(" Last Location: "),_c('b',[_vm._v(" "+_vm._s(vehicle.lat && vehicle.lng ? `${vehicle.lat}, ${vehicle.lng}` : 'N/A')+" ")])])])])],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }