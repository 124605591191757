<template>
  <div class="base-timer" :style="computedStyles">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="50" cy="50" r="45" fill="white"></circle>
      </g>
      <g class="base-timer__circle">
        <circle
          class="base-timer__path-elapsed"
          cx="50"
          cy="50"
          r="45"
        ></circle>
        <path
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>
      </g>
    </svg>
    <span
      class="base-timer__label"
      :style="timerSize"
      :class="remainingPathColor"
    >
      {{ timeLeft }}
    </span>
  </div>
</template>

<script>
const FULL_DASH_ARRAY = 283

export default {
  props: {
    timeLimit: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
    hidden: {
      type: Boolean,
    },
  },
  data() {
    return {
      timePassed: 0,
      timerInterval: null,
      colorCodes: {
        start: {
          color: 'blue',
        },
        end: {
          color: 'purple',
          threshold: 0,
        },
      },
    }
  },

  computed: {
    circleDasharray() {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`
    },
    timeLeft() {
      return this.timeLimit - this.timePassed
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit
      return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction)
    },
    remainingPathColor() {
      const { end, start } = this.colorCodes

      if (this.timeLeft <= end.threshold) {
        return end.color
      } else {
        return start.color
      }
    },
    computedStyles() {
      let styles = this.timerSize
      if (this.hidden) {
        styles = `${styles} display: none;`
      }
      return styles
    },
    timerSize() {
      return `width: ${this.size}px; height: ${this.size}px;`
    },
  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp()
        this.startTimer()
      }
    },
  },

  mounted() {
    const colorChangeThreshold = this.timeLimit / 3.0
    this.colorCodes.end.threshold = colorChangeThreshold
    this.startTimer()
  },

  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval)
      this.timePassed = 0
      this.$emit('countdown-finished')
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
    },
  },
}
</script>

<style scoped lang="scss">
.base-timer {
  position: relative;
  display: block;
  margin-left: 10px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 7px;
    stroke: $border-gray;
  }

  &__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.blue {
      color: $primary;
    }

    &.purple {
      color: $purple;
    }
  }

  &__label {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background: none !important;
    transition: 1s linear color;

    &.blue {
      color: $primary;
    }

    &.purple {
      color: $purple;
    }
  }
}
</style>
