import { DateTime } from 'luxon'
import { deepClone } from './deepClone'

export const sortPricingBlocksByMarket = (
  markupBlocks,
  eventBlocks,
  minimumGroupBlocks,
  startOfWeek
) => {
  const map = {}
  for (const mb of markupBlocks) {
    if (!map[mb.marketId]) {
      const newMapItem = {
        blocks: [],
      }
      map[mb.marketId] = newMapItem
    }
    if (mb.markupDays.length) {
      let sameMarkupBlocks = []
      for (let dayIncrement = 0; dayIncrement < 7; dayIncrement++) {
        const day = DateTime.fromISO(startOfWeek)
          .plus({ days: dayIncrement })
          .startOf('day')
        if (
          mb.markupDays.map((md) => md?.day).find((md) => md === day.weekday)
        ) {
          const markupPricingBlock = deepClone(mb)
          markupPricingBlock.startDate = day.toISODate()
          markupPricingBlock.endDate = day.toISODate()
          markupPricingBlock.isMarkup = true
          markupPricingBlock.isEvent = false
          sameMarkupBlocks.push(markupPricingBlock)
        }
      }
      map[mb.marketId].blocks.push(sameMarkupBlocks)
    } else {
      const markupPricingBlock = deepClone(mb)
      markupPricingBlock.isMarkup = true
      markupPricingBlock.isEvent = false
      markupPricingBlock.isMinimumGroup = false
      map[mb.marketId].blocks.push([markupPricingBlock])
    }
  }
  for (const eb of eventBlocks) {
    if (!map[eb.marketId]) {
      const newMapItem = {
        blocks: [],
      }
      map[eb.marketId] = newMapItem
    }
    const eventPricingBlock = deepClone(eb)
    eventPricingBlock.isMarkup = false
    eventPricingBlock.isEvent = true
    eventPricingBlock.isMinimumGroup = false
    map[eb.marketId].blocks.push(eventPricingBlock)
  }
  for (const mgb of minimumGroupBlocks) {
    if (!map[mgb.marketId]) {
      const newMapItem = {
        blocks: [],
      }
      map[mgb.marketId] = newMapItem
    }
    if (mgb.days?.length) {
      let sameMinimumGroupBlocks = []
      for (let dayIncrement = 0; dayIncrement < 7; dayIncrement++) {
        const day = DateTime.fromISO(startOfWeek)
          .plus({ days: dayIncrement })
          .startOf('day')
        const matchingDay = mgb.days.find((mgd) => mgd?.day === day.weekday)
        if (matchingDay) {
          const minimumGroupPricingBlock = deepClone(mgb)
          minimumGroupPricingBlock.days = mgb.days
            .map((day) => day.day)
            .join(', ')
          minimumGroupPricingBlock.startDate = day.toISODate()
          minimumGroupPricingBlock.endDate = day.toISODate()
          minimumGroupPricingBlock.isMarkup = false
          minimumGroupPricingBlock.isEvent = false
          minimumGroupPricingBlock.isMinimumGroup = true
          sameMinimumGroupBlocks.push(minimumGroupPricingBlock)
        }
      }
      map[mgb.marketId].blocks.push(sameMinimumGroupBlocks)
    } else {
      const minimumGroupPricingBlock = deepClone(mgb)
      minimumGroupPricingBlock.isMarkup = false
      minimumGroupPricingBlock.isEvent = false
      minimumGroupPricingBlock.isMinimumGroup = true
      map[mgb.marketId].blocks.push([minimumGroupPricingBlock])
    }
  }
  return map
}

export const sortEventPricingBlocksByMarket = (eventBlocks) => {
  const map = {}
  for (const eb of eventBlocks) {
    if (!map[eb.marketId]) {
      const newMapItem = {
        blocks: [],
      }
      map[eb.marketId] = newMapItem
    }
    const eventPricingBlock = deepClone(eb)
    map[eb.marketId].blocks.push(eventPricingBlock)
  }
  return map
}

export const convertMarkupToPricingBlock = (markup) => {
  const pricingMarkup = {
    markupId: markup.markupId,
    name: markup.name,
    market: markup.market,
    marketId: markup?.market?.marketId,
    marketName: markup?.market?.marketName,
    markupPercentage: markup.markupPercentage,
    markupType: markup.markupType,
    rateType: markup.rateType,
    vehicleType: markup.vehicleType,
    markupDays: markup.markupDays,
    startDate: DateTime.fromISO(markup.tripStartDate.split('T')[0])
      .startOf('day')
      .toISODate(),
    endDate: DateTime.fromISO(markup.tripEndDate.split('T')[0])
      .startOf('day')
      .toISODate(),
  }
  return pricingMarkup
}

export const convertEventToPricingBlock = (event) => {
  const pricingEvent = {
    eventId: event.eventId,
    title: event.title,
    eventAddress: event.eventAddress,
    severityTypeKey: event.severityTypeKey,
    severityTypeLabel: event.severityTypeLabel,
    market: event.market,
    marketId: event?.market?.marketId,
    marketName: event?.market?.marketName,
    vehicleType: event.vehicleType,
    comments: event.comments,
    isSoldOut: event.isSoldOut,
    isLive: event.isLive,
    startDate: DateTime.fromISO(event.startDate.split('T')[0])
      .startOf('day')
      .toISODate(),
    endDate: DateTime.fromISO(event.endDate.split('T')[0])
      .startOf('day')
      .toISODate(),
  }
  return pricingEvent
}

export const convertMinimumGroupToPricingBlock = (minimumGroup) => {
  const dayString = minimumGroup.days?.replace(' ', '')
  let days = null

  if (dayString) {
    days = dayString.split(',')?.map((day) => ({
      day: parseInt(day),
    }))
  }

  const pricingMinimum = {
    marketMinimumGroupId: minimumGroup.marketMinimumGroupId,
    marketMinimumGroupName: minimumGroup.marketMinimumGroupName,
    market: {
      marketId: minimumGroup.marketId,
      marketName: minimumGroup.marketName,
    },
    marketId: minimumGroup.marketId,
    marketName: minimumGroup.marketName,
    days: days,
    startDate: DateTime.fromISO(minimumGroup.startDate.split('T')[0])
      .startOf('day')
      .toISODate(),
    endDate: DateTime.fromISO(minimumGroup.endDate.split('T')[0])
      .startOf('day')
      .toISODate(),
  }
  return pricingMinimum
}

export const PRICING_ROW_HEIGHT = 65
