<template>
  <DataTable v-bind.sync="tableProps" />
</template>

<script>
import actionsWrapper from '@/utils/BaseActionsWrapper'
import BaseActionsRequiresWrapperVue from '@/components/BaseActionsRequiresWrapper.vue'
import AffiliateVehicleListName from '@/components/AffiliateVehicleListName.vue'
import DataTable from '@/components/DataTable.vue'
import tracking from '@/services/tracking'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import { v4 } from 'uuid'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'

const actions = ['view-affiliate-vehicle-sidebar']

export default {
  components: {
    DataTable,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    isAffiliateView: {
      type: Boolean,
    },
  },
  data() {
    return {
      isAdmin: false,
      tableProps: {
        tableId: 'vehicles_tv_view',
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        currentPage: 1,
        perPage: 10,
        sort: this.sort,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        changePage: this.changePage,
        isDetailed: true,
        loading: true,
        columns: [
          {
            _t_id: '8f0e39d81',
            prop: '/',
            text: 'Actions',
            component: null,
            sort: false,
            filter: false,
            detail: false,
            type: 'actions',
          },
          {
            _t_id: '8f0e3c941',
            prop: 'vehicleName',
            text: 'Vehicle Name',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            sortProp: 'name',
            defaultSort: true,
            component: AffiliateVehicleListName,
          },
          {
            _t_id: '8f0e425c1',
            prop: 'vehicleTypeName',
            text: 'Vehicle Type',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            sortProp: 'vehicleTypeName',
          },
          {
            _t_id: '8f0e425c12',
            prop: 'deviceInfo',
            text: 'ELD Type',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            sortProp: 'deviceInfo',
            computedText: this.trackerTypeComputedText,
          },
          {
            _t_id: '8f0e425c13',
            prop: 'deviceInfo',
            text: 'Device ID',
            sort: true,
            filter: true,
            type: 'number',
            filterType: 'eq',
            sortProp: 'deviceInfo',
            computedText: this.trackerNumberComputedText,
          },
          {
            _t_id: '8f0e43ce14',
            prop: 'vinNumber',
            text: 'VIN',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            sortProp: 'vinNumber',
          },
          {
            _t_id: '8f0e43ce23',
            prop: 'garageName',
            text: 'Garage',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            sortProp: 'garageName',
          },
          {
            _t_id: '8f0e43ce15',
            prop: 'passengerCapacity',
            text: 'Capacity',
            sort: true,
            filter: true,
            type: 'number',
            filterType: 'eq',
            sortProp: 'passengerCapacity',
          },
          {
            _t_id: '8f0e43ce16',
            prop: 'licensePlate',
            text: 'License Plate',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            sortProp: 'licensePlate',
          },
        ],
        list: [],
      },
      deviceInfo: [],
      isVinOverrideEnabled: false,
    }
  },
  computed: {
    ...authComputed,
    computedActions() {
      if (this.isVinOverrideEnabled) {
        return [...actions, 'edit-affiliate-vehicle-sidebar']
      }

      return actions
    },
  },
  async mounted() {
    this.isVinOverrideEnabled = await this.isFeatureEnabled(SplitFeatureFlag.VehicleVinOverride)

    const actionsWrapperComponent = actionsWrapper(
      BaseActionsRequiresWrapperVue,
      'vehicle',
      this.computedActions
    )

    this.tableProps.columns[0].component = actionsWrapperComponent

    EventBus.$on('global-table-view-refresh', () => {
      this.refresh()
    })
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    changePage(pagination) {
      if (
        this.currentPage != pagination.page ||
        this.itemsPerPage != pagination.rowsPerPage
      ) {
        this.currentPage = pagination.page
        this.itemsPerPage = pagination.rowsPerPage
        this.refresh()
      }
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getTable() {
      const requestId = v4()
      this.latestRequestId = requestId
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const params = {
        sorts,
        filters,
        additionalQueries: this.isAffiliateView
          ? `referredCompanyId=${this.company.companyId}`
          : '',
        pageSize: this.itemsPerPage,
        page: this.currentPage,
      }
      this.tableProps.loading = true

      const vehiclesData = await this.$store.dispatch(
        'vehicles/getVehicles',
        params
      )

      if (this.latestRequestId !== requestId) {
        return
      }

      const vehicles = vehiclesData.data

      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = vehicles.resultList || []
      this.tableProps.total = vehicles.count || 0

      const payload = this.tableProps.list.map((v) => {
        return {
          vinNumber: v.vinNumber,
          companyId: v.companyId,
        }
      })
      const res = await tracking.getDeviceInfo(payload)
      const deviceInfo = res?.data?.data
      this.deviceInfo = deviceInfo

      this.tableProps.loading = false
    },
    trackerTypeComputedText(item, row) {
      if (this.tableProps.loading) {
        return ''
      }
      const device = this.deviceInfo?.filter(
        (d) => d.trackingContextId.toUpperCase() === row.vinNumber.toUpperCase()
      )?.[0]
      return device?.eldType
        ? `${device?.eldType.charAt(0)}${device?.eldType
            .slice(1)
            ?.toLowerCase()}`
        : 'N/A'
    },
    trackerNumberComputedText(item, row) {
      if (this.tableProps.loading) {
        return ''
      }
      return (
        this.deviceInfo?.filter(
          (d) =>
            d.trackingContextId.toUpperCase() === row.vinNumber.toUpperCase()
        )?.[0]?.trackingDeviceId || 'N/A'
      )
    },
  },
}
</script>
