<template>
  <CRCalendarView
    :style="cssVars"
    :display-period-uom="displayPeriod"
    :display-period-count="displayPeriodCount"
    :show-date="displayDateAsJSDate"
    :starting-day-of-week="startingDayOfWeek"
    :date-classes="dateClasses"
    :items="items"
  >
    <!-- Scoped slot for the Weekday/Date Calendar Headers -->
    <template #dayHeader="{ index, label }">
      <div
        class="cv-week-day-header"
        :class="{
          'cv-week-day-header--bold': isHeaderBold(index.substring(3)),
          today: isToday(index.substring(3)),
        }"
      >
        <p class="margin-a-0" style="margin: 0">{{ label }}</p>
        <p class="margin-a-0" style="margin: 0">
          {{ getDayHeaderDate(index.substring(3), label) }}
        </p>
      </div>
    </template>
    <template #item="{ value }">
      <PricingCalendarItem
        v-if="isPricingItem"
        :key="`pricing-item-${value.originalItem.top}-${value.originalItem.startDate}`"
        :item="value.originalItem"
        :classes="value.classes"
        :top="value.originalItem.top"
        :is-markup="value.originalItem.isMarkup"
        :is-event="value.originalItem.isEvent"
        :is-minimum-group="value.originalItem.isMinimumGroup"
      />
      <AvailabilityCalendarItem
        v-else-if="isAvailabilityItem"
        :item="value.originalItem"
        :classes="value.classes"
        :top="value.originalItem.top"
        :is-block="value.originalItem.isVehicleAvailabilityBlock"
        :is-type-block="value.originalItem.isVehicleTypeAvailabilityBlock"
        :is-general-block="value.originalItem.isGeneralBlock"
        :is-vehicle-type-general-block="
          value.originalItem.isVehicleTypeGeneralBlock
        "
        :is-company-filler-block="value.originalItem.isCompanyFillerBlock"
        :is-type-filler-block="value.originalItem.isTypeFillerBlock"
        :is-free-filler-block="value.originalItem.isFreeFillerBlock"
      />
    </template>
  </CRCalendarView>
</template>

<script>
import { CalendarView } from 'vue-simple-calendar'
import PricingCalendarItem from './PricingCalendarItem.vue'
import AvailabilityCalendarItem from './AvailabilityCalendarItem.vue'
import { AVAILABILITY_ROW_HEIGHT } from '@/utils/availability'
import { PRICING_ROW_HEIGHT } from '@/utils/pricing'
import { DateTime } from 'luxon'
export default {
  components: {
    CalendarView,
    PricingCalendarItem,
    AvailabilityCalendarItem,
  },
  props: {
    displayDate: {
      type: String,
      default: DateTime.local().toISODate(),
    },
    items: {
      type: Array,
      default: () => [],
    },
    minHeight: {
      type: Number,
      default: 600,
    },
    itemType: {
      type: String,
      default: '',
    },
    displayPeriod: {
      type: String,
      default: 'week',
    },
    displayPeriodCount: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    displayDateAsJSDate() {
      return DateTime.fromISO(this.displayDate).toJSDate()
    },
    startingDayOfWeek() {
      return DateTime.fromISO(this.displayDate).weekday
    },
    cssVars() {
      let currentRowHeight
      if (this.isPricingItem) {
        currentRowHeight = PRICING_ROW_HEIGHT
      } else if (this.isAvailabilityItem) {
        currentRowHeight = AVAILABILITY_ROW_HEIGHT
      }
      const minHeight = Math.max(this.minHeight, currentRowHeight)
      let cssVars = {
        '--min-calendar-height': `${minHeight}px`,
      }
      return cssVars
    },
    // Set the background of the current displayed calendar day to
    // gray
    dateClasses() {
      const showDateISO = this.displayDate
      return {
        [showDateISO]: 'bolded',
      }
    },
    isPricingItem() {
      return this.itemType === 'pricing'
    },
    isAvailabilityItem() {
      return this.itemType === 'availability'
    },
  },
  methods: {
    isToday(index) {
      const headerDate = this.getDateOfHeaderDate(index).toISODate()
      return headerDate === DateTime.local().toISODate()
    },
    isHeaderBold(index) {
      const headerDate = this.getDateOfHeaderDate(index)
      return headerDate.c.day === DateTime.fromISO(this.displayDate).c.day
    },
    getDateOfHeaderDate(index) {
      index = Number(index)
      if (index >= this.startingDayOfWeek) {
        return DateTime.fromISO(this.displayDate).plus({
          days: index - this.startingDayOfWeek,
        })
      } else {
        return DateTime.fromISO(this.displayDate).plus({
          days: Number(7 - this.startingDayOfWeek + index),
        })
      }
    },
    getDayHeaderDate(index) {
      return this.getDateOfHeaderDate(index).c.day
    },
  },
}
</script>
<style lang="scss" scoped>
$gray-border: $input-border-gray;
$gray-header: $input-background-gray;

.cv-week-day-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px 8px;
  flex-basis: calc(100% / 7);
  border-right: 1px solid $gray-border;
  border-bottom: 1px solid $gray-header;
  border-top: 1px solid transparent;
  p {
    margin: 0;
    font-size: 14px;
  }

  &--bold {
    flex-basis: calc((100% / 7) + 1px);
    font-weight: bolder;
  }
}

.today {
  background: $gray-header;
}

::v-deep .cv-day-number {
  display: none;
}

.cv-day.bolded {
  background: $gray-header;
}

.cv-item {
  background: transparent;
}

::v-deep {
  .cv-day {
    border-color: rgba($gray-mid-light, 0.8);
  }

  .cv-weeks {
    border-width: 0 0 0 1px;
    min-height: var(--min-calendar-height);
  }
}
</style>
