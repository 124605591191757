<template>
  <div>
    <v-tabs v-model="selectedTab" class="mb-4">
      <template v-for="tab in tabs">
        <v-tab
          :id="`affiliate-adjustment-minimum-tab-${tab._t_id}-${tab.text}-control-${tabIndex}`"
          :key="`affiliate-adjustment-minimum-tab-${tab._t_id}-${tab.text}-control-${tabIndex}`"
          class="mx-2"
        >
          {{ tab.text }}
        </v-tab>
      </template>
    </v-tabs>
    <AdjustmentsList
      v-show="viewAdjustments"
      :company="company"
      is-affiliate-view
      style="margin-left: 15px"
    />
    <MinimumList
      v-show="viewMinimums"
      :company="company"
      is-affiliate-view
      style="margin-left: 15px"
    />
  </div>
</template>

<script>
import AdjustmentsList from '@/views/AdjustmentList.vue'
import MinimumList from '@/views/MinimumList.vue'

const ADJUSTMENTS_TAB = 0
const MINIMUMS_TAB = 1

export default {
  components: {
    AdjustmentsList,
    MinimumList,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        {
          _t_id: '24ccb17c-8d6d-4ab5-ac4f-ca8b6ecbc5e3',
          text: 'Adjustments',
          action: () => {
            this.viewAdjustments = true
            this.viewMinimums = false
          },
        },
        {
          _t_id: '9a57c3db-1078-4394-b429-b388d0d8be2c',
          text: 'Minimums',
          action: () => {
            this.viewAdjustments = false
            this.viewMinimums = true
          },
        },
      ],
    }
  },
  computed: {
    viewAdjustments() {
      return this.selectedTab === ADJUSTMENTS_TAB
    },
    viewMinimums() {
      return this.selectedTab === MINIMUMS_TAB
    },
  },
  methods: {
    isTabActive(tab) {
      if (tab._t_id === '24ccb17c-8d6d-4ab5-ac4f-ca8b6ecbc5e3') {
        return this.viewAdjustments
      }
      if (tab._t_id === '9a57c3db-1078-4394-b429-b388d0d8be2c') {
        return this.viewMinimums
      }
      return false
    },
  },
}
</script>
<style lang="scss" scoped>
.affiliate-adjustment-minimum-tab {
  line-height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  color: $gray-medium-light;
  border-width: 0 0 2px 0;
  border-style: solid;
  cursor: pointer;
}
.selected-tab {
  color: $blue;
  border-color: $blue;
}
.unselected-tab {
  border-style: none;
}
.unselected-tab:hover {
  border-style: solid !important;
}
</style>
