<template>
  <div v-if="!isVehicleLinkDataEnabled">
    <AffiliateVehiclesList :company="company" is-affiliate-view />
  </div>
  <div v-else>
    <div class="d-flex align-center mb-4 mt-2">
      <v-tabs v-model="selectedTab">
        <template v-for="component in affiliateVehiclesComponents">
          <v-tab
            :id="`affiliate-vehicles-tab-${component.text.toLowerCase()}`"
            :key="component.text"
            class="mx-2"
          >
            {{ component.text }}
          </v-tab>
        </template>
      </v-tabs>
      <v-spacer />
      <a
        v-if="shouldShowNewVehicleLink"
        style="display: block; text-align: right"
        @click="newVehicleNav"
      >
        + Add New Vehicle
      </a>
    </div>
    <keep-alive>
      <component
        :is="affiliateVehiclesComponents[selectedTab].value"
        :id="`${id}-affiliate-vehicles-component-selector`"
        :company="company"
        :devices="devices"
        is-affiliate-view
      />
    </keep-alive>
  </div>
</template>

<script>
import AffiliateVehiclesList from '@/views/AffiliateVehiclesList.vue'
import AffiliateDevicesList from '@/views/AffiliateDevicesList.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AffiliateVehiclesList,
    AffiliateDevicesList,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      devices: [],
      selectedTab: 0,
      affiliateVehiclesComponents: [
        { text: 'Vehicles', value: AffiliateVehiclesList },
        { text: 'Devices', value: AffiliateDevicesList },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isVehicleLinkDataEnabled: 'featureToggles/isVehicleLinkDataEnabled',
    }),
    shouldShowNewVehicleLink() {
      if (this.affiliateVehiclesComponents[this.selectedTab].text === 'Vehicles') {
        return true
      }
      return false
    },
  },
  async mounted() {
    const devices = await this.$store.dispatch(
      'trackingDevices/getTrackingDevicesByCompanyId',
      this.company.companyId
    )
    this.devices = devices.data.data
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    async newVehicleNav() {
      const component = () => import('../views/VehiclesForm.vue')

      this.$store.dispatch('app/openSidebarDialog', {
        component,
        data: {
          companyId: `${this.company.companyId}`,
          mode: 'add',
          title: 'Add Vehicle',
        },
      })
    },
  },
}
</script>
