<template>
  <!-- Inline styling to override default calendar settings -->
  <div
    class="ci"
    :class="[cvClasses, mainDivColor]"
    style="cursor: pointer"
    :style="`top: ${top}px; height: ${height - 1}px`"
    @click="() => viewCalendarItem(item)"
  >
    <div class="ci--info" :style="`height: ${PRICING_ROW_HEIGHT}px;`">
      <div class="ci--info--header">
        <p v-if="isMarkup">
          {{ item.name }}
        </p>
        <p v-else-if="isEvent">
          {{ item.title }}
        </p>
        <p v-else-if="isMinimumGroup">
          {{ item.marketMinimumGroupName }}
        </p>
      </div>
      <div class="ci--info--secondary">
        <p v-if="isMarkup">{{ markupTypeDescription }}</p>
      </div>
      <div class="ci--info--secondary">
        <p v-if="isMarkup">
          {{ rateTypeDescription }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { PRICING_ROW_HEIGHT } from '@/utils/pricing'
import { EventBus } from '@/utils/event-bus'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    top: {
      type: Number,
      default: 0,
    },
    classes: {
      type: Array,
      default: () => [],
    },
    isMarkup: {
      type: Boolean,
      default: false,
    },
    isEvent: {
      type: Boolean,
      default: false,
    },
    isMinimumGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PRICING_ROW_HEIGHT,
    }
  },
  computed: {
    cvClasses() {
      return 'cv-item ' + this.classes.join(' ')
    },
    mainDivColor() {
      if (this.isMarkup) {
        return 'markup-color'
      } else if (this.isEvent) {
        if (!this.item.isLive) {
          return 'inactive-event-color'
        } else {
          return 'event-color'
        }
      } else if (this.isMinimumGroup) {
        return 'minimum-group-color'
      } else {
        return ''
      }
    },
    height() {
      if (this.item?.height) {
        return this.item.height
      }
      return PRICING_ROW_HEIGHT
    },
    markupTypeDescription() {
      if (this.item?.markupType?.key === 'vehicle_type') {
        return `${this.item.markupType.label}: ${this.item.vehicleType.label}`
      } else {
        return `${this.item.markupType.label}`
      }
    },
    rateTypeDescription() {
      if (this.item?.rateType) {
        return `${this.item.rateType.label}: ${this.item.markupPercentage}%`
      } else {
        return `${this.item.markupPercentage}%`
      }
    },
  },
  methods: {
    viewCalendarItem(item) {
      EventBus.$emit('pricing-calendar-view-item', item)
    },
  },
}
</script>
<style lang="scss" scoped>
.markup-color {
  background: $awaiting-dark !important;
  //border: solid 1px $dispatch-unassigned-border-hover !important;
}
.event-color {
  background: $assigned-dark !important;
  //border: solid 1px $dispatch-border-hover !important;
}
.inactive-event-color {
  background: rgba($assigned-dark, 0.5) !important;
}

.minimum-group-color {
  background: $pending-dark !important;
}

.ci {
  border: none;
  padding: 0;
  margin-top: 1px;
  &--info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 1px;
    padding: 5px;
    color: $black;

    p {
      margin: 0;
    }

    &--header {
      margin: 0 0 0 0;
      overflow: hidden;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &--secondary {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
    }
  }
  &--info:hover {
    cursor: pointer;
  }
}
</style>
