<template>
  <v-sheet
    class="availability-display"
    style="margin: 0"
    :style="isAffiliateView ? 'padding-top: 0; padding-left: 10px;' : ''"
  >
    <div>
      <v-layout
        row
        wrap
        class="align-end"
        style="margin-top: 5px"
        :style="!viewingMultipleCompanies ? 'padding-bottom: 32px' : ''"
      >
        <div v-if="!isAffiliateView">
          <h1 style="margin-bottom: 20px; padding-right: 10px; font-size: 23px">
            Availability
          </h1>
          <AffiliatesSearchBar
            :company="company"
            prepopulate
            @company-selected="companiesSelected"
          />
          <v-spacer />
        </div>
        <v-layout
          v-if="currentCompanyIds.length"
          class="justify-end align-end"
          style="display: flex"
        >
          <v-btn v-show="loading" class="loading-button">Loading...</v-btn>
          <v-btn class="today-button" @click="setDateToday">Today</v-btn>
          <CRInput
            v-model="displayDate"
            type="date"
            hide-details
            style="margin-left: 10px; min-height: 40px; max-width: 130px"
          />
          <v-menu>
            <template #activator="actionMenu">
              <v-btn
                v-if="!addInCalendarView"
                id="unavailability-actions-menu"
                color="primary"
                class="availability-button"
                v-on="actionMenu.on"
              >
                <p style="margin-bottom: 0; padding-left: 8px">Actions</p>
                <v-icon>arrow_drop_down</v-icon>
              </v-btn>
              <v-btn
                v-else
                id="unavailability-add-in-calendar-save"
                color="primary"
                class="availability-button"
                @click="addInCalendarSubmit"
              >
                Save
              </v-btn>
            </template>

            <v-list>
              <v-list-tile
                v-for="item in menuItems"
                :id="`unavailability-actions-menu-item-${item._t_id}`"
                :key="`unavailability-actions-menu-item-${item._t_id}`"
                :style="{ cursor: 'pointer' }"
                @click="item.action"
              >
                <v-list-tile-title>{{ item.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-layout>
      </v-layout>
      <v-layout
        v-show="viewingMultipleCompanies"
        row
        justify-space-between
        style="padding-bottom: 20px; padding-top: 12px"
      >
        <div>
          <template v-for="(tab, tabIndex) in tabs">
            <span
              :id="`multi-company-availability-tab-${tab._t_id}-${tab.text}-control-${tabIndex}`"
              :key="`multi-company-availability-tab-${tab._t_id}-${tab.text}-control-${tabIndex}`"
              class="availability-tab"
              :class="{
                'selected-tab': isTabActive(tab),
                'unselected-tab': !isTabActive(tab),
              }"
              @click="tab.action"
            >
              {{ tab.text }}
            </span>
          </template>
        </div>
        <div style="display: flex">
          <p class="expand" @click="handleExpandClick">
            {{ isExpanded ? 'Collapse All' : 'Expand All' }}
          </p>
        </div>
      </v-layout>
      <v-layout v-if="currentCompanyIds.length" row class="calendar-display">
        <CalendarGridLines :rows="vehicleKeyRows" />
        <AvailabilityKeyList
          :vehicle-rows="vehicleKeyRows"
          :is-vehicle-type-first="viewByVehicleType"
          @dropdown-click="adjustVehicleKeyRows"
          @shift-calendar-display-left="shiftCalendarDisplayDate(-2)"
        />
        <CalendarModal
          display-period="week"
          display-period-count="2"
          :display-date="displayDate"
          :items="calendarItemsSortedByVehicles"
          :min-height="minHeight"
          item-type="availability"
        />
        <v-btn
          class="calendar-buttons"
          x-small
          icon
          color="primary"
          @click="shiftCalendarDisplayDate(2)"
        >
          <CRIcon color="white" view-box="0 0 24 24" width="20px" height="20px">
            keyboard_arrow_right
          </CRIcon>
        </v-btn>
      </v-layout>
    </div>
  </v-sheet>
</template>

<script>
import CalendarModal from '@/components/CalendarModal.vue'
import AvailabilityKeyList from '@/components/AvailabilityKeyList.vue'
import CalendarGridLines from '@/components/CalendarGridLines.vue'
import AffiliatesSearchBar from '@/components/AffiliatesSearchBar.vue'
import availability from '@/services/availability'
import companies from '@/services/companies'
import { mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import IntervalTree from '@flatten-js/interval-tree'
import {
  convertReservationToAvailabilityBlock,
  sortAvailabilityBlocksByVehicle,
  AVAILABILITY_ROW_HEIGHT,
  sortReservationBlocksByVehicle,
  sortTypeAvailabilityBlocksByVehicle,
  getVehicleTypeGeneralAvailabilityBlocks,
  getGeneralAvailabilityBlocks,
  createCompanyFillerBlock,
} from '@/utils/availability'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'

const NUMBER_OF_DAYS = 14
const COACHRAIL_COMPANY_ID = 2
const UNAVAILABLE_AVAILABILITY_REASON_ID = 1

export default {
  components: {
    CalendarModal,
    AvailabilityKeyList,
    CalendarGridLines,
    AffiliatesSearchBar,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    isAffiliateView: {
      type: Boolean,
    },
  },
  data() {
    return {
      displayDate: DateTime.local().toISODate(),
      partnerTypes: [],
      vehicles: [],
      vehicleTypes: [],
      currentCompanyIds: [],
      companyVehicleTypes: [],
      vehicleTypeCompanies: [],
      companies: [],
      loadedDateIntervals: new IntervalTree(),
      loadedReservations: [],
      loadedVehicleTypeBlocks: [],
      loadedVehicleBlocks: [],
      isBlockDialogOpen: false,
      blockMode: 'add',
      viewByPartnerType: true,
      viewByVehicleType: false,
      addInCalendarView: false,
      loading: false,
      addInCalendarAdd: [],
      addInCalendarDelete: [],
      tabs: [
        {
          _t_id: '442ee488-7e35-499e-b780-cfcf34d983b4',
          text: 'By Partner',
          action: () => {
            this.viewByPartnerType = true
            this.viewByVehicleType = false
          },
        },
        {
          _t_id: 'a08ef1cf-8bee-44f9-9b8f-f41b32a3d1e2',
          text: 'By Vehicle Type',
          action: () => {
            this.viewByPartnerType = false
            this.viewByVehicleType = true
          },
        },
      ],
      menuItems: [
        {
          _t_id: 'f2030410-043a-4efa-a5e1-5e89e4501fee',
          text: 'Add in Calendar',
          action: () => {
            this.addInCalendarView = true
          },
        },
        {
          _t_id: 'f5a00d78-1ae7-4175-9876-bcf73086732f',
          text: 'Add Unavailability',
          action: this.addUnavailabilityBlock,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    isCompanyBlocksEnabled() {
      const { getters = {} } = this.$store
      return getters['systemParameters/isCompanyBlocksEnabled']
    },
    isPreSelectedCompany() {
      return !!this.company
    },
    calendarDisplayDateAsJS() {
      return DateTime.fromISO(this.displayDate).toJSDate()
    },

    calendarDisplayDateMonth() {
      return DateTime.fromISO(this.displayDate).monthLong
    },
    startOfWeek() {
      return DateTime.fromISO(this.displayDate)
    },

    endOfWeek() {
      return DateTime.fromISO(this.displayDate).plus({
        days: NUMBER_OF_DAYS - 1,
      })
    },

    viewingMultipleCompanies() {
      return this.currentCompanyIds?.length > 1
    },

    isExpanded() {
      if (this.viewingMultipleCompanies) {
        if (this.viewByPartnerType) {
          return this.companyVehicleTypes.some((cvt) => !cvt.hidden)
        } else if (this.viewByVehicleType) {
          return this.vehicleTypeCompanies.some((vtc) => !vtc.hidden)
        }
      }
      return false
    },

    // Given the whole array of loaded reservations, and the beginning/end of
    // the current week view, return an array of all reservations
    displayedReservations() {
      const displayedReservations = []
      for (const reservation of this.loadedReservations) {
        const startDate = DateTime.fromISO(reservation.startDate)
        const endDate = DateTime.fromISO(reservation.endDate)
        if (this.startOfWeek <= startDate && this.endOfWeek >= startDate) {
          displayedReservations.push(reservation)
        } else if (this.startOfWeek <= endDate && this.endOfWeek >= endDate) {
          displayedReservations.push(reservation)
        } else if (startDate < this.startOfWeek && endDate > this.endOfWeek) {
          displayedReservations.push(reservation)
        }
      }
      return displayedReservations
    },

    // Given the whole array of loaded reservations, and the beginning/end of
    // the current week view, return an array of dates and offered vehicle type
    displayedOfferedVehicles() {
      const displayedOfferedVehicles = []
      for (const reservation of this.displayedReservations.filter(
        (dr) => dr.referralStatus === 'offered'
      )) {
        for (const requiredVehicle of reservation.requiredVehicles) {
          const newVehicle = {
            vehicleTypeId: requiredVehicle.vehicleType.id,
            quantity: requiredVehicle.quantity,
            startDate: reservation.startDate,
            endDate: reservation.endDate,
            reservation: reservation,
          }
          displayedOfferedVehicles.push(newVehicle)
        }
      }
      return displayedOfferedVehicles
    },

    displayedNotOfferedVehicles() {
      const displayedNotOfferedVehicles = []
      for (const reservation of this.displayedReservations.filter(
        (dr) =>
          dr.referralStatus === 'not_offered' &&
          dr.reservationStatusKey !== 'cancellation' &&
          dr.reservationStatusKey !== 'cancelled' &&
          dr.companyId === COACHRAIL_COMPANY_ID &&
          !dr.parentReservationId
      )) {
        for (const requiredVehicle of reservation.requiredVehicles) {
          const newVehicle = {
            vehicleTypeId: requiredVehicle.vehicleType.id,
            quantity: requiredVehicle.quantity,
            startDate: reservation.startDate,
            endDate: reservation.endDate,
            reservation: reservation,
          }
          displayedNotOfferedVehicles.push(newVehicle)
        }
      }
      return displayedNotOfferedVehicles
    },

    // Given the whole array of loaded reservations, and the beginning/end of
    // the current week view, return an array of dates and unassigned vehicle type
    displayedUnassignedVehicles() {
      const displayedUnassignedVehicles = []
      for (const reservation of this.displayedReservations.filter(
        (dr) => dr.referralStatus === 'accepted' && !dr.vehiclesAreFullyAssigned
      )) {
        for (const requiredVehicle of reservation.requiredVehicles) {
          const newVehicle = {
            quantity: requiredVehicle.quantity,
            vehicleTypeId: requiredVehicle.vehicleType.id,
            startDate: reservation.startDate,
            endDate: reservation.endDate,
            reservation: reservation,
          }
          displayedUnassignedVehicles.push(newVehicle)
        }
      }
      return displayedUnassignedVehicles
    },

    // Given the whole array of loaded reservations, and the beginning/end of
    // the current week view, return an array of dates and assigned vehicle type
    displayedAssignedVehicles() {
      const displayedAssignedVehicles = []
      for (const reservation of this.displayedReservations.filter(
        (dr) => dr.vehiclesAreFullyAssigned
      )) {
        for (const assignedVehicle of reservation.vehicleAssignments) {
          const newVehicle = {
            vehicleTypeId: assignedVehicle.vehicleTypeId,
            startDate: reservation.startDate,
            endDate: reservation.endDate,
            reservation: reservation,
          }
          displayedAssignedVehicles.push(newVehicle)
        }
      }
      return displayedAssignedVehicles
    },

    // Given the whole array of loaded vehicle type blocks, and the beginning/end of
    // the current week view, return an array of all vehicle type blocks
    displayedVehicleTypeBlocks() {
      const displayedVehicleTypeBlocks = []
      for (const block of this.loadedVehicleTypeBlocks) {
        const startDate = DateTime.fromISO(block.startDate)
        const endDate = DateTime.fromISO(block.endDate)
        if (this.startOfWeek <= startDate && this.endOfWeek >= startDate) {
          displayedVehicleTypeBlocks.push(block)
        } else if (this.startOfWeek <= endDate && this.endOfWeek >= endDate) {
          displayedVehicleTypeBlocks.push(block)
        } else if (startDate < this.startOfWeek && endDate > this.endOfWeek) {
          displayedVehicleTypeBlocks.push(block)
        }
      }
      return displayedVehicleTypeBlocks
    },
    // Given the whole array of loaded vehicle blocks, and the beginning/end of
    // the current week view, return an array of all vehicle blocks
    displayedVehicleBlocks() {
      const displayedVehicleBlocks = []
      for (const block of this.loadedVehicleBlocks) {
        const startDate = DateTime.fromISO(block.startDate)
        const endDate = DateTime.fromISO(block.endDate)
        if (this.startOfWeek <= startDate && this.endOfWeek >= startDate) {
          displayedVehicleBlocks.push(block)
        } else if (this.startOfWeek <= endDate && this.endOfWeek >= endDate) {
          displayedVehicleBlocks.push(block)
        } else if (startDate < this.startOfWeek && endDate > this.endOfWeek) {
          displayedVehicleBlocks.push(block)
        }
      }
      return displayedVehicleBlocks
    },

    // Rows for the vehicle sidebar + grid lines
    // Get an array of vehicle objects + how tall that row should be
    vehicleKeyRows() {
      const vehicleKeyRows = []
      if (!this.viewingMultipleCompanies || this.viewByPartnerType) {
        let distanceFromTop = 0
        for (const company of this.companyVehicleTypes) {
          const vehicles = deepClone(
            this.vehicles.filter((v) => v.companyId === company.companyId)
          )
          if (this.viewingMultipleCompanies) {
            const foundCompany = this.companies.find(
              (c) => c.companyId === company.companyId
            )
            if (foundCompany) {
              let companyInfo = [
                foundCompany.phone,
                foundCompany.email,
                this.getPartnerTypeName(foundCompany.partnerTypeId),
                foundCompany.address.completeAddress,
              ]
              let newCompanyKeyRow = {
                rowHeight: AVAILABILITY_ROW_HEIGHT,
                vehicle: null,
                vehicleType: null,
                vehicleTypeId: null,
                vehicleTypeQuantity: null,
                companyName: foundCompany.name,
                companyInfo: companyInfo,
                companyId: foundCompany.companyId,
                header: true,
                hidden: company.hidden,
                distanceFromTop: distanceFromTop + 1,
              }
              distanceFromTop += AVAILABILITY_ROW_HEIGHT
              vehicleKeyRows.push(newCompanyKeyRow)
            }
          }
          if (!company.hidden) {
            for (const vt of company.vehicleTypes) {
              let filteredVehicles = vehicles.filter(
                (v) => v.vehicleTypeName == vt.vehicleTypeName
              )
              let newVehicleTypeKeyRow = {
                rowHeight: AVAILABILITY_ROW_HEIGHT,
                vehicle: null,
                vehicleType: vt.vehicleTypeName,
                vehicleTypeId: vt.vehicleTypeId,
                vehicleTypeQuantity: filteredVehicles.length,
                companyId: company.companyId,
                header: true,
                notBold: this.viewingMultipleCompanies,
                hidden: vt.hidden,
                distanceFromTop: distanceFromTop + 1,
              }
              distanceFromTop += AVAILABILITY_ROW_HEIGHT
              vehicleKeyRows.push(newVehicleTypeKeyRow)
              if (!vt.hidden) {
                for (const vehicle of filteredVehicles) {
                  let newVehicleKeyRow = {
                    rowHeight: AVAILABILITY_ROW_HEIGHT,
                    vehicle: vehicle,
                    vehicleTypeId: vt.vehicleTypeId,
                    vehicleType: null,
                    companyId: company.companyId,
                    header: false,
                    hidden: false,
                    distanceFromTop: distanceFromTop,
                  }
                  distanceFromTop += AVAILABILITY_ROW_HEIGHT
                  vehicleKeyRows.push(newVehicleKeyRow)
                }
              }
            }
          }
        }
      } else if (this.viewByVehicleType) {
        let distanceFromTop = 0
        for (const vehicleType of this.vehicleTypeCompanies) {
          const vehicles = this.vehicles.filter(
            (v) => v.vehicleTypeId === vehicleType.vehicleTypeId
          )
          let newVehicleTypeKeyRow = {
            rowHeight: AVAILABILITY_ROW_HEIGHT,
            vehicle: null,
            vehicleType: vehicleType.vehicleTypeName,
            vehicleTypeId: vehicleType.vehicleTypeId,
            vehicleTypeQuantity: vehicles.length,
            header: true,
            notBold: false,
            hidden: vehicleType.hidden,
            distanceFromTop: distanceFromTop + 1,
          }
          distanceFromTop += AVAILABILITY_ROW_HEIGHT
          vehicleKeyRows.push(newVehicleTypeKeyRow)
          if (!vehicleType.hidden) {
            for (const company of vehicleType.companies) {
              const foundCompany = this.companies.find(
                (c) => c.companyId === company.companyId
              )
              if (foundCompany) {
                let companyInfo = [
                  foundCompany.phone,
                  foundCompany.email,
                  this.getPartnerTypeName(foundCompany.partnerTypeId),
                  foundCompany.address.completeAddress,
                ]
                const filteredVehicles = vehicles.filter(
                  (v) => v.companyId === company.companyId
                )
                let newCompanyKeyRow = {
                  rowHeight: AVAILABILITY_ROW_HEIGHT,
                  vehicle: null,
                  vehicleType: null,
                  vehicleTypeId: vehicleType.vehicleTypeId,
                  vehicleTypeQuantity: filteredVehicles.length,
                  companyName: foundCompany.name,
                  companyInfo: companyInfo,
                  companyId: foundCompany.companyId,
                  header: true,
                  hidden: company.hidden,
                  notBold: true,
                  distanceFromTop: distanceFromTop + 1,
                }
                distanceFromTop += AVAILABILITY_ROW_HEIGHT
                vehicleKeyRows.push(newCompanyKeyRow)
                if (!company.hidden) {
                  for (const vehicle of filteredVehicles) {
                    let newVehicleKeyRow = {
                      rowHeight: AVAILABILITY_ROW_HEIGHT,
                      vehicle: vehicle,
                      vehicleTypeId: vehicleType.vehicleTypeId,
                      vehicleType: null,
                      companyId: company.companyId,
                      header: false,
                      hidden: false,
                      distanceFromTop: distanceFromTop,
                    }
                    distanceFromTop += AVAILABILITY_ROW_HEIGHT
                    vehicleKeyRows.push(newVehicleKeyRow)
                  }
                }
              }
            }
          }
        }
      }
      return vehicleKeyRows
    },

    // For each displayed calendar item, find its starting height
    // on the calendar based on the vehicleKeyRows
    calendarItemsSortedByVehicles() {
      let calendarItems = []
      if (!this.viewingMultipleCompanies || this.viewByPartnerType) {
        const vehicleIdToStartingHeight = {}
        this.vehicleKeyRows.map((vkr) => {
          const companyKey = vkr?.companyId
          if (companyKey && vkr?.vehicle?.vehicleId) {
            vehicleIdToStartingHeight[
              companyKey + ':' + vkr.vehicle.vehicleId
            ] = vkr.distanceFromTop
          } else if (companyKey && vkr?.vehicleTypeId) {
            vehicleIdToStartingHeight[companyKey + ':' + vkr.vehicleTypeId] =
              vkr.distanceFromTop
          } else if (companyKey) {
            vehicleIdToStartingHeight[companyKey + ':' + companyKey] =
              vkr.distanceFromTop
          }
        })
        for (const company of this.companyVehicleTypes) {
          const sortedByVehicle = sortReservationBlocksByVehicle(
            this.displayedReservations.filter(
              (dr) => dr?.companyId === company?.companyId
            ),
            this.startOfWeek,
            NUMBER_OF_DAYS
          )
          const sortedVehicleBlocksByVehicle = sortAvailabilityBlocksByVehicle(
            this.displayedVehicleBlocks.filter(
              (dvb) => dvb?.companyId === company?.companyId
            ),
            this.startOfWeek,
            NUMBER_OF_DAYS
          )
          const sortedVehicleTypeBlocksByVehicle = sortTypeAvailabilityBlocksByVehicle(
            this.displayedVehicleTypeBlocks.filter(
              (dvtb) => dvtb?.companyId === company?.companyId
            )
          )
          const offeredVehicles = this.displayedOfferedVehicles.filter(
            (dov) => dov.reservation?.companyId === company?.companyId
          )
          const unassignedVehicles = this.displayedUnassignedVehicles.filter(
            (duv) => duv.reservation?.companyId === company?.companyId
          )
          const assignedVehicles = this.displayedAssignedVehicles.filter(
            (dav) => dav.reservation?.companyId === company?.companyId
          )
          const foundCompany = this.companies.find(
            (c) => c?.companyId === company?.companyId
          )
          if (!company.hidden && foundCompany) {
            const generalAvailabilityBlocks = getGeneralAvailabilityBlocks(
              this.vehicleKeyRows,
              vehicleIdToStartingHeight,
              this.startOfWeek,
              NUMBER_OF_DAYS,
              company.vehicleTypes,
              offeredVehicles,
              unassignedVehicles,
              assignedVehicles,
              this.displayedVehicleBlocks.filter(
                (dvb) => dvb.companyId === company.companyId
              ),
              this.displayedVehicleTypeBlocks.filter(
                (dvtb) => dvtb.companyId === company.companyId
              ),
              foundCompany,
              this.addInCalendarView,
              this.addInCalendarAdd,
              this.addInCalendarDelete
            )
            calendarItems = calendarItems.concat(generalAvailabilityBlocks)
          }
          if (this.viewingMultipleCompanies) {
            const companyFillerBlock = createCompanyFillerBlock(
              company?.companyId,
              vehicleIdToStartingHeight,
              this.startOfWeek,
              this.endOfWeek
            )
            calendarItems.push(companyFillerBlock)
          }
          if (Object.keys(vehicleIdToStartingHeight).length === 0) {
            continue
          }
          for (const vehicleTypeId of Object.keys(
            sortedVehicleTypeBlocksByVehicle
          )) {
            const key = company?.companyId + ':' + vehicleTypeId
            if (
              vehicleIdToStartingHeight[key] ||
              vehicleIdToStartingHeight[key] === 0
            ) {
              const newTop = vehicleIdToStartingHeight[key]
              const newBlocks = sortedVehicleTypeBlocksByVehicle[
                vehicleTypeId
              ].associatedAvailabilityBlock.map((block) => {
                block.top = newTop
                block.isVehicleAvailabilityBlock = false
                block.isVehicleTypeAvailabilityBlock = true
                block.isUnassignBlock = false
                block.addInCalendarView = this.addInCalendarView
                return block
              })

              if (!company?.hidden) {
                let foundVehicleType = company.vehicleTypes.find(
                  (vt) => vt?.vehicleTypeId === Number.parseInt(vehicleTypeId)
                )
                if (!foundVehicleType?.hidden) {
                  const vehicleIds = this.vehicles
                    .filter(
                      (v) =>
                        v?.companyId === company?.companyId &&
                        v?.vehicleTypeId === Number.parseInt(vehicleTypeId)
                    )
                    .map((v) => v.vehicleId)
                  for (const block of sortedVehicleTypeBlocksByVehicle[
                    vehicleTypeId
                  ].associatedAvailabilityBlock) {
                    for (const vehicleId of vehicleIds) {
                      const key = company?.companyId + ':' + vehicleId
                      if (
                        vehicleIdToStartingHeight[key] ||
                        vehicleIdToStartingHeight[key] === 0
                      ) {
                        const newTop = vehicleIdToStartingHeight[key]
                        let newBlock = deepClone(block)
                        newBlock.top = newTop + 1
                        newBlock.isVehicleAvailabilityBlock = false
                        newBlock.isVehicleTypeAvailabilityBlock = false
                        newBlock.isUnassignBlock = false
                        newBlock.isTypeFillerBlock = true
                        newBlocks.push(newBlock)
                      }
                    }
                  }
                }
              }
              calendarItems = calendarItems.concat(newBlocks)
            }
          }
          for (const vehicleId of Object.keys(sortedVehicleBlocksByVehicle)) {
            const key = company?.companyId + ':' + vehicleId
            if (
              vehicleIdToStartingHeight[key] ||
              vehicleIdToStartingHeight[key] === 0
            ) {
              const rowHeight = this.vehicleBlockRowHeight(vehicleId)
              const newBlocks = sortedVehicleBlocksByVehicle[
                vehicleId
              ].associatedAvailabilityBlock.map((block) => {
                block.top = vehicleIdToStartingHeight[key]
                block.height = rowHeight
                block.isVehicleAvailabilityBlock = true
                block.isVehicleTypeAvailabilityBlock = false
                block.isUnassignBlock = false
                block.addInCalendarView = this.addInCalendarView
                return block
              })
              calendarItems = calendarItems.concat(newBlocks)
            }
          }
          for (const vehicleId of Object.keys(sortedByVehicle)) {
            const key = company?.companyId + ':' + vehicleId
            if (
              vehicleIdToStartingHeight[key] ||
              vehicleIdToStartingHeight[key] === 0
            ) {
              const newBlocks = sortedByVehicle[vehicleId].blocks.map(
                (block) => {
                  block.isVehicleAvailabilityBlock = false
                  block.isVehicleTypeAvailabilityBlock = false
                  block.isUnassignBlock = false
                  block.companies = [foundCompany]
                  block.vehicle = sortedByVehicle[vehicleId].vehicle
                  block.top = vehicleIdToStartingHeight[key]
                  block.addInCalendarView = this.addInCalendarView
                  return block
                }
              )
              calendarItems = calendarItems.concat(newBlocks)
            }
          }
          if (!company?.hidden) {
            let newFreeBlocks = []
            for (const vehicleType of company.vehicleTypes) {
              if (!vehicleType?.hidden) {
                const vehicleIds = this.vehicles
                  .filter(
                    (v) =>
                      v?.companyId === company?.companyId &&
                      v?.vehicleTypeId ===
                        Number.parseInt(vehicleType?.vehicleTypeId)
                  )
                  .map((v) => v.vehicleId)
                for (const vehicleId of vehicleIds) {
                  const key = company?.companyId + ':' + vehicleId
                  if (
                    vehicleIdToStartingHeight[key] ||
                    vehicleIdToStartingHeight[key] === 0
                  ) {
                    const newTop = vehicleIdToStartingHeight[key] + 1
                    const filteredItems = deepClone(calendarItems).filter(
                      (ci) => ci?.top === newTop || ci?.top === newTop - 1
                    )
                    for (
                      let dayIncrement = 0;
                      dayIncrement < NUMBER_OF_DAYS;
                      dayIncrement++
                    ) {
                      const day = DateTime.fromISO(this.startOfWeek)
                        .plus({ days: dayIncrement })
                        .startOf('day')
                      if (
                        filteredItems.filter(
                          (fi) =>
                            day >= DateTime.fromISO(fi.startDate) &&
                            day <= DateTime.fromISO(fi.endDate)
                        )?.length === 0
                      ) {
                        const isAddedBlock = !!this.addInCalendarAdd.find(
                          (ab) =>
                            ab.vehicleId === vehicleId &&
                            ab.startDate === day.toISODate() &&
                            ab.endDate === day.toISODate()
                        )
                        let newBlock = {
                          startDate: day.toISODate(),
                          endDate: day.toISODate(),
                          top: newTop,
                          isVehicleAvailabilityBlock: false,
                          isVehicleTypeAvailabilityBlock: false,
                          isUnassignBlock: false,
                          isTypeFillerBlock: false,
                          isFreeFillerBlock: true,
                          addInCalendarView: this.addInCalendarView,
                          companies: [foundCompany],
                          vehicleId,
                          vehicleTypeId: vehicleType.vehicleTypeId,
                          isAddedBlock,
                        }
                        newFreeBlocks.push(newBlock)
                      }
                    }
                  }
                }
              }
            }
            calendarItems = calendarItems.concat(newFreeBlocks)
          }
        }
      } else if (this.viewByVehicleType) {
        const vehicleIdToStartingHeight = {}
        this.vehicleKeyRows.map((vkr) => {
          const companyKey = vkr?.companyId
          if (companyKey && vkr?.vehicle?.vehicleId) {
            vehicleIdToStartingHeight[
              companyKey + ':' + vkr.vehicle.vehicleId
            ] = vkr.distanceFromTop
          } else if (companyKey && vkr?.vehicleTypeId) {
            vehicleIdToStartingHeight[companyKey + ':' + vkr.vehicleTypeId] =
              vkr.distanceFromTop
          } else if (vkr?.vehicleTypeId) {
            vehicleIdToStartingHeight[
              vkr.vehicleTypeId + ':' + vkr.vehicleTypeId
            ] = vkr.distanceFromTop
          }
        })
        const vehicleTypeGeneralAvailabilityBlocks = getVehicleTypeGeneralAvailabilityBlocks(
          this.vehicleKeyRows,
          vehicleIdToStartingHeight,
          this.startOfWeek,
          NUMBER_OF_DAYS,
          this.vehicleTypeCompanies,
          this.displayedOfferedVehicles,
          this.displayedNotOfferedVehicles,
          this.displayedUnassignedVehicles,
          this.displayedAssignedVehicles,
          this.displayedVehicleBlocks,
          this.displayedVehicleTypeBlocks,
          this.companies,
          this.addInCalendarView,
          this.addInCalendarAdd
        )
        calendarItems = calendarItems.concat(
          vehicleTypeGeneralAvailabilityBlocks.map((vtgab) => {
            return {
              ...vtgab,
              isGeneralBlock: false,
              isVehicleTypeGeneralBlock: true,
            }
          })
        )
        for (const company of this.companies) {
          const sortedByVehicle = sortReservationBlocksByVehicle(
            this.displayedReservations.filter(
              (dr) => dr?.companyId === company?.companyId
            ),
            this.startOfWeek,
            NUMBER_OF_DAYS
          )
          for (const vehicleId of Object.keys(sortedByVehicle)) {
            const key = company?.companyId + ':' + vehicleId
            if (
              vehicleIdToStartingHeight[key] ||
              vehicleIdToStartingHeight[key] === 0
            ) {
              const newBlocks = sortedByVehicle[vehicleId].blocks.map(
                (block) => {
                  block.isVehicleAvailabilityBlock = false
                  block.isVehicleTypeAvailabilityBlock = false
                  block.isUnassignBlock = false
                  block.companies = [company]
                  block.vehicle = sortedByVehicle[vehicleId].vehicle
                  block.top = vehicleIdToStartingHeight[key]
                  block.addInCalendarView = this.addInCalendarView
                  return block
                }
              )
              calendarItems = calendarItems.concat(newBlocks)
            }
          }
          const offeredVehicles = this.displayedOfferedVehicles.filter(
            (dov) => dov.reservation?.companyId === company?.companyId
          )
          const unassignedVehicles = this.displayedUnassignedVehicles.filter(
            (duv) => duv.reservation?.companyId === company?.companyId
          )
          const assignedVehicles = this.displayedAssignedVehicles.filter(
            (dav) => dav.reservation?.companyId === company?.companyId
          )
          const generalAvailabilityBlocks = getGeneralAvailabilityBlocks(
            this.vehicleKeyRows,
            vehicleIdToStartingHeight,
            this.startOfWeek,
            NUMBER_OF_DAYS,
            this.vehicleTypeCompanies,
            offeredVehicles,
            unassignedVehicles,
            assignedVehicles,
            this.displayedVehicleBlocks.filter(
              (dvb) => dvb.companyId === company.companyId
            ),
            this.displayedVehicleTypeBlocks.filter(
              (dvtb) => dvtb.companyId === company.companyId
            ),
            company,
            this.addInCalendarView,
            this.addInCalendarAdd,
            this.addInCalendarDelete
          )
          calendarItems = calendarItems.concat(generalAvailabilityBlocks)
        }
        for (const vehicleType of this.vehicleTypeCompanies) {
          for (const company of vehicleType.companies) {
            const sortedVehicleBlocksByVehicle = sortAvailabilityBlocksByVehicle(
              this.displayedVehicleBlocks.filter(
                (dvb) =>
                  dvb?.companyId === company?.companyId &&
                  dvb.vehicleTypeId === vehicleType.vehicleTypeId
              ),
              this.startOfWeek,
              NUMBER_OF_DAYS
            )
            const sortedVehicleTypeBlocksByVehicle = sortTypeAvailabilityBlocksByVehicle(
              this.displayedVehicleTypeBlocks.filter(
                (dvtb) =>
                  dvtb?.companyId === company?.companyId &&
                  dvtb.vehicleTypeId === vehicleType.vehicleTypeId
              )
            )
            if (Object.keys(vehicleIdToStartingHeight).length === 0) {
              continue
            }
            for (const vehicleTypeId of Object.keys(
              sortedVehicleTypeBlocksByVehicle
            )) {
              const key = company?.companyId + ':' + vehicleTypeId
              if (
                vehicleIdToStartingHeight[key] ||
                vehicleIdToStartingHeight[key] === 0
              ) {
                const newTop = vehicleIdToStartingHeight[key]
                const newBlocks = sortedVehicleTypeBlocksByVehicle[
                  vehicleTypeId
                ].associatedAvailabilityBlock.map((block) => {
                  block.top = newTop
                  block.isVehicleAvailabilityBlock = false
                  block.isVehicleTypeAvailabilityBlock = true
                  block.isUnassignBlock = false
                  block.addInCalendarView = this.addInCalendarView
                  return block
                })

                if (!company?.hidden) {
                  const vehicleIds = this.vehicles
                    .filter(
                      (v) =>
                        v?.companyId === company?.companyId &&
                        v?.vehicleTypeId === Number.parseInt(vehicleTypeId)
                    )
                    .map((v) => v.vehicleId)
                  for (const block of sortedVehicleTypeBlocksByVehicle[
                    vehicleTypeId
                  ].associatedAvailabilityBlock) {
                    for (const vehicleId of vehicleIds) {
                      const key = company?.companyId + ':' + vehicleId
                      if (
                        vehicleIdToStartingHeight[key] ||
                        vehicleIdToStartingHeight[key] === 0
                      ) {
                        const newTop = vehicleIdToStartingHeight[key]
                        let newBlock = deepClone(block)
                        newBlock.top = newTop + 1
                        newBlock.isVehicleAvailabilityBlock = false
                        newBlock.isVehicleTypeAvailabilityBlock = false
                        newBlock.isUnassignBlock = false
                        newBlock.isTypeFillerBlock = true
                        newBlocks.push(newBlock)
                      }
                    }
                  }
                }
                calendarItems = calendarItems.concat(newBlocks)
              }
            }
            for (const vehicleId of Object.keys(sortedVehicleBlocksByVehicle)) {
              const key = company?.companyId + ':' + vehicleId
              if (
                vehicleIdToStartingHeight[key] ||
                vehicleIdToStartingHeight[key] === 0
              ) {
                const rowHeight = this.vehicleBlockRowHeight(vehicleId)
                const newBlocks = sortedVehicleBlocksByVehicle[
                  vehicleId
                ].associatedAvailabilityBlock.map((block) => {
                  block.top = vehicleIdToStartingHeight[key]
                  block.height = rowHeight
                  block.isVehicleAvailabilityBlock = true
                  block.isVehicleTypeAvailabilityBlock = false
                  block.isUnassignBlock = false
                  block.addInCalendarView = this.addInCalendarView
                  return block
                })
                calendarItems = calendarItems.concat(newBlocks)
              }
            }
            if (!vehicleType?.hidden) {
              let newFreeBlocks = []
              if (!company?.hidden) {
                const vehicleIds = this.vehicles
                  .filter(
                    (v) =>
                      v?.companyId === company?.companyId &&
                      v?.vehicleTypeId ===
                        Number.parseInt(vehicleType?.vehicleTypeId)
                  )
                  .map((v) => v.vehicleId)
                for (const vehicleId of vehicleIds) {
                  const key = company?.companyId + ':' + vehicleId
                  if (
                    vehicleIdToStartingHeight[key] ||
                    vehicleIdToStartingHeight[key] === 0
                  ) {
                    const newTop = vehicleIdToStartingHeight[key] + 1
                    const filteredItems = deepClone(calendarItems).filter(
                      (ci) => ci?.top === newTop || ci?.top === newTop - 1
                    )
                    for (
                      let dayIncrement = 0;
                      dayIncrement < NUMBER_OF_DAYS;
                      dayIncrement++
                    ) {
                      const day = DateTime.fromISO(this.startOfWeek)
                        .plus({ days: dayIncrement })
                        .startOf('day')
                      if (
                        filteredItems.filter(
                          (fi) =>
                            day >= DateTime.fromISO(fi.startDate) &&
                            day <= DateTime.fromISO(fi.endDate)
                        )?.length === 0
                      ) {
                        let newBlock = {
                          startDate: day.toISODate(),
                          endDate: day.toISODate(),
                          top: newTop,
                          isVehicleAvailabilityBlock: false,
                          isVehicleTypeAvailabilityBlock: false,
                          isUnassignBlock: false,
                          isTypeFillerBlock: false,
                          isFreeFillerBlock: true,
                        }
                        newFreeBlocks.push(newBlock)
                      }
                    }
                  }
                }
              }

              calendarItems = calendarItems.concat(newFreeBlocks)
            }
          }
        }
      }
      return calendarItems
    },

    // Minimum height of the entire calendar
    // Get the starting height of the last row, and add its height
    minHeight() {
      let lastRow
      lastRow = this.vehicleKeyRows[this.vehicleKeyRows.length - 1]

      if (!lastRow) {
        return 0
      }
      const { distanceFromTop, rowHeight } = lastRow
      return distanceFromTop + rowHeight
    },
  },
  watch: {
    // When the displayed date changes, check if we need to
    // load more reservations by checking the date intervals
    // we've already loaded in our interval tree
    displayDate: function (val) {
      for (
        let dayIncrement = 0;
        dayIncrement < NUMBER_OF_DAYS;
        dayIncrement++
      ) {
        const day = this.startOfWeek
          .plus({ days: dayIncrement })
          .startOf('day')
          .toMillis()
        const dataIsLoadedForDate = this.loadedDateIntervals.intersect_any([
          day,
          day,
        ])
        if (!dataIsLoadedForDate) {
          this.getDataForDates(
            this.startOfWeek.toISODate(),
            this.endOfWeek.toISODate()
          )
        }
      }
    },

    currentCompanyIds: async function (val) {
      this.loadedDateIntervals = new IntervalTree()
      this.vehicles = []
      this.companyVehicleTypes = []
      this.vehicleTypeCompanies = []
      this.companies = []
      this.loadedReservations = []
      this.loadedVehicleTypeBlocks = []
      this.loadedVehicleBlocks = []
      this.addInCalendarView = false
      this.addInCalendarAdd = []
      this.addInCalendarDelete = []
      await this.getCompanies()
      await this.getVehicles()
      await this.getDataForDates(
        DateTime.fromISO(this.displayDate)
          .startOf('day')
          .minus({ weeks: 2 })
          .toISODate(),
        DateTime.fromISO(this.displayDate)
          .startOf('day')
          .plus({ days: NUMBER_OF_DAYS - 1 })
          .toISODate()
      )
    },
  },
  async mounted() {
    if (this.isPreSelectedCompany) {
      this.currentCompanyIds = [this.company.companyId]
    }
    await this.getVehicleTypes()
    await this.getPartnerTypes()
    await this.getVehicles()
    await this.getDataForDates(
      DateTime.local().startOf('day').minus({ weeks: 2 }).toISODate(),
      DateTime.local()
        .startOf('day')
        .plus({ days: NUMBER_OF_DAYS - 1 })
        .toISODate()
    )
    EventBus.$on(
      'reload-vehicle-type-availability',
      (startDate, endDate, availabilityBlockIds = []) => {
        if (availabilityBlockIds.length) {
          this.loadedVehicleTypeBlocks = this.loadedVehicleTypeBlocks.filter(
            (lvt) => !availabilityBlockIds.includes(lvt.availabilityBlockId)
          )
        }
        startDate = startDate || this.startOfWeek
        endDate = endDate || this.endOfWeek
        setTimeout(() => {
          this.getVehicleTypeBlocksForDates(startDate, endDate)
        }, 1000)
      }
    )
    EventBus.$on('reload-vehicle-availability', (startDate, endDate, availabilityBlockIds = []) => {
      startDate = startDate || this.startOfWeek
      endDate = endDate || this.endOfWeek
      if (availabilityBlockIds.length) {
        this.loadedVehicleBlocks = this.loadedVehicleBlocks.filter(
          (lv) => !availabilityBlockIds.includes(lv.availabilityBlockId)
        )
      }
      setTimeout(() => {
        this.getVehicleBlocksForDates(startDate, endDate)
      }, 1000)
    })
    EventBus.$on('delete-availability', (availabilityBlockId) => {
      this.loadedVehicleTypeBlocks = this.loadedVehicleTypeBlocks.filter(
        (lvt) => lvt.availabilityBlockId !== availabilityBlockId
      )
      this.loadedVehicleBlocks = this.loadedVehicleBlocks.filter((lv) => lv.availabilityBlockId !== availabilityBlockId)
    })
    EventBus.$on('add-to-add-list', (availabilityBlock) => {
      let foundBlockIndex
      if (availabilityBlock.vehicleId) {
        foundBlockIndex = this.addInCalendarAdd.findIndex((ab) => ab.startDate == availabilityBlock.startDate && ab.vehicleId == availabilityBlock.vehicleId && ab.companyId == availabilityBlock.companyId)
      } else {
        foundBlockIndex = this.addInCalendarAdd.findIndex(
          (ab) =>
            ab.startDate == availabilityBlock.startDate &&
            ab.vehicleTypeId == availabilityBlock.vehicleTypeId &&
            ab.companyId == availabilityBlock.companyId &&
            (ab.vehicleId === null || ab.vehicleId === undefined)
        )
      }
      if (foundBlockIndex !== -1) {
        this.addInCalendarAdd.splice(foundBlockIndex, 1)
      } else {
        this.addInCalendarAdd.push(availabilityBlock)
      }
    })
    EventBus.$on('add-to-delete-list', (availabilityBlock) => {
      if (availabilityBlock.vehicleId) {
        this.loadedVehicleBlocks = this.loadedVehicleBlocks.filter((lv) => lv.availabilityBlockId !== availabilityBlock.availabilityBlockId)
      } else {
        this.loadedVehicleTypeBlocks = this.loadedVehicleTypeBlocks.filter(
          (lvt) =>
            lvt.availabilityBlockId !== availabilityBlock.availabilityBlockId
        )
      }
      this.addInCalendarDelete.push({
        startDate: availabilityBlock.startDate,
        endDate: availabilityBlock.endDate,
        availabilityBlockId: availabilityBlock.availabilityBlockId,
        vehicleId: availabilityBlock.vehicleId
      })
    })
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    isTabActive(tab) {
      if (tab._t_id === '442ee488-7e35-499e-b780-cfcf34d983b4') {
        return this.viewByPartnerType
      }
      if (tab._t_id === 'a08ef1cf-8bee-44f9-9b8f-f41b32a3d1e2') {
        return this.viewByVehicleType
      }
      return false
    },
    setDateToday() {
      this.displayDate = DateTime.local().toISODate()
    },
    companiesSelected(companyIds) {
      if (companyIds === null) {
        this.currentCompanyIds = []
        return
      }
      if (companyIds?.length) {
        this.currentCompanyIds = companyIds
      }
      return
    },
    shiftCalendarDisplayDate(numWeeks) {
      this.displayDate = DateTime.fromISO(this.displayDate)
        .plus({ weeks: numWeeks })
        .toISODate()
    },
    addUnavailabilityBlock() {
      const component = () =>
        import('@/components/VehicleAvailabilitySidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          mode: 'add',
          companyId: this.currentCompanyIds[0] || null,
          isTypeBlock: true,
          isAffiliateView: this.isAffiliateView,
          title: 'Add Unavailability',
        },
        component,
      })
    },

    async getDataForDates(startDatetime, endDatetime) {
      this.getDispatchDataForDates(startDatetime, endDatetime)
      this.getVehicleTypeBlocksForDates(startDatetime, endDatetime)
      this.getVehicleBlocksForDates(startDatetime, endDatetime)

      const startTime = DateTime.fromISO(startDatetime).toMillis()
      const endTime = DateTime.fromISO(endDatetime).toMillis()
      this.loadedDateIntervals.insert([startTime, endTime], startDatetime)
    },

    async getDispatchDataForDates(startDatetime, endDatetime) {
      if (!this.currentCompanyIds.length) {
        return
      }
      this.loading = true
      const companyIds = deepClone(this.currentCompanyIds)
      let newResArray = deepClone(this.loadedReservations)
      try {
        await Promise.all(
          companyIds.map(async (cci) => {
            const params = {
              payload: {
                from: DateTime.fromISO(startDatetime),
                to: DateTime.fromISO(endDatetime).endOf('day'),
                companyId: cci,
              },
            }
            const res = await this.$store.dispatch(
              'dispatch/getDispatchDataAsBroker',
              params
            )
            for (const reservation of res.data.reservations) {
              if (
                (reservation.referralStatus === 'not_offered' &&
                  reservation.companyId !== COACHRAIL_COMPANY_ID &&
                  !reservation.parentReservationId) ||
                (reservation.isPreBooking &&
                  !!reservation.allocatedFromReservationId)
              ) {
                continue
              }
              const availabilityBlock = convertReservationToAvailabilityBlock(
                reservation
              )
              newResArray = newResArray.filter(
                (res) => res.reservationId !== availabilityBlock.reservationId
              )
              newResArray.push(deepClone(availabilityBlock))
            }
          })
        )
      } catch (e) {
        console.error(e)
      }
      this.loadedReservations = deepClone(newResArray)
      this.loading = false
    },

    async getVehicleTypeBlocksForDates(startDatetime, endDatetime) {
      if (!this.currentCompanyIds.length) {
        return
      }
      let newVtabArray = deepClone(this.loadedVehicleTypeBlocks)
      await Promise.all(
        this.currentCompanyIds.map(async (cci) => {
          const foundCompany = this.companyVehicleTypes.find(
            (cvt) => cvt.companyId === cci
          )
          if (foundCompany) {
            const params = {
              payload: {
                startDatetime: DateTime.fromISO(startDatetime),
                endDatetime: DateTime.fromISO(endDatetime).endOf('day'),
                vehicleIds: null,
                vehicleTypeIds: foundCompany.vehicleTypes.map((vt) => {
                  return vt.vehicleTypeId
                }),
                companyId: cci,
              },
            }
            const res = await availability.getVehicleTypeBlocks(params)
            for (const availabilityBlock of res.data
              .vehicleTypeAvailabilityBlocks) {
              const newAvailabilityBlock = {
                ...availabilityBlock,
                startDate: DateTime.fromISO(
                  availabilityBlock.startDatetime.split('T')[0]
                ).toISODate(),
                endDate: DateTime.fromISO(
                  availabilityBlock.endDatetime.split('T')[0]
                ).toISODate(),
                vehicleTypeLabel: this.vehicleTypes.find(
                  (vt) => vt.id === availabilityBlock.vehicleTypeId
                )?.label,
                vehicles: this.vehicles.filter(
                  (v) =>
                    v.companyId === availabilityBlock.companyId &&
                    v.vehicleTypeId === availabilityBlock.vehicleTypeId
                ),
              }
              newVtabArray = newVtabArray.filter(
                (vtab) =>
                  vtab.availabilityBlockId !==
                  newAvailabilityBlock.availabilityBlockId
              )
              newVtabArray.push(deepClone(newAvailabilityBlock))
            }
          }
        })
      )
      this.loadedVehicleTypeBlocks = deepClone(newVtabArray)
    },

    async getVehicleBlocksForDates(startDatetime, endDatetime) {
      if (!this.currentCompanyIds.length) {
        return
      }
      let newVbArray = deepClone(this.loadedVehicleBlocks)
      await Promise.all(
        this.currentCompanyIds.map(async (cci) => {
          const params = {
            payload: {
              startDatetime: DateTime.fromISO(startDatetime),
              endDatetime: DateTime.fromISO(endDatetime).endOf('day'),
              vehicleIds: this.vehicles.map((v) => {
                return v.vehicleId
              }),
              vehicleTypeIds: null,
              companyId: cci,
            },
          }
          const res = await availability.getVehicleBlocks(params)
          for (const availabilityBlock of res.data.vehicleAvailabilityBlocks) {
            const newAvailabilityBlock = {
              ...availabilityBlock,
              startDate: DateTime.fromISO(
                availabilityBlock.startDatetime.split('T')[0]
              ).toISODate(),
              endDate: DateTime.fromISO(
                availabilityBlock.endDatetime.split('T')[0]
              ).toISODate(),
            }
            newVbArray = newVbArray.filter(
              (vb) =>
                vb.availabilityBlockId !==
                newAvailabilityBlock.availabilityBlockId
            )
            newVbArray.push(deepClone(newAvailabilityBlock))
          }
          if (this.isCompanyBlocksEnabled) {
            const companyRes = await availability.getCompanyBlocks(params)
            for (const availabilityBlock of companyRes.data
              .companyAvailabilityBlocks) {
              for (const vehicle of this.vehicles.filter(
                (v) => v.companyId === cci
              )) {
                const newAvailabilityBlock = {
                  ...availabilityBlock,
                  availabilityBlockId: `${availabilityBlock.availabilityBlockId}|${vehicle.vehicleId}`,
                  vehicleId: vehicle.vehicleId,
                  vehicleName: vehicle.vehicleName,
                  vehicleTypeId: vehicle.vehicleTypeId,
                  startDate: DateTime.fromISO(
                    availabilityBlock.startDatetime.split('T')[0]
                  ).toISODate(),
                  endDate: DateTime.fromISO(
                    availabilityBlock.endDatetime.split('T')[0]
                  ).toISODate(),
                }
                newVbArray = newVbArray.filter(
                  (vb) =>
                    vb.availabilityBlockId !==
                    newAvailabilityBlock.availabilityBlockId
                )
                newVbArray.push(deepClone(newAvailabilityBlock))
              }
            }
          }
        })
      )
      this.loadedVehicleBlocks = deepClone(newVbArray)
    },

    async getVehicles() {
      if (!this.currentCompanyIds.length) {
        return
      }
      await Promise.all(
        this.currentCompanyIds.map(async (cci) => {
          const vehiclesListRes = await this.$store.dispatch(
            'vehicles/getAllVehiclesForCompany',
            { pageSize: -1, page: 1, companyId: cci }
          )
          for (const vehicle of vehiclesListRes.data.resultList.filter(
            (v) => v.companyId && v.active
          )) {
            if (!this.vehicles.find((v) => v.vehicleId === vehicle.vehicleId)) {
              this.vehicles.push(vehicle)
            }
          }
          const vehicleTypeSet = [
            ...new Set(
              vehiclesListRes.data.resultList.map((v) => v.vehicleTypeName)
            ),
          ]
          if (!this.companyVehicleTypes.find((cvt) => cvt.companyId === cci)) {
            this.companyVehicleTypes = this.companyVehicleTypes.concat({
              companyId: cci,
              companyName: this.companies.find(
                (company) => company.companyId === cci
              )?.name,
              vehicleTypes: this.vehicleTypes
                .filter((vt) => vehicleTypeSet.find((vts) => vts === vt.label))
                .map((vt) => {
                  return {
                    vehicleTypeName: vt.label,
                    hidden: true,
                    vehicleTypeId: vt.id,
                  }
                }),
              hidden: this.viewingMultipleCompanies ? true : false,
            })
          }
          for (const vehicleTypeName of vehicleTypeSet) {
            const foundVehicleType = this.vehicleTypeCompanies.find(
              (vtc) => vtc.vehicleTypeName === vehicleTypeName
            )
            if (!foundVehicleType) {
              const foundTypeInfo = this.vehicleTypes.find(
                (vt) => vt.label === vehicleTypeName
              )
              if (foundTypeInfo) {
                this.vehicleTypeCompanies.push({
                  vehicleTypeId: foundTypeInfo.id,
                  vehicleTypeName: foundTypeInfo.label,
                  hidden: true,
                  companies: [
                    {
                      companyId: cci,
                      companyName: this.companies.find(
                        (company) => company.companyId === cci
                      )?.name,
                      hidden: true,
                    },
                  ],
                })
              }
            } else if (
              foundVehicleType &&
              !foundVehicleType.companies.find((c) => c.companyId === cci)
            ) {
              foundVehicleType.companies.push({
                companyId: cci,
                companyName: this.companies.find(
                  (company) => company.companyId === cci
                )?.name,
                hidden: true,
              })
            }
          }
        })
      )
      this.companyVehicleTypes = this.companyVehicleTypes.sort((a, b) =>
        a.companyName > b.companyName ? 0 : -1
      )
      this.vehicleTypeCompanies = this.vehicleTypeCompanies
        .sort((a, b) => (a.vehicleTypeId > b.vehicleTypeId ? 0 : -1))
        .map((vtc) => {
          return {
            ...vtc,
            companies: vtc.companies.sort((a, b) =>
              a.companyName > b.companyName ? 0 : -1
            ),
          }
        })
    },

    async getPartnerTypes() {
      const partnerTypeResults = await this.$store
        .dispatch('types/getPartnerTypes', {
          pageSize: -1,
        })
        .catch((e) => {
          console.error(e)
        })
      this.partnerTypes = partnerTypeResults.data.resultList.filter(
        (pt) => !(pt.id === 1 || pt.id === 2)
      )
      if (!this.partnerTypes.find((pt) => pt.id === -1)) {
        this.partnerTypes.unshift({ id: -1, key: 'all', label: 'All' })
      }
    },

    async getCompanies() {
      await Promise.all(
        this.currentCompanyIds.map(async (cci) => {
          const companyRes = await companies.getCompany(cci)
          if (
            !this.companies.find(
              (c) => c.companyId === companyRes.data?.company.companyId
            )
          ) {
            this.companies.push(companyRes.data?.company)
          }
        })
      )
    },

    async getVehicleTypes() {
      const res = await this.$store.dispatch('types/getVehicleTypes', {
        pageSize: -1,
        page: 1,
      })
      this.vehicleTypes = res.data.resultList
    },

    adjustVehicleKeyRows(rowName) {
      if (!this.viewingMultipleCompanies || this.viewByPartnerType) {
        const foundCompanyId = this.companies.find((c) => c.name === rowName)
          ?.companyId
        if (foundCompanyId) {
          const changedRow = this.companyVehicleTypes.find(
            (cvt) => cvt.companyId === foundCompanyId
          )
          if (changedRow) {
            changedRow.hidden = !changedRow.hidden
          }
        } else {
          const companyId = Number.parseInt(rowName.split(':')[0])
          const vehicleType = rowName.split(':')[1]
          const companyVehicleTypeRow = this.companyVehicleTypes.find(
            (cvt) => cvt.companyId === companyId
          )
          if (companyVehicleTypeRow) {
            const changedRow = companyVehicleTypeRow.vehicleTypes.find(
              (vt) => vt.vehicleTypeName === vehicleType
            )
            if (changedRow) {
              changedRow.hidden = !changedRow.hidden
            }
          }
        }
      } else if (this.viewByVehicleType) {
        const changedRow = this.vehicleTypeCompanies.find(
          (vtc) => vtc.vehicleTypeId === rowName
        )
        if (changedRow) {
          changedRow.hidden = !changedRow.hidden
        } else {
          const vehicleTypeId = Number.parseInt(rowName.split(':')[0])
          const companyId = Number.parseInt(rowName.split(':')[1])
          const vehicleTypeRow = this.vehicleTypeCompanies.find(
            (vtc) => vtc.vehicleTypeId === vehicleTypeId
          )
          if (vehicleTypeRow) {
            const changedRow = vehicleTypeRow.companies.find(
              (c) => c.companyId === companyId
            )
            if (changedRow) {
              changedRow.hidden = !changedRow.hidden
            }
          }
        }
      }
    },

    vehicleBlockRowHeight(vehicleId) {
      const vehicleKeyRow = this.vehicleKeyRows.find(
        (vkr) => vkr?.vehicle?.vehicleId == vehicleId
      )
      if (vehicleKeyRow) {
        return vehicleKeyRow.rowHeight
      } else {
        return 0
      }
    },

    getPartnerTypeName(partnerTypeId) {
      if (partnerTypeId) {
        const foundType = this.partnerTypes.find(
          (pt) => pt.id === partnerTypeId
        )
        if (foundType) {
          return foundType.label
        }
      }
      return ''
    },

    handleExpandClick() {
      if (this.isExpanded) {
        if (this.viewByPartnerType) {
          for (const cvt of this.companyVehicleTypes) {
            for (const vt of cvt.vehicleTypes) {
              vt.hidden = true
            }
            cvt.hidden = true
          }
        } else if (this.viewByVehicleType) {
          for (const vtc of this.vehicleTypeCompanies) {
            for (const c of vtc.companies) {
              c.hidden = true
            }
            vtc.hidden = true
          }
        }
      } else {
        if (this.viewByPartnerType) {
          for (const cvt of this.companyVehicleTypes) {
            cvt.hidden = false
          }
        } else if (this.viewByVehicleType) {
          for (const vtc of this.vehicleTypeCompanies) {
            vtc.hidden = false
          }
        }
      }
    },

    async addInCalendarSubmit() {
      this.addInCalendarView = false
      const deleteResults = await Promise.all(
        this.addInCalendarDelete.map((availabilityBlock) => {
          const payload = {
            availabilityBlockId: availabilityBlock.availabilityBlockId,
          }
          if (availabilityBlock.vehicleId) {
            payload.vehicleId = availabilityBlock.vehicleId
            const params = { payload }
            return availability.deleteVehicleAvailabilityBlock(params).catch((e) => console.error(e))
          } else {
            payload.vehicleTypeId = availabilityBlock.vehicleTypeId
            const params = { payload }
            return availability
              .deleteVehicleTypeAvailabilityBlock(params)
              .catch((e) => console.error(e))
          }
        })
      )
      const validDeleteResults = deleteResults.filter(
        (result) => !(result instanceof Error)
      )
      if (deleteResults?.length) {
        this.showAlert({
          type: 'success',
          message: `${validDeleteResults.length} out of ${deleteResults.length} availability blocks deleted successfully.`,
        })
      }
      const addResults = await Promise.all(
        this.addInCalendarAdd.map((availabilityBlock) => {
          const payload = {
            companyId: availabilityBlock.companyId,
            startDatetime: DateTime.fromISO(
              availabilityBlock.startDate
            ).toUTC(),
            endDatetime: DateTime.fromISO(availabilityBlock.endDate).toUTC(),
            reasonTypeId: UNAVAILABLE_AVAILABILITY_REASON_ID,
            availabilityBlockId: null,
          }
          if (availabilityBlock.vehicleId) {
            payload.vehicleId = availabilityBlock.vehicleId
            const params = { payload }
            return availability.createVehicleAvailabilityBlock(params).catch((e) => console.error(e))
          } else {
            payload.vehicleTypeId = availabilityBlock.vehicleTypeId
            const params = { payload }
            return availability
              .createVehicleTypeAvailabilityBlock(params)
              .catch((e) => console.error(e))
          }
        })
      )
      const validAddResults = addResults.filter(
        (result) => !(result instanceof Error)
      )
      if (addResults?.length) {
        this.showAlert({
          type: 'success',
          message: `${validAddResults.length} out of ${addResults.length} availability blocks created successfully.`,
        })
      }
      const earliest = this.addInCalendarAdd
        .concat(this.addInCalendarDelete)
        .sort((a, b) => (a.startDate > b.startDate ? 0 : -1))[0]?.startDate
      const latest = this.addInCalendarAdd
        .concat(this.addInCalendarDelete)
        .sort((a, b) => (a.endDate < b.endDate ? 0 : -1))[0]?.endDate

      this.getVehicleTypeBlocksForDates(earliest, latest)
      this.getVehicleBlocksForDates(earliest, latest)
      this.addInCalendarAdd = []
      this.addInCalendarDelete = []
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar-buttons {
  width: 20px;
  height: 28px;
  border-radius: 2px;
  background: $primary;
  margin-left: 4px;
  margin-right: 4px;
}
.availability-display {
  padding: 20px;
  margin: 0 24px 20px 24px;
  border-radius: 15px;
}
.availability-button {
  margin: 0 0 8px 8px;
  height: 40px;
  width: 100px;
}
.availability-tab {
  line-height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  color: $gray-medium-light;
  border-width: 0 0 2px 0;
  border-style: solid;
  cursor: pointer;
}
.selected-tab {
  color: $blue;
  border-color: $blue;
}
.unselected-tab {
  border-style: none;
}
.unselected-tab:hover {
  border-style: solid !important;
}
.today-button {
  margin: 0 0 8px 8px;
  padding: 0 8px;
  height: 40px;
  width: 70px;
  min-width: 70px;
  background-color: $dispatch-light-blue !important;
  color: $primary;
  border-radius: 10px;
}
.loading-button {
  margin: 0 0 8px 8px;
  pointer-events: none;
}
.calendar-display {
  position: relative;
  align-items: start;
}
.expand {
  color: $blue;
  margin-bottom: 0;
  line-height: 32px;
  cursor: pointer;
}
</style>
