<template>
  <div class="gridlines">
    <div class="solid-bottom-border" style="height: 37px"></div>
    <div
      v-for="(block, index) in rows"
      :key="`grid-block-${index}`"
      class="solid-bottom-border"
      :style="`height: ${block.rowHeight}px;`"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    rows: {
      type: Array,
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.gridlines {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 4;
  pointer-events: none;
}

.solid-bottom-border {
  border-width: 0px 0px 1px 0px;
  border-color: rgba($gray-mid-light, 0.8);
  border-style: solid;
}
</style>
